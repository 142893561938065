import { memo } from 'react';
import { clsx } from 'clsx';
import { FloatingFocusManager } from '@floating-ui/react';
import type { ListChildComponentProps } from 'react-window';
import { TextBlock } from '../../typography';
import isString from '../../../../core/util/isString';
import { getClickableAriaOptions } from '../../buttons';
import WindowRender from './WindowRender';
import useSelectInput from './hooks/useSelectInput';
import SelectTrigger from './SelectTrigger';
import type { SelectOption, SingleSelectProps } from './types';
import SelectDropdown from './SelectDropdown';
import useFloatingSelect from './hooks/useFloatingSelect';
import styles from './Select.module.scss';

/* eslint-disable jsdoc/require-jsdoc */
const Option = memo(
    ({
        item,
        value,
        activeIndex,
        index,
        listElementsRef,
        getItemProps,
        handleSelect,
        id,
        window,
    }: {
        item: SelectOption;
        value: string;
        activeIndex: number | null;
        index: number;
        listElementsRef: React.MutableRefObject<(HTMLElement | null)[]>;

        getItemProps: (
            userProps?: React.HTMLProps<HTMLElement> | undefined
        ) => Record<string, unknown>;
        handleSelect: (index?: number) => void;
        id: string;
        window?: boolean;
    }) => {
        const props = window
            ? {
                  ...getClickableAriaOptions({
                      onPress: () => {
                          handleSelect(index + 1);
                      },
                      role: 'option',
                  }),
                  'aria-label': item.label?.toString(),
                  'aria-selected': value === item.value,
              }
            : {
                  tabIndex: -1,
                  ...getItemProps({
                      onClick: () => {
                          handleSelect();
                      },
                  }),
                  role: 'option',
                  'aria-label': item.label?.toString(),
                  'aria-selected':
                      value === item.value || activeIndex === index + 1,
                  ref: (node: HTMLDivElement) => {
                      listElementsRef.current[index + 1] = node;
                  },
              };

        return (
            <div className={clsx(styles.optionItem)} {...props}>
                <label
                    tabIndex={window ? undefined : 0}
                    htmlFor={`${id}-select-option-${item.value}}`}
                    className={clsx(
                        styles.dropdownMenuItem,
                        styles.contentHorizontalPadding,
                        {
                            [styles.active]: activeIndex === index + 1,
                            [styles.selected]: value === item.value,
                        }
                    )}
                    title={isString(item.label) ? item.label : item.value}
                >
                    <TextBlock primary truncate>
                        {item.label}
                    </TextBlock>
                </label>
            </div>
        );
    }
);

const SingleSelect = ({
    value,
    onChange,
    id,
    options,
    header: HeaderElement,
    placeholder,
    searchable = false,
    disabled = false,
    success = false,
    error = false,
    rounded = false,
    small = false,
    filled = false,
    theme,
    windowRender,
    autoWidth,
    ...ariaProps
}: SingleSelectProps) => {
    const { searchValue, setSearchValue, filteredOptions } =
        useSelectInput(options);

    const {
        refs: { setFloating, setReference: selectTriggerReference },
        open,
        getReferenceProps,
        context,
        strategy,
        y,
        x,
        getFloatingProps,
        setPointer,
        activeIndex,
        handleSelect,
        setOpen,
        listElementsRef,
        getItemProps,
        setActiveIndex,
        styles: animationStyles,
        isMounted,
    } = useFloatingSelect({ options: filteredOptions, onChange });

    return (
        <div
            className={clsx(styles.select, theme, {
                [styles.auto]: autoWidth,
            })}
            {...ariaProps}
        >
            <SelectTrigger
                ref={selectTriggerReference}
                isOpen={open}
                value={value}
                disabled={disabled}
                success={success}
                error={error}
                getReferenceProps={getReferenceProps}
                rounded={rounded}
                small={small}
                filled={!!value && filled}
            >
                {!value
                    ? placeholder || 'select'
                    : options.find(item => item.value === value)?.label}
            </SelectTrigger>

            {isMounted && (
                <div
                    className={styles.dropdownFloatingMenu}
                    ref={setFloating}
                    tabIndex={-1}
                    style={{
                        position: strategy,
                        top: y,
                        left: x,
                        overflow: 'auto',
                        ...animationStyles,
                    }}
                >
                    <FloatingFocusManager
                        modal={false}
                        returnFocus={false}
                        context={context}
                    >
                        <SelectDropdown
                            ref={listElementsRef}
                            searchable={searchable}
                            searchText={searchValue}
                            setSearchText={setSearchValue}
                            optionsCount={filteredOptions.length}
                            header={
                                HeaderElement ? (
                                    <HeaderElement options={filteredOptions} />
                                ) : undefined
                            }
                            context={context}
                            getFloatingProps={getFloatingProps}
                            setPointer={setPointer}
                            activeIndex={activeIndex}
                            handleSelect={handleSelect}
                            setOpen={setOpen}
                            setActiveIndex={setActiveIndex}
                        >
                            {windowRender ? (
                                <WindowRender
                                    filteredOptions={filteredOptions}
                                    windowRender={windowRender}
                                >
                                    {({
                                        index,
                                        style,
                                    }: ListChildComponentProps) => (
                                        <div style={style} key={value}>
                                            <Option
                                                item={filteredOptions[index]}
                                                index={index}
                                                value={value}
                                                activeIndex={activeIndex}
                                                listElementsRef={
                                                    listElementsRef
                                                }
                                                getItemProps={getItemProps}
                                                handleSelect={handleSelect}
                                                id={id}
                                                window
                                            />
                                        </div>
                                    )}
                                </WindowRender>
                            ) : (
                                <>
                                    {filteredOptions.map((item, index) => (
                                        <Option
                                            key={item.value}
                                            item={item}
                                            index={index}
                                            value={value}
                                            activeIndex={activeIndex}
                                            listElementsRef={listElementsRef}
                                            getItemProps={getItemProps}
                                            handleSelect={handleSelect}
                                            id={id}
                                        />
                                    ))}
                                </>
                            )}
                        </SelectDropdown>
                    </FloatingFocusManager>
                </div>
            )}
        </div>
    );
};

export default memo(SingleSelect);
