import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import { useRef, memo, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import ClickableArea from '../../../core/components/button/ClickableArea';
import { ChevronDownIcon, ChevronRightIcon } from '../icons';
import HStack from '../layout/HStack';
import { Text } from '../typography';
import styles from './Collapsible.module.scss';

interface CollapsibleProps {
    /**
     * Component that is displayed in the closed view and
     * acts as a trigger for the collapsible.
     */
    trigger: ReactNode;
    children: ReactNode;
    /**
     * Border type for the whole collapsible view.
     */
    borderType?: 'full' | 'topBottom' | 'leftRight' | 'none' | 'bottom';
    /**
     * Bottom border for trigger.
     */
    triggerSeparator?: boolean;
    /**
     * Place arrow icon before text when component is wide
     */
    wide?: boolean;
    /**
     * Secondary style of accordion
     */
    secondary?: boolean;
    /**
     * The theme
     */
    theme?: string;
}

const Collapsible = ({
    trigger,
    children,
    borderType = 'full',
    triggerSeparator,
    wide,
    secondary,
    theme,
}: CollapsibleProps) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div
            className={clsx(
                styles.collapsible,
                styles[`${borderType}Border`],
                {
                    [styles.contentOpen]: expanded,
                },
                theme
            )}
        >
            <ClickableArea
                className={clsx(styles.trigger, {
                    [styles.contentOpen]: expanded,
                    [styles.wide]: wide,
                    [styles.secondary]: secondary,
                })}
                onClick={() => setExpanded(isExpanded => !isExpanded)}
            >
                <HStack
                    align="center"
                    justify={wide ? 'end' : 'spaceBetween'}
                    reversed={wide}
                >
                    <div className={styles.triggerTitle}>
                        <Text primary bold>
                            {trigger}
                        </Text>
                    </div>
                    <div className={styles.triggerIconContainer}>
                        <div className={styles.triggerIcon}>
                            {secondary ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronDownIcon />
                            )}
                        </div>
                    </div>
                </HStack>
            </ClickableArea>
            <CollapsibleAnimatedContainer expanded={expanded}>
                <div
                    className={clsx(styles.content, {
                        [styles.triggerSeparator]: triggerSeparator,
                    })}
                >
                    {children}
                </div>
            </CollapsibleAnimatedContainer>
        </div>
    );
};

const CollapsibleAnimatedContainer = memo(
    ({
        expanded,
        children,
    }: {
        /**
         * expanded
         */
        expanded: boolean;
        children: ReactNode;
    }) => {
        const contentRef = useRef<HTMLDivElement>(null);
        const [height, setHeight] = useState<number | undefined>(undefined);
        return (
            <CSSTransition
                in={expanded}
                unmountOnExit
                timeout={300}
                onEntering={() => {
                    setHeight(contentRef.current?.offsetHeight);
                }}
                onEntered={() => {
                    setHeight(undefined);
                }}
                onExit={() => {
                    setHeight(contentRef.current?.offsetHeight);
                }}
                onExiting={() => {
                    setHeight(0);
                }}
                onExited={() => {
                    setHeight(undefined);
                }}
                classNames={{ ...styles }}
            >
                <div
                    className={clsx(styles.contentContainer)}
                    style={{ height: height ? `${height}px` : undefined }}
                >
                    <div ref={contentRef}>{children}</div>
                </div>
            </CSSTransition>
        );
    }
);

export default memo(Collapsible);
