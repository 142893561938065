import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { clsx } from 'clsx';
import {
    Button,
    ChevronLeftIcon,
    FormField,
    HStack,
    Heading,
    TextBox,
    TextField,
    VStack,
    Text,
} from '@library';
import {
    useDiscardTicketInCreation,
    useSubmitTicketInCreation,
    useTicketInCreation,
    useUpdateTicketInCreation,
} from '../../../context/TicketCreationContext/TicketCreationContext';
import { useWizardContext } from '../../../context/WizardContext/WizardContext';
import DraftIndicator from '../DraftIndicator';
import CreationWizardStep from '../Wizard/CreationWizardStep';
import DiscardModal from '../../Common/DiscardModal/DiscardModal';
import FilesSection from '../../Common/Files/FilesSection';
import ContactForm from '../Common/PreviewStep/ContactForm';
import styles from './InquiryStep.module.scss';

const InquiryStep = () => {
    const { ticket, isNotCreated, currentProductClass } = useTicketInCreation();
    const { updateTicket } = useUpdateTicketInCreation();
    const { submitTicket, isSubmitting } = useSubmitTicketInCreation();
    const { nextStep } = useWizardContext();

    const [discardModal, setDiscardModal] = useState(false);
    const navigate = useNavigate();
    const onDiscard = () => {
        navigate('../../');
    };

    const [showError, setShowError] = useState(false);

    const isValid = !!ticket.description;

    const { discardTicket, isDiscarding } = useDiscardTicketInCreation();

    const submit = () => {
        setShowError(true);
        if (!isValid) {
            return;
        }
        submitTicket()
            .then(() => {
                nextStep();
            })
            .catch(error => {
                throw error;
            });
    };

    return (
        <CreationWizardStep
            titleId="Tickets Creation Inquiry Title"
            footer={
                <HStack gap="small" justify="spaceBetween" align="center">
                    <Button
                        outline
                        onClick={() => {
                            navigate('../../');
                        }}
                    >
                        <ChevronLeftIcon />
                        <FormattedMessage id="Tickets Creation Common BackButton" />
                    </Button>
                    <HStack gap="small" align="center">
                        <DraftIndicator />
                        <Button
                            outline
                            danger
                            onClick={() => {
                                setDiscardModal(true);
                            }}
                            disabled={isNotCreated}
                        >
                            <FormattedMessage id="Tickets Creation Common DiscardButton" />
                        </Button>
                        <Button onClick={submit} loading={isSubmitting}>
                            <FormattedMessage id="Tickets Creation Common SubmitButton" />
                        </Button>
                    </HStack>
                </HStack>
            }
        >
            <VStack gap="medium">
                <Heading h3>
                    <FormattedMessage id="Tickets Creation Inquiry Title" />
                </Heading>
                <div className={styles.fields}>
                    <VStack gap="medium">
                        <FormField
                            label={
                                <FormattedMessage id="Tickets Creation Inquiry DescriptionLabel" />
                            }
                            error={
                                showError &&
                                !isValid && (
                                    <FormattedMessage id="this field is required" />
                                )
                            }
                        >
                            <TextBox
                                rows={7}
                                value={ticket.description || ''}
                                onChange={value => {
                                    setShowError(true);
                                    updateTicket({ description: value });
                                }}
                            />
                        </FormField>

                        <ContactForm />

                        <VStack gap="small">
                            <Heading h4>
                                <FormattedMessage id="Tickets Creation Inquiry AdditionalDetails" />
                            </Heading>
                            <FormField
                                label={
                                    <FormattedMessage id="Tickets Creation Inquiry CustomerReferenceLabel" />
                                }
                            >
                                <TextField
                                    value={ticket.customerReference || ''}
                                    onChange={value => {
                                        updateTicket({
                                            customerReference: value.slice(
                                                0,
                                                49
                                            ),
                                        });
                                    }}
                                />
                            </FormField>
                        </VStack>
                    </VStack>
                </div>
                <VStack gap="small">
                    <Heading h4>
                        <FormattedMessage id="Tickets Creation Inquiry Attachments" />
                    </Heading>

                    <div
                        className={clsx({
                            [styles.disabledFiles]: isNotCreated,
                        })}
                    >
                        <FilesSection
                            events={ticket.events}
                            id={ticket.id}
                            type={ticket.type}
                            uploadPermissionToken={ticket.uploadPermissionToken}
                            currentProductClass={currentProductClass}
                            qrCode={!isNotCreated}
                        />
                    </div>
                    {isNotCreated && (
                        <Text secondary>
                            <FormattedMessage id="Tickets Creation Inquiry AttachmentsDisabledMessage" />
                        </Text>
                    )}
                </VStack>
            </VStack>
            <DiscardModal
                open={discardModal}
                onOpenChange={setDiscardModal}
                onDiscard={onDiscard}
                discardTicket={discardTicket}
                isDiscarding={isDiscarding}
            />
        </CreationWizardStep>
    );
};

export default memo(InquiryStep);
