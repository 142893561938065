import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { HStack, Text, TextBlock, CloseIcon } from '@library';
import ClickableArea from '../../../../core/components/button/ClickableArea';
import type { MultiSelectProps, SelectOption } from './types';
import styles from './Select.module.scss';

const MultiSelectTriggerContent = ({
    allOptionsSelectedText,
    deselectableLabels,
    handleChange,
    optionsCount,
    placeholder,
    selectedOptions,
    selectedCountBadge,
    noSelectedLabels,
    filled,
}: Pick<
    MultiSelectProps,
    | 'allOptionsSelectedText'
    | 'deselectableLabels'
    | 'placeholder'
    | 'selectedCountBadge'
    | 'noSelectedLabels'
    | 'filled'
> & {
    handleChange(newValue: string): void;
    /**
     * Count of options (Can be filtered count)
     */
    optionsCount: number;
    /**
     * Array of selected options
     */
    selectedOptions: SelectOption[];
}) => {
    const selectedValuesCount = selectedOptions.length;

    if (selectedValuesCount === 0) {
        return <>{placeholder || 'select'}</>;
    }

    if (selectedValuesCount === optionsCount && allOptionsSelectedText) {
        return <>{allOptionsSelectedText}</>;
    }

    const labels = selectedOptions.map(item => item.label);

    if (noSelectedLabels) {
        if (selectedCountBadge) {
            return (
                <HStack align="center" gap="micro">
                    {filled ? (
                        <span>
                            <b>{placeholder || 'select'}</b>
                        </span>
                    ) : (
                        <span>{placeholder || 'select'}</span>
                    )}
                    <div className={styles.countBadge}>
                        <Text caption bold>
                            {selectedValuesCount}
                        </Text>
                    </div>
                </HStack>
            );
        }

        return <>{placeholder || 'select'}</>;
    }

    if (!deselectableLabels) {
        const firstFew = labels.slice(0, 3).join(', ');

        return (
            <>
                {firstFew}{' '}
                {labels.length > 3 && (
                    <span className={styles.andMoreLabel}>
                        <FormattedMessage
                            id="and {count} more"
                            values={{ count: labels.length - 3 }}
                        />
                    </span>
                )}
            </>
        );
    } else {
        const firstFew = selectedOptions.slice(0, 3);

        return (
            <HStack align="center" gap="micro">
                {firstFew.map(item => {
                    return (
                        <div
                            className={styles.selectedOptionPill}
                            key={item.value}
                        >
                            <HStack align="center" gap="micro">
                                <TextBlock secondary>{item.label}</TextBlock>
                                <ClickableArea
                                    data-test-class={`Remove ${item.label}`}
                                    className={styles.removeOptionIcon}
                                    onClick={e => {
                                        e.stopPropagation();
                                        handleChange(item.value);
                                    }}
                                >
                                    <CloseIcon mini />
                                </ClickableArea>
                            </HStack>
                        </div>
                    );
                })}
                {labels.length > 3 && (
                    <span className={styles.andMoreLabel}>
                        {' '}
                        <FormattedMessage
                            id="and {count} more"
                            values={{ count: labels.length - 3 }}
                        />
                    </span>
                )}
            </HStack>
        );
    }
};

export default memo(MultiSelectTriggerContent);
