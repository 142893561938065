import type { Ref } from 'react';
import { memo, forwardRef } from 'react';
import type { BaseInputProps, BaseInputReference } from './BaseInput';
import BaseInput from './BaseInput';
import styles from './TextBox.module.scss';

/**
 *
 */
type TextBoxProps = Omit<
    BaseInputProps,
    'theme' | 'children' | 'startAdornment' | 'endAdornment'
> & {
    /**
     * The html rows attribute.
     */
    rows?: number;
};
/**
 * The text box component for multiline input .
 */
const TextBox = forwardRef(
    ({ onChange, ...props }: TextBoxProps, ref: Ref<BaseInputReference>) => {
        return (
            <BaseInput {...props} ref={ref} theme={styles.theme}>
                <textarea
                    rows={props.rows}
                    onChange={e => {
                        onChange?.(e.target.value);
                    }}
                />
            </BaseInput>
        );
    }
);

export default memo(TextBox);
