import { FormattedMessage, useIntl } from 'react-intl';
import type { ReactNode } from 'react';
import { memo } from 'react';
import { clsx } from 'clsx';
import { HStack, Stack, VStack } from '../../layout';
import { Text } from '../../typography';
import { Image } from '../../image';
import { Button, LinkButton } from '../../buttons';
import { ProgressSpinner } from '../../../../core/components/ProgressIndicators';
import { BinIcon, DownloadIcon } from '../../icons';
import { useLayoutContext } from '../../../contexts';
import styles from './FilePreview.module.scss';
import DocumentWithExtension from './DocumentWithExtension';

type Action = 'delete' | 'loading' | ReactNode;

interface FilePreviewActionProps {
    /**
        Render component on right side. Use for any action or indicators
     */
    action: Action;
    /**
     * Callback for click
     */
    onAction?: () => void;
    /**
     * Source File link
     */
    sourceFile?: string;
}

const FilePreviewAction = memo(
    ({ action, onAction, sourceFile }: FilePreviewActionProps) => {
        const { isInMobileView } = useLayoutContext();
        switch (action) {
            case 'delete': {
                return (
                    <Button
                        text
                        inline
                        onClick={() => {
                            onAction?.();
                        }}
                    >
                        <BinIcon
                            mini={!isInMobileView}
                            small={isInMobileView}
                        />
                    </Button>
                );
            }
            case 'loading': {
                return <ProgressSpinner />;
            }
            case 'download': {
                return sourceFile ? (
                    <LinkButton
                        text
                        inline
                        href={sourceFile}
                        target="_blank"
                        download={sourceFile}
                    >
                        <DownloadIcon small />
                        <FormattedMessage id="Library FileField DownloadFileButton" />
                    </LinkButton>
                ) : null;
            }
            default: {
                return <>{action}</>;
            }
        }
    }
);

const LinkWrapper = memo(
    ({
        children,
        sourceFile,
    }: {
        children: ReactNode;
        /**
         * Source file for link
         */
        sourceFile?: string;
    }) => {
        if (sourceFile) {
            return (
                <LinkButton
                    href={sourceFile}
                    text
                    inline
                    secondary
                    target="_blank"
                >
                    {children}
                </LinkButton>
            );
        }

        return <>{children}</>;
    }
);

const FilePreview = ({
    preview,
    name,
    size,
    sourceFile,
    action,
    onAction,
    uploader,
    uploadDate,
    detailView,
}: {
    /**
     * The preview link
     */
    preview?: string;
    /**
     * The name of file
     */
    name: string;
    /**
     * Size in bytes
     */
    size: number;
    /**
     * Source File link
     */
    sourceFile?: string;
    /**
     * uploader
     */
    uploader?: string;
    /**
     * The upload date
     */
    uploadDate?: string;
    /**
     * The detail view
     */
    detailView?: boolean;
} & FilePreviewActionProps) => {
    const kBytes = size / 1024;
    const mBytes = kBytes / 1024;
    const { locale } = useIntl();
    const byteValueNumberFormatter = Intl.NumberFormat(locale, {
        notation: 'compact',
        style: 'unit',
        unit: kBytes > 1000 ? 'megabyte' : 'kilobyte',
        unitDisplay: 'narrow',
    });

    const ext = name && name.split('.').pop();

    return (
        <div
            className={clsx(styles.content, {
                [styles.detailView]: detailView,
            })}
        >
            <Stack
                gap="small"
                align="center"
                direction={detailView ? 'columnMobileWideRow' : 'row'}
            >
                <div className={styles.mainInfo}>
                    <LinkWrapper sourceFile={sourceFile}>
                        <HStack gap="small" align="center">
                            <div className={styles.image}>
                                <Image
                                    placeholder={
                                        <DocumentWithExtension
                                            ext={ext?.toUpperCase() || '.'}
                                        />
                                    }
                                    src={preview}
                                    alt={name}
                                />
                            </div>
                            <VStack>
                                <Text medium>{name}</Text>
                                <Text secondary>
                                    {byteValueNumberFormatter.format(
                                        kBytes > 1000 ? mBytes : kBytes
                                    )}
                                </Text>
                            </VStack>
                        </HStack>
                    </LinkWrapper>
                </div>
                {uploader && <div className={styles.subInfo}>{uploader}</div>}
                {uploadDate && (
                    <div className={styles.subInfo}>{uploadDate}</div>
                )}
                {action && (
                    <div className={styles.action}>
                        <FilePreviewAction
                            sourceFile={sourceFile}
                            action={action}
                            onAction={onAction}
                        />
                    </div>
                )}
            </Stack>
        </div>
    );
};

export default memo(FilePreview);
