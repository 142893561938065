import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import type { MutationUpdateSupportTicketArgs } from '@apiTypes';
import { getTicketRefetchQuery } from './useTicket';

export const updateTicketMutation = gql`
    mutation updateSupportTicket(
        $updates: UpdateSupportTicketInput!
        $items: [SupportTicketItemUpdateInput!]
    ) {
        updateSupportTicket(updates: $updates, items: $items)
    }
`;

const useUpdateTicket = () => {
    const [mutation, { loading }] = useMutation<
        boolean,
        MutationUpdateSupportTicketArgs
    >(updateTicketMutation, {
        awaitRefetchQueries: true,
    });

    const updateTicket = useCallback(
        async (
            id: number,
            updates: Omit<MutationUpdateSupportTicketArgs['updates'], 'id'>
        ) => {
            const { data } = await mutation({
                variables: { id, updates: { ...updates, id } },
                refetchQueries: [getTicketRefetchQuery(id)],
            });
            return !!data;
        },
        [mutation]
    );
    return {
        updateTicket,
        loading,
    };
};

export default useUpdateTicket;
