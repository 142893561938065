import { memo } from 'react';
import assert from '../../../core/util/assert';
import type { ImageProps } from './Image';
import Image from './Image';
import type { RenditionSet, RenditionUrl } from './types';

interface BaseImageProps
    extends Pick<ImageProps, 'placeholder' | 'alt' | 'disableLazy'> {
    /**
     * The rendition url. Should be like "link*", * will be replaced with needed rendition.
     */
    renditionUrl?: RenditionUrl;
    /**
     * The rendition set. Image takes better rendition depends on props: size or rendition.
     */
    renditionSet: RenditionSet[];
}

/**
 *
 */
type ImageWithRenditionsProps =
    | ({
          /**
           *
           */
          renditionSize?: never;
          /**
           * The Size of image. Supports any units: px/rem/em.
           */
          size: string;
          /**
           * The aspectRatio of image. It works only with size prop.
           */
          aspectRatio?: number;
      } & BaseImageProps)
    | ({
          /**
           * Use rendition size and stretch image.
           */
          renditionSize: string;
          /**
           *
           */
          size?: never;
          /**
           *
           */
          aspectRatio?: never;
      } & BaseImageProps);

const getRendition = ({
    renditionSize,
    size,
    renditionSet: renditionSets,
}: ImageWithRenditionsProps) => {
    if (renditionSize) {
        return renditionSets.find(({ rendition }) => {
            return renditionSize === rendition;
        })?.set;
    }
    assert(size, 'size is required.');
    return renditionSets.find(
        ({ set }) => parseFloat(set[1]) > parseFloat(size) * 2
    )?.set;
};

const getImageSrc = (
    renditionUrl: RenditionUrl | undefined,
    opts: ImageWithRenditionsProps
) => {
    if (!renditionUrl || !renditionUrl.includes('*')) {
        return {};
    }
    const rendition = getRendition(opts);
    assert(rendition, 'Image does not support provided rendition and size');
    const [src, src2x] = rendition;
    return {
        src: renditionUrl.replace('*', src),
        src2x: src2x && renditionUrl.replace('*', src2x),
    };
};

/**
 * The Image component for showing responsive images. Image takes whole width and height of the parent container.
 * Supports fallback placeholder.
 */
const ImageWithRenditions = (props: ImageWithRenditionsProps) => {
    const { src, src2x } = getImageSrc(props.renditionUrl, props);
    const {
        renditionUrl,
        renditionSet: renditionSets,
        renditionSize,
        ...imageProps
    } = props;
    return <Image src={src} src2x={src2x} size={props.size} {...imageProps} />;
};

export default memo(ImageWithRenditions);
