import { useDeferredValue, useMemo, useState } from 'react';
import type { SelectOption } from '../types';

const useSelectInput = (options: SelectOption[]) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const deferredSearch = useDeferredValue(searchValue);

    const toggleDropdown = () => {
        setShowDropdown(isShown => !isShown);
    };

    const filteredOptions = useMemo(
        () =>
            options.filter(
                item =>
                    item.label
                        ?.toString()
                        .toLowerCase()
                        .includes(deferredSearch.toLowerCase()) ||
                    item.value
                        .toLowerCase()
                        .includes(deferredSearch.toLowerCase())
            ),
        [options, deferredSearch]
    );

    return {
        showDropdown,
        toggleDropdown,
        searchValue,
        setSearchValue,
        filteredOptions,
    };
};

export default useSelectInput;
