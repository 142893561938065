import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { MutableRefObject, ReactNode, RefObject } from 'react';
import { clsx } from 'clsx';
import onOutsideClick from '../../../events/onOutsideClick';
import useSearchableSelectOptions from '../../../hooks/useSearchableSelectOptions';
import SelectMenu from './SelectMenu';
import type { SelectDropdownOptions } from './SelectDropdown';
import SelectDropdown from './SelectDropdown';
import styles from './Select.module.css';
import VirtualizedSelectDropdown from './VirtualizedSelectDropdown';

export interface SelectBaseProps {
    inline?: boolean;
    forceDisableVirtualization?: boolean;
    fullWidth?: boolean;
    noPadding?: boolean;
    rightAligned?: boolean;
    bordered?: boolean;
    material?: boolean;
    searchable?: boolean;
    small?: boolean;
    label?: ReactNode;
    placeholder?: ReactNode;
    options?: SelectDropdownOptions[];
    menuSize?: 'min' | 'max' | 'full';
    disabled?: boolean;
    keepLabel?: boolean;
    formStyle?: boolean;
    loading?: boolean;
    boundsRef?: RefObject<HTMLElement | null>;
    successMessage?: string;
    showSuccessMessage?: boolean;
    fluidWidth?: boolean;
    noBackground?: boolean;
    /**
     * Used for `useOutsideClick` hook
     */
    rootNode?: HTMLElement;
}

export interface SelectProps extends SelectBaseProps {
    clearButton?: boolean;
    value: string;
    onChange(value: string): void;
    unselectable?: boolean;
}

export const useSelectInputState = (): [
    MutableRefObject<HTMLDivElement | null>,
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
] => {
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        let subscription: { unsubscribe: () => void } | undefined;

        if (containerRef.current) {
            subscription = onOutsideClick(containerRef.current as Element, () =>
                setIsOpen(false)
            );
        }

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, []);

    return [containerRef, isOpen, setIsOpen];
};

const Select = ({
    disabled = false,
    forceDisableVirtualization = false,
    inline = false,
    fullWidth = false,
    noPadding = false,
    rightAligned = false,
    bordered = false,
    material = true,
    small = false,
    searchable = false,
    unselectable = false,
    label,
    placeholder,
    options = [],
    value,
    onChange,
    menuSize,
    boundsRef,
    formStyle,
    loading,
    successMessage,
    showSuccessMessage,
    fluidWidth,
    keepLabel = false,
    clearButton,
    noBackground,
    ...ariaProps
}: SelectProps) => {
    const [containerRef, isOpen, setIsOpen] = useSelectInputState();
    const virtualized = options.length > 200 && !forceDisableVirtualization;

    const { search, setSearch, filteredOptions } = useSearchableSelectOptions(
        options,
        searchable
    );

    const closeDropdown = () => {
        setIsOpen(false);
        setSearch('');
    };

    const handleChange = (newValue: string) => {
        onChange(unselectable && newValue === value ? '' : newValue);
        closeDropdown();
    };

    useEffect(() => {
        if (!isOpen) setSearch('');
    }, [isOpen, setSearch]);

    return (
        <div
            className={clsx(styles.select, {
                [styles.isOpen]: isOpen,
                [styles.inline]: inline,
                [styles.fullWidth]: fullWidth,
                [styles.formStyle]: formStyle,
                [styles.fluidWidth]: fluidWidth,
            })}
            ref={containerRef}
            {...ariaProps}
        >
            <SelectMenu
                small={small}
                disabled={disabled}
                bordered={bordered}
                material={material}
                label={label}
                placeholder={placeholder}
                selectedOption={options.find(option => option.value === value)}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                search={search}
                updateSearch={setSearch}
                searchable={searchable}
                fullWidth={fullWidth}
                noPadding={noPadding}
                loading={loading}
                formStyle={formStyle}
                successMessage={successMessage}
                showSuccessMessage={showSuccessMessage}
                keepLabel={keepLabel}
                onChange={handleChange}
                value={value}
                clearButton={clearButton}
                noBackground={noBackground}
            />
            {isOpen &&
                (virtualized ? (
                    <VirtualizedSelectDropdown
                        menuSize={menuSize}
                        rightAligned={rightAligned}
                        value={value}
                        onChange={handleChange}
                        options={filteredOptions}
                        closeDropdown={closeDropdown}
                        boundsRef={boundsRef}
                    />
                ) : (
                    <SelectDropdown
                        menuSize={menuSize}
                        rightAligned={rightAligned}
                        value={value}
                        onChange={handleChange}
                        options={filteredOptions}
                        closeDropdown={closeDropdown}
                        boundsRef={boundsRef}
                        small={small}
                        formStyle={formStyle}
                    />
                ))}
        </div>
    );
};

export default memo(Select);
