import { memo, createContext, useContext } from 'react';
import type { ReactNode } from 'react';
import assert from '../../../core/util/assert';

export interface WizardContextValue {
    step: string;
    nextStep(): boolean;
    previousStep(): boolean;
}

const WizardContext = createContext<Partial<WizardContextValue>>({});

export const WizardContextProvider = memo(
    ({
        children,
        value,
    }: {
        children: ReactNode;
        value: WizardContextValue;
    }) => {
        return (
            <WizardContext.Provider value={value}>
                {children}
            </WizardContext.Provider>
        );
    }
);

export const useWizardContext = () => {
    const context = useContext(WizardContext);
    assert(
        context.step !== undefined,
        'Please check that WizardContextProvider is present in the tree'
    );
    return context as WizardContextValue;
};
