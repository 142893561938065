import { memo } from 'react';
import type { IconProps } from './types';
import BaseIcon from './BaseIcon';

const UploadIcon = (props: IconProps) => {
    return (
        <BaseIcon {...props} viewBox="0 0 42 43" fill="none">
            <rect y="0.5" width="42" height="42" rx="21" fill="#EEEEEE" />
            <path
                d="M30.0909 22.5C29.8498 22.5 29.6186 22.5948 29.4481 22.7636C29.2776 22.9324 29.1818 23.1613 29.1818 23.4V27.1917C29.1811 27.8567 28.9139 28.4943 28.4389 28.9645C27.9639 29.4348 27.3199 29.6993 26.6482 29.7H15.3518C14.6801 29.6993 14.0361 29.4348 13.5611 28.9645C13.0861 28.4943 12.8189 27.8567 12.8182 27.1917V23.4C12.8182 23.1613 12.7224 22.9324 12.5519 22.7636C12.3814 22.5948 12.1502 22.5 11.9091 22.5C11.668 22.5 11.4368 22.5948 11.2663 22.7636C11.0958 22.9324 11 23.1613 11 23.4V27.1917C11.0012 28.334 11.4601 29.4291 12.2759 30.2368C13.0918 31.0445 14.198 31.4988 15.3518 31.5H26.6482C27.802 31.4988 28.9082 31.0445 29.7241 30.2368C30.5399 29.4291 30.9988 28.334 31 27.1917V23.4C31 23.1613 30.9042 22.9324 30.7337 22.7636C30.5632 22.5948 30.332 22.5 30.0909 22.5Z"
                fill="currentColor"
            />
            <path
                d="M16.4552 18.3002L20.1413 14.5122V25.6176C20.1413 25.8517 20.2318 26.0761 20.3928 26.2416C20.5539 26.407 20.7723 26.5 21 26.5C21.2277 26.5 21.4461 26.407 21.6072 26.2416C21.7682 26.0761 21.8586 25.8517 21.8586 25.6176V14.5122L25.5448 18.3002C25.7068 18.4609 25.9237 18.5499 26.1488 18.5478C26.3739 18.5458 26.5893 18.453 26.7485 18.2894C26.9077 18.1258 26.998 17.9045 27 17.6732C27.0019 17.4418 26.9154 17.2189 26.759 17.0525L21.6071 11.7583C21.446 11.5929 21.2277 11.5 21 11.5C20.7723 11.5 20.554 11.5929 20.3929 11.7583L15.241 17.0525C15.0846 17.2189 14.9981 17.4418 15 17.6732C15.002 17.9045 15.0923 18.1258 15.2515 18.2894C15.4107 18.453 15.6261 18.5458 15.8512 18.5478C16.0763 18.5499 16.2932 18.4609 16.4552 18.3002Z"
                fill="currentColor"
            />
        </BaseIcon>
    );
};

export default memo(UploadIcon);
