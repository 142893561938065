import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import type { MutationDiscardTicketArgs } from '@apiTypes';

export const discardTicketQuery = gql`
    mutation discardTicket($id: Int!) {
        discardTicket(id: $id)
    }
`;

const useDiscardTicket = () => {
    const [mutation, { loading }] = useMutation<
        { discardTicket: boolean },
        MutationDiscardTicketArgs
    >(discardTicketQuery, {
        awaitRefetchQueries: true,
    });
    const discardTicket = useCallback(
        async (id: number) => {
            const { data } = await mutation({
                variables: { id },
            });
            return !!data?.discardTicket;
        },
        [mutation]
    );
    return {
        discardTicket,
        loading,
    };
};

export default useDiscardTicket;
