import { memo } from 'react';
import type { IconProps } from './types';
import BaseIcon from './BaseIcon';

const EditIcon = memo((props: IconProps) => {
    return (
        <BaseIcon viewBox="0 0 18 18" {...props} fill="none">
            <>
                <g clipPath="url(#clip0_8078_1033)">
                    <path
                        d="M12.6465 3.36519L13.9117 2.09919C14.1755 1.83543 14.5332 1.68726 14.9062 1.68726C15.2793 1.68726 15.637 1.83543 15.9008 2.09919C16.1645 2.36295 16.3127 2.72068 16.3127 3.09369C16.3127 3.4667 16.1645 3.82443 15.9008 4.08819L5.124 14.8649C4.72749 15.2612 4.23852 15.5525 3.70125 15.7124L1.6875 16.3124L2.2875 14.2987C2.44746 13.7614 2.73873 13.2724 3.135 12.8759L12.6473 3.36519H12.6465ZM12.6465 3.36519L14.625 5.34369"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_8078_1033">
                        <rect width="18" height="18" fill="white" />
                    </clipPath>
                </defs>
            </>
        </BaseIcon>
    );
});

export default memo(EditIcon);
