import type {
    NumericFormatProps,
    PatternFormatProps,
} from 'react-number-format';
import { PatternFormat, NumericFormat } from 'react-number-format';
import { memo, useRef } from 'react';
import useAutoFocusAfterOnMount from '../../../../core/hooks/dom/useAutoFocusAfterOnMount';
import type { BaseInputProps } from './BaseInput';
import BaseInput from './BaseInput';

/**
 *
 */
function isPatternFormatProps(
    props: NumericFormatProps | PatternFormatProps
): props is PatternFormatProps {
    return !!(props as PatternFormatProps).format;
}

/**
 *
 */
export type NumberMaskInputProps = Omit<
    BaseInputProps,
    'defaultValue' | 'type' | 'ref' | 'children'
> & {
    /**
     * The number format props for the number mask input.
     */
    numberFormatProps: NumericFormatProps | PatternFormatProps;
};
const NumericMaskInput = ({
    autoFocus,
    numberFormatProps,
    placeholder,
    ...otherProps
}: NumberMaskInputProps) => {
    const ref = useRef<HTMLInputElement | null>(null);
    useAutoFocusAfterOnMount(ref, autoFocus);
    const baseProps = {
        getInputRef: (element: HTMLInputElement) => {
            ref.current = element;
        },
        placeholder,
    };

    const formatInput = isPatternFormatProps(numberFormatProps) ? (
        <PatternFormat {...baseProps} {...numberFormatProps} />
    ) : (
        <NumericFormat {...baseProps} {...numberFormatProps} />
    );

    return <BaseInput {...otherProps}>{formatInput}</BaseInput>;
};

export default memo(NumericMaskInput);
