import { gql, useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { notify } from '@library';
import { CheckoutErrors } from '../../checkout/context/CheckoutProvider';
import type { CartFields } from '../../checkout/types';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import useCurrentCartId from '../hooks/useCurrentCartId';
import usePolledQuery from '../../core/hooks/apollo/usePolledQuery';

const checkoutStockLocationQuery = gql`
    query checkoutStockLocation($cartId: Int!, $store: ID!) {
        cartFields(cartId: $cartId, store: $store) {
            id
            stockLocation
        }
    }
`;

// cSpell:words STOCKLOCATION

const useCheckoutStockLocation = () => {
    const intl = useIntl();
    const { store } = useStoreAndLanguageParams();
    const cartId = useCurrentCartId();
    const [checkoutStockLocation, setCheckoutStockLocation] = useState<
        string | undefined
    >(undefined);
    const [isUpdatePending, setIsUpdatePending] = useState(false);

    const { data, loading, error } = usePolledQuery<{
        cartFields: Pick<CartFields, 'stockLocation'>;
    }>(checkoutStockLocationQuery, {
        variables: {
            store,
            cartId,
        },
        pollInterval: 180_000,
        skip: !cartId,
    });

    const [saveStockLocation] = useMutation(
        gql`
            mutation saveCheckoutStockLocation(
                $cartId: Int!
                $store: ID!
                $fields: CartFieldsInput!
            ) {
                saveCartFields(cartId: $cartId, store: $store, fields: $fields)
            }
        `,
        {
            refetchQueries: [
                {
                    query: checkoutStockLocationQuery,
                    variables: {
                        store,
                        cartId,
                    },
                },
            ],
            awaitRefetchQueries: true,
        }
    );

    useEffect(() => {
        if (!isUpdatePending) {
            setCheckoutStockLocation(data?.cartFields.stockLocation);
        }
    }, [data?.cartFields.stockLocation, isUpdatePending]);

    const updateCheckoutStockLocation = useCallback(
        (stockLocation: string) => {
            setIsUpdatePending(true);
            setCheckoutStockLocation(stockLocation);
            saveStockLocation({
                variables: {
                    store,
                    cartId,
                    fields: {
                        stockLocation,
                    },
                },
            })
                .catch(e => {
                    notify({
                        type: 'error',
                        title: (
                            <FormattedMessage
                                id="Failed to update {fieldName}"
                                values={{
                                    fieldName: intl.formatMessage({
                                        id: 'stockLocation field',
                                    }),
                                }}
                            />
                        ),
                        message: (
                            <FormattedMessage id="Your changes were not submitted to the server. Please check your input and try again." />
                        ),

                        id: `${CheckoutErrors.FAILED_TO_UPDATE_}STOCKLOCATION`,
                    });

                    setCheckoutStockLocation(data?.cartFields.stockLocation);

                    captureException(e);
                })
                .finally(() => {
                    setIsUpdatePending(false);
                });
        },
        [cartId, data?.cartFields.stockLocation, intl, saveStockLocation, store]
    );

    return {
        checkoutStockLocation,
        updateCheckoutStockLocation,
        isUpdatePending: isUpdatePending || loading,
        error,
    };
};

export default useCheckoutStockLocation;
