import { DateTime } from 'luxon';
import type { ReactNode } from 'react';
import { memo, useState } from 'react';
import BaseQuantityInput from './BaseQuantityInput';
import styles from './TimeQuantityInput.module.css';

type FirstHour = '0' | '1' | '2';
type SecondHour = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
type FirstMinute = '0' | '3';
type SecondMinute = '0';
type Hours24h = `${FirstHour}${SecondHour}:${FirstMinute}${SecondMinute}`;

const TimeQuantityInput = ({
    value,
    onChange,
    maxTime: maxTimeProp,
    minTime: minTimeProp,
    maxTimeMessage,
    minTimeMessage,
}: {
    /**
     * The value
     */
    value: string;
    /**
     *  The onChange event callback
     */
    onChange: (value: string) => void;

    /**
     * The max time.  Time can't be more than 23:30
     */
    maxTime?: Hours24h;
    /**
     * The max time.  Time can't be more than 23:30
     */
    maxTimeMessage?: ReactNode;
    /**
     * The min time.  Time can't be less than 00:00
     */
    minTime?: Hours24h;
    /**
     * The min time message
     */
    minTimeMessage?: ReactNode;
}) => {
    const minTime = minTimeProp || (maxTimeProp && '00:00');
    const maxTime = maxTimeProp || (minTimeProp && '23:30');

    const [tempValue, setTempValue] = useState<string | null>(null);

    const validate = (newTime: DateTime) => {
        if (minTime) {
            const minTimeDate = DateTime.fromFormat(minTime, 'HH:mm');
            if (newTime < minTimeDate) {
                return minTime;
            }
        }
        if (maxTime) {
            const maxTimeDate = DateTime.fromFormat(maxTime, 'HH:mm');
            if (newTime > maxTimeDate) {
                return maxTime;
            }
        }
        return newTime.toFormat('HH:mm');
    };

    const onDecrease = () => {
        if (value === minTime) {
            return;
        }
        const date = DateTime.fromFormat(value, 'HH:mm');
        const newTime = date.minus({ minute: 30 });

        if (newTime.day === DateTime.now().day) {
            onChange(validate(newTime));
            setTempValue(validate(newTime));
        } else {
            onChange(validate(newTime.set({ day: DateTime.now().day })));
            setTempValue(validate(newTime.set({ day: DateTime.now().day })));
        }
    };

    const onIncrease = () => {
        if (value === maxTime) {
            return;
        }

        const date = DateTime.fromFormat(value, 'HH:mm');
        const newTime = date.plus({ minute: 30 });
        if (newTime.day === DateTime.now().day) {
            onChange(validate(newTime));
            setTempValue(validate(newTime));
        } else {
            onChange(validate(newTime.set({ day: DateTime.now().day })));
            setTempValue(validate(newTime.set({ day: DateTime.now().day })));
        }
    };

    const startOfHalfHours = (date: DateTime) => {
        const result = date.startOf('hour');
        if (date.minute >= 30) {
            return result.set({ minute: 30 });
        }
        return result;
    };

    return (
        <BaseQuantityInput
            value={tempValue || value}
            numberFormatProps={{
                format: '##:##',
            }}
            onDecrease={onDecrease}
            onIncrease={onIncrease}
            onChange={({ formattedValue }) => {
                setTempValue(formattedValue);
            }}
            onBlur={() => {
                if (tempValue) {
                    const date = startOfHalfHours(
                        DateTime.fromFormat(tempValue, 'HH:mm')
                    );
                    onChange(validate(date));
                    setTempValue(null);
                }
            }}
            maxMessage={maxTime === value && maxTimeMessage}
            minMessage={minTime === value && minTimeMessage}
            theme={styles.theme}
        />
    );
};

export default memo(TimeQuantityInput);
