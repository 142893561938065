import type { ReactElement } from 'react';
import { memo } from 'react';
import type { SidePaneProps } from './SidePane';
import SidePane from './SidePane';
import styles from './ExpandableFixedBottomPane.module.scss';

type ExpandableFixedBottomPaneProps = {
    /**
     * The trigger of expand
     */
    trigger?: ReactElement;
    /**
     * The expanded state
     */
    expanded?: boolean;
    /**
     * The expand state change
     */
    onExpandChange?: (value: boolean) => void;
} & Pick<SidePaneProps, 'footer' | 'children'>;

const ExpandableFixedBottomPane = ({
    footer,
    children,
    trigger,
    expanded,
    onExpandChange,
}: ExpandableFixedBottomPaneProps) => {
    return (
        <SidePane
            open
            side="bottom"
            hiddenCloseButton
            autoHeight={!expanded}
            hiddenOverlay={!expanded}
            onOpenChange={() => {
                onExpandChange?.(!expanded);
            }}
            footer={footer}
        >
            {trigger && <div className={styles.trigger}>{trigger}</div>}
            {expanded && children}
        </SidePane>
    );
};

export default memo(ExpandableFixedBottomPane);
