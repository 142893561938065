import { memo, useContext } from 'react';
import { clsx } from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    LayoutContext,
    Button,
    HStack,
    notify,
    Text,
    ClickableArea,
} from '@library';
import useStoreRootPath from '../../../../../core/hooks/useStoreRootPath';
import { formatTicketNumber } from '../../../../domain';
import QRCode from './QRCode';
import styles from './QrCodeUpload.module.scss';

const QrCodeUpload = ({
    id,
    uploadPermissionToken,
    compact,
    dark,
    transparent,
}: {
    id: number;
    uploadPermissionToken: string;
    compact?: boolean;
    dark?: boolean;
    transparent?: boolean;
}) => {
    const { storeRootPath } = useStoreRootPath();

    const prefixPlace = window.location.href.indexOf(storeRootPath + '/');
    const link = `${window.location.href.substring(
        0,
        prefixPlace
    )}${storeRootPath}/headless/ticket-upload?id=${formatTicketNumber(
        id
    )}&permissionToken=${uploadPermissionToken}`;

    const { isInMobileView } = useContext(LayoutContext);
    const intl = useIntl();

    const copy = () => {
        notify({
            type: 'info',
            title: intl.formatMessage({
                id: 'Tickets QrUpload LinkCopiedMessage',
            }),
        });
        navigator.clipboard.writeText(link).catch(error => {
            throw error;
        });
    };
    if (isInMobileView) {
        return null;
    }
    return (
        <div
            className={clsx(styles.container, {
                [styles.dark]: dark,
                [styles.transparent]: transparent,
            })}
        >
            <HStack gap="smallMedium" align="center" wrap>
                {!compact && (
                    <>
                        <div>
                            <Button outline secondary onClick={copy}>
                                <FormattedMessage id="Tickets QrUpload CopyLinkButton" />
                            </Button>
                        </div>
                        <div>
                            <Text>
                                <FormattedMessage id="Tickets QrUpload Or" />
                            </Text>
                        </div>
                    </>
                )}
                <HStack align="center" gap="mini">
                    <div className={styles.message}>
                        <Text>
                            <FormattedMessage id="Tickets QrUpload ScanMessage" />
                        </Text>
                    </div>
                    <div>
                        <ClickableArea onPress={copy}>
                            <QRCode className={styles.qrCode} link={link} />
                        </ClickableArea>
                    </div>
                </HStack>
            </HStack>
        </div>
    );
};

export default memo(QrCodeUpload);
