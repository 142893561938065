import { memo } from 'react';
import DocumentIcon from '../../icons/DocumentIcon';
import { Text } from '../../typography';
import styles from './DocumentWithExtension.module.scss';

const DocumentWithExtension = ({
    ext,
}: {
    /**
        The extension of file
     */
    ext: string;
}) => {
    return (
        <div className={styles.container}>
            <DocumentIcon theme={styles.iconTheme} />
            <div className={styles.text}>
                <Text tertiary bold>
                    {ext}
                </Text>
            </div>
        </div>
    );
};

export default memo(DocumentWithExtension);
