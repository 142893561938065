import { getOperationName } from '@apollo/client/utilities';
import deliveryDatesQuery from '../../cart/queries/deliveryDatesQuery';
import { preorderCartBrandBreakdownQuery } from '../../cart/queries/useCartBrandTotal';
import notEmpty from '../../core/util/notEmpty';
import { customerPreorderBrandBreakdownQuery } from '../queries/useCustomerPreorderBrandBreakdown';
import { customerPreorderSummary } from '../../pricing/queries/useCustomerPreorderSummary';

const getPreorderRefetchQueries = () => {
    return [
        getOperationName(customerPreorderBrandBreakdownQuery),
        getOperationName(preorderCartBrandBreakdownQuery),
        getOperationName(customerPreorderSummary),
        getOperationName(deliveryDatesQuery),
    ].filter(notEmpty);
};

export default getPreorderRefetchQueries;
