import type { Customer, UserPermissions } from '../../user/types';
import type { CompanyCode, Store, StoreId } from '../types/stores';
import useActivePreorderSeasons from '../../preorder/queries/useActivePreorderSeasons';
import useStoreAndLanguageParams from './useStoreAndLanguageParams';
import useLoginState from './useLoginState';
import useCurrentStore from './useCurrentStore';
import useCurrentCustomer from './useCurrentCustomer';
import useCurrentUser from './useCurrentUser';

type Permission = 'dealerPricesEnabled';

type Attributes = 'requiresPrepay' | 'yamahaPortalEnabled';

type UserDataPermission = Partial<UserPermissions>;

type FeatureFlag =
    | 'stocklist'
    | 'rma'
    | 'availabilityFilter'
    | 'backorderRelease'
    | 'backorderCancellation';

type StoreFlag = keyof Pick<
    Store,
    | 'orderCommentsEnabled'
    | 'dealerSearchEnabled'
    | 'sparePartSearchEnabled'
    | 'cartSharingEnabled'
>;

export interface ContentAvailabilityOptions {
    loggedInOnly?: boolean;
    loggedOutOnly?: boolean;
    restrictedPage?: boolean;
    applications?: Application[] | Application;
    stores?: StoreId[] | StoreId;
    companies?: CompanyCode[] | CompanyCode;
    permissions?: Permission | Permission[];
    featureFlag?: FeatureFlag;
    storeFlags?: StoreFlag | StoreFlag[];
    attributes?: Attributes | Attributes[];
    preorder?: boolean;
}

export const hasPermissions = (
    permissions: Permission | Permission[],
    userPermissions?: UserDataPermission
) => {
    if (!userPermissions) {
        return false;
    }
    if (Array.isArray(permissions)) {
        return permissions.every(permission => !!userPermissions[permission]);
    }
    return !!userPermissions[permissions];
};

const isFlagEnabled = (featureFlag: FeatureFlag) => {
    const formattedName = featureFlag
        .replace(/[A-Z]/g, letter => `_${letter}`)
        .toUpperCase();
    const envName = `FEATURE_${formattedName}` as keyof typeof APP_ENV;
    return (
        !!APP_ENV[envName] &&
        APP_ENV[envName] !== `#VITE__FEATURE_${formattedName}#`
    );
};

export const checkFeatureFlags = (...featureFlags: FeatureFlag[]) => {
    return featureFlags.every(featureFlag => isFlagEnabled(featureFlag));
    /* istanbul ignore next -- @preserve */
};

export const checkStoreFlags = (
    store: Store,
    storeFlags: StoreFlag | StoreFlag[]
) => {
    if (Array.isArray(storeFlags)) {
        return storeFlags.every(storeFlag => !!store[storeFlag]);
    }

    return !!store[storeFlags];
    /* istanbul ignore next -- @preserve */
};

export const hasAttributes = (
    attributes: Attributes | Attributes[],
    customer?: Customer
) => {
    if (!customer) {
        return false;
    }

    if (Array.isArray(attributes)) {
        return attributes.every(attribute => !!customer[attribute]);
    }

    return !!customer[attributes];
};

const useStoreChecks = ({
    stores,
    storeFlags,
}: Pick<ContentAvailabilityOptions, 'stores' | 'storeFlags'>) => {
    const { store } = useStoreAndLanguageParams();
    const currentStore = useCurrentStore();

    if (stores && !stores.includes(store)) {
        return false;
    }

    if (storeFlags && !checkStoreFlags(currentStore, storeFlags)) {
        return false;
    }

    return true;
};

const useCompanyChecks = ({
    companies,
}: Pick<ContentAvailabilityOptions, 'companies'>) => {
    const currentStore = useCurrentStore();

    if (companies && !companies.includes(currentStore.companyCode)) {
        return false;
    }

    return true;
};

const useLoginStateCheck = ({
    loggedOutOnly,
    loggedInOnly,
}: Pick<ContentAvailabilityOptions, 'loggedInOnly' | 'loggedOutOnly'>) => {
    const isLoggedIn = useLoginState();

    if (loggedInOnly && !isLoggedIn) {
        return false;
    }

    if (loggedOutOnly && isLoggedIn) {
        return false;
    }

    return true;
};

/* eslint max-statements: ['error', 25], complexity: ['error', 16] */
const useContentAvailableCheck = ({
    loggedOutOnly,
    loggedInOnly,
    applications,
    stores,
    companies,
    permissions,
    featureFlag,
    storeFlags,
    attributes,
    preorder,
}: ContentAvailabilityOptions) => {
    const user = useCurrentUser();
    const customer = useCurrentCustomer();

    const storeCheckResult = useStoreChecks({ stores, storeFlags });
    const companyCheckResult = useCompanyChecks({ companies });

    const loginCheckResult = useLoginStateCheck({
        loggedOutOnly,
        loggedInOnly,
    });

    const { loading: preorderSeasonLoading, activePreorderSeasons } =
        useActivePreorderSeasons(!preorder);

    if (applications && !applications.includes(APP_ENV.APPLICATION)) {
        return {
            available: false,
        };
    }

    if (!companyCheckResult) {
        return {
            available: false,
        };
    }

    if (!storeCheckResult) {
        return {
            available: false,
        };
    }

    if (!loginCheckResult) {
        return {
            available: false,
        };
    }
    if (permissions && !hasPermissions(permissions, user)) {
        return {
            available: false,
        };
    }

    if (featureFlag && !checkFeatureFlags(featureFlag)) {
        return {
            available: false,
        };
    }

    if (attributes && !hasAttributes(attributes, customer)) {
        return {
            available: false,
        };
    }

    if (preorder) {
        return {
            available: activePreorderSeasons.length > 0,
            loading: preorderSeasonLoading,
        };
    }

    return {
        available: true,
    };
};

export default useContentAvailableCheck;
