import { gql } from '@apollo/client/core';

const impersonateCustomerMutation = gql`
    mutation impersonate($customerNumber: Int, $username: String) {
        impersonate(customerNumber: $customerNumber, username: $username) {
            username
            accessToken
        }
    }
`;

export const stopImpersonatingCustomerMutation = gql`
    mutation stopImpersonating {
        stopImpersonating {
            username
            accessToken
        }
    }
`;

export default impersonateCustomerMutation;
