import { DateTime } from 'luxon';
import type { ReactNode } from 'react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import withErrorBoundary from '../../../core/components/errors/withErrorBoundary';
import useDeliveryDatesQuery from '../../queries/useDeliveryDatesQuery';
import computeDefaultDeliveryDate from '../../util/computeDefaultDeliveryDate';
import DeliveryDatesContext from './DeliveryDatesContext';

type DeliveryDateTypeKey = 'brandName' | 'productGroupId' | 'variantGroupId';

const useDefaultDates = (
    applicableDeliveryDates: string[],
    defaultDeliveryDateString?: string
) => {
    const defaultDataFromApi = useMemo(() => {
        return defaultDeliveryDateString
            ? DateTime.fromISO(defaultDeliveryDateString)
            : undefined;
    }, [defaultDeliveryDateString]);

    const [defaultDeliveryDateState, setDefaultDeliveryDate] =
        useState(defaultDataFromApi);

    useEffect(() => {
        if (defaultDataFromApi !== defaultDeliveryDateState) {
            setDefaultDeliveryDate(defaultDataFromApi);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultDataFromApi]);

    const defaultDeliveryDate = useMemo(() => {
        return computeDefaultDeliveryDate(
            applicableDeliveryDates,
            defaultDeliveryDateState
        );
    }, [defaultDeliveryDateState, applicableDeliveryDates]);

    return {
        defaultDeliveryDate,
        defaultDeliveryDateState,
        setDefaultDeliveryDate,
    };
};

const useDeliveryDates = () => {
    const {
        loading,
        deliveryDates,
        defaultDeliveryDate: defaultDeliveryDateString,
        saveDeliveryDates,
        saveDefaultDeliveryDate,
        deliveryDatesUpdating,
        defaultDeliveryDateUpdating,
        applicableDeliveryDates = [],
    } = useDeliveryDatesQuery();

    const {
        defaultDeliveryDate,
        defaultDeliveryDateState,
        setDefaultDeliveryDate,
    } = useDefaultDates(applicableDeliveryDates, defaultDeliveryDateString);

    const updateDeliveryDate = useCallback(
        (
            deliveryDateTypeKey: DeliveryDateTypeKey,
            deliveryDateTypeValue: string,
            deliveryDate?: DateTime
        ) => {
            const updatedValue = deliveryDates?.slice() ?? [];

            const existingItemIndex = updatedValue.findIndex(
                item => item[deliveryDateTypeKey] === deliveryDateTypeValue
            );

            if (!deliveryDate) {
                if (existingItemIndex !== -1) {
                    updatedValue.splice(existingItemIndex, 1);
                } else {
                    return;
                }
            } else {
                const updatedItem = {
                    [deliveryDateTypeKey]: deliveryDateTypeValue,
                    deliveryDate: deliveryDate.toISODate(),
                };

                if (existingItemIndex === -1) {
                    updatedValue.push(updatedItem);
                } else {
                    updatedValue.splice(existingItemIndex, 1, updatedItem);
                }
            }

            saveDeliveryDates(updatedValue);
        },
        [deliveryDates, saveDeliveryDates]
    );

    const updateBrandDeliveryDate = useCallback(
        async (brandName: string, deliveryDate?: DateTime) =>
            updateDeliveryDate('brandName', brandName, deliveryDate),
        [updateDeliveryDate]
    );

    const updateProductGroupDeliveryDate = useCallback(
        async (productGroupId: string, deliveryDate?: DateTime) =>
            updateDeliveryDate('productGroupId', productGroupId, deliveryDate),
        [updateDeliveryDate]
    );

    const updateVariantGroupDeliveryDate = useCallback(
        async (variantGroupId: string, deliveryDate?: DateTime) =>
            updateDeliveryDate('variantGroupId', variantGroupId, deliveryDate),
        [updateDeliveryDate]
    );

    const updateProductDeliveryDate = useCallback(
        ({
            sku,
            sortIndex,
            deliveryDate,
            quantity,
        }: {
            sku: string;
            sortIndex: number;
            deliveryDate?: DateTime;
            quantity?: number;
        }) => {
            const currentValue = deliveryDates?.slice() ?? [];

            const existingItemIndex = currentValue.findIndex(
                item => item.sku === sku && item.sortIndex === sortIndex
            );

            if (deliveryDate) {
                const newItem = {
                    sku,
                    quantity: sortIndex === 0 ? undefined : quantity || 1,
                    sortIndex,
                    deliveryDate: deliveryDate.toISODate(),
                };

                if (existingItemIndex !== -1) {
                    currentValue.splice(existingItemIndex, 1, newItem);
                } else {
                    currentValue.push(newItem);
                }
            } else {
                currentValue.splice(existingItemIndex, 1);
            }

            const updatedValue = currentValue.slice();

            saveDeliveryDates(updatedValue);
        },
        [deliveryDates, saveDeliveryDates]
    );

    const updateDefaultDeliveryDate = useCallback(
        async (date?: DateTime) => {
            setDefaultDeliveryDate(date);
            return saveDefaultDeliveryDate(date?.toISODate());
        },
        [saveDefaultDeliveryDate, setDefaultDeliveryDate]
    );

    return {
        loading,
        deliveryDates: deliveryDates ?? [],
        deliveryDatesUpdating,
        defaultDeliveryDateUpdating,
        defaultDeliveryDate,
        defaultDeliveryDateState,
        applicableDeliveryDates,
        updateDefaultDeliveryDate,
        updateBrandDeliveryDate,
        updateProductGroupDeliveryDate,
        updateVariantGroupDeliveryDate,
        updateProductDeliveryDate,
    };
};

const DeliveryDatesProvider = ({ children }: { children: ReactNode }) => {
    const {
        loading,
        deliveryDates,
        deliveryDatesUpdating,
        defaultDeliveryDateUpdating,
        defaultDeliveryDate,
        updateDefaultDeliveryDate,
        updateBrandDeliveryDate,
        applicableDeliveryDates,
        updateProductGroupDeliveryDate,
        updateVariantGroupDeliveryDate,
        updateProductDeliveryDate,
        defaultDeliveryDateState,
    } = useDeliveryDates();

    return (
        <DeliveryDatesContext.Provider
            value={{
                loading,
                deliveryDatesUpdating,
                defaultDeliveryDateUpdating,
                defaultDeliveryDateState,
                deliveryDates,
                defaultDeliveryDate,
                updateDefaultDeliveryDate,
                applicableDeliveryDates,
                updateBrandDeliveryDate,
                updateProductGroupDeliveryDate,
                updateVariantGroupDeliveryDate,
                updateProductDeliveryDate,
            }}
        >
            {children}
        </DeliveryDatesContext.Provider>
    );
};

export default memo(withErrorBoundary(DeliveryDatesProvider));
