import { useContext } from 'react';
import useCustomerPreorderSummary from '../../pricing/queries/useCustomerPreorderSummary';
import PreorderModeContext from '../PreorderModeContext';

const usePreorderDiscountTier = () => {
    const { selectedPreorderSeason } = useContext(PreorderModeContext);
    const { customerPreorderSummary } = useCustomerPreorderSummary();

    if (!selectedPreorderSeason || !customerPreorderSummary) {
        return {
            amountToNextLevel: 0,
        };
    }

    const sortedDiscountTiers = selectedPreorderSeason.discountTiers.slice();
    sortedDiscountTiers.sort((a, b) => a.threshold - b.threshold);

    const currentLevelIndex = sortedDiscountTiers.findIndex(
        item => item.tier === customerPreorderSummary.discountTier
    );

    const currentLevel = sortedDiscountTiers[currentLevelIndex];

    if (customerPreorderSummary.isDiscountTierOverridden) {
        return {
            currentLevel,
            amountToNextLevel: 0,
        };
    }

    const nextLevel = sortedDiscountTiers[currentLevelIndex + 1];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const amountToNextLevel = nextLevel
        ? nextLevel.threshold - customerPreorderSummary.totalValue
        : 0;

    return {
        currentLevel,
        nextLevel,
        amountToNextLevel: amountToNextLevel,
    };
};

export default usePreorderDiscountTier;
