import { memo } from 'react';
import { clsx } from 'clsx';
import FadeAnimation from '../../animations/FadeAnimation';
import SuccessUpdateMessage from '../SuccessUpdateMessage';
import { ProgressSpinner } from '../../ProgressIndicators';
import ChevronDownIcon from '../../icons/ChevronDownIcon';
import styles from './Select.module.css';

const SelectIndication = ({
    isOpen,
    setIsOpen,
    formStyle = false,
    loading = false,
    successMessage,
    showSuccessMessage,
    noPadding,
}: {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
    noPadding?: boolean;
    formStyle?: boolean;
    loading?: boolean;
    successMessage?: string;
    showSuccessMessage?: boolean;
}) => {
    return (
        <div
            className={clsx(styles.dropdownIndicator, {
                [styles.padding]: !noPadding,
                [styles.formStyle]: formStyle,
            })}
        >
            <FadeAnimation show={!!showSuccessMessage}>
                <SuccessUpdateMessage messageId={successMessage} />
            </FadeAnimation>
            {loading ? (
                <ProgressSpinner />
            ) : (
                <ChevronDownIcon
                    className={clsx(styles.dropdownIndicatorIcon, {
                        [styles.dropdownIndicatorIconActive]: isOpen,
                    })}
                    large
                    onClick={e => {
                        e.stopPropagation();
                        setIsOpen(currentlyOpen => !currentlyOpen);
                    }}
                />
            )}
        </div>
    );
};

export default memo(SelectIndication);
