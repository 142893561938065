import { memo } from 'react';
import type { ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './FadeAnimation.module.css';

const FadeAnimation = ({
    show,
    children,
}: {
    show: boolean;
    children: ReactNode;
}) => {
    return (
        <CSSTransition
            in={show}
            unmountOnExit
            timeout={200}
            classNames={{ ...styles }}
        >
            {children}
        </CSSTransition>
    );
};

export default memo(FadeAnimation);
