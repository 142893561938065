import { useLocation, useSearchParams } from 'react-router-dom';
import assert from '../../core/util/assert';
import useCartList from '../queries/cartApi/useCartList';
import type { CartType } from '../types';

const useActiveCart = (cartType: CartType) => {
    const [searchParams] = useSearchParams();
    const { carts, loading } = useCartList(cartType, 120_000);
    const location = useLocation();

    if (!carts) {
        return {
            loading,
            shouldRedirect: false,
            canSubmit: false,
        };
    }

    const defaultCart = carts.find(item => item.default);
    assert(defaultCart, 'No default cart found');
    const cartId = searchParams.get('cartId');

    const cartFromUrl = cartId && carts.find(item => item.id === +cartId);
    const selectedCart = cartFromUrl || defaultCart;
    return {
        cartId: selectedCart.id,
        canSubmit: selectedCart.canSubmit,
        loading,
        shouldRedirect:
            cartId &&
            +cartId !== selectedCart.id &&
            !location.pathname.includes('/checkout'),
    };
};

export default useActiveCart;
