import { memo, useState } from 'react';
import { DateTime } from 'luxon';
import { FilePreview } from '@library';
import { DEFAULT_DATE_FORMAT } from '../../../../core/constants';
import getUserName from '../../../../user/util/getUserName';
import type { AttachmentDetails } from './types';

const getAction = (
    deleteFile: boolean,
    loading: boolean,
    detailView?: boolean
) => {
    if (detailView) {
        return 'download';
    }
    if (loading) {
        return 'loading';
    }
    if (deleteFile) {
        return 'delete';
    }
    return null;
};

const AttachmentPreview = ({
    attachment,
    attachmentId,
    deleteFile,
    detailView,
}: {
    attachment: AttachmentDetails;
    attachmentId: number;
    deleteFile?: (attachmentId: number) => Promise<unknown>;
    detailView?: boolean;
}) => {
    const [loading, setLoading] = useState(false);

    const startDeleting = async () => {
        setLoading(true);
        try {
            await deleteFile?.(attachmentId);
        } finally {
            setLoading(false);
        }
    };

    const uploadDate =
        detailView && attachment.event
            ? DateTime.fromISO(attachment.event.created).toFormat(
                  DEFAULT_DATE_FORMAT
              )
            : undefined;

    const uploader =
        detailView && attachment.event
            ? getUserName(attachment.event.user)
            : undefined;

    return (
        <FilePreview
            size={attachment.sourceFileSize}
            preview={attachment.thumbnail}
            name={attachment.sourceFileName}
            sourceFile={attachment.sourceFile}
            onAction={() => {
                startDeleting().catch(error => {
                    throw error;
                });
            }}
            uploadDate={uploadDate}
            uploader={uploader}
            action={getAction(!!deleteFile, loading, detailView)}
            detailView={detailView}
        />
    );
};

export default memo(AttachmentPreview);
