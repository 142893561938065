import { memo } from 'react';
import { Helmet } from 'react-helmet';

import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from '@library';
import useCurrentStore from '../hooks/useCurrentStore';
import Breadcrumbs from './router/Breadcrumbs';
import styles from './LinkBreadcrumbs.module.scss';
import CurrentStoreLink from './router/CurrentStoreLink';
import Container from './Container';
import CustomLink from './CustomLink';
import withErrorBoundary from './errors/withErrorBoundary';

export interface BreadcrumbLink {
    label?: string;
    labelId?: string;
    to: string;
    isStore?: number;
    isRelative?: boolean;
}

const LinkBreadcrumbs = ({
    title,
    titleId,
    breadcrumbs,
}: {
    title?: string;
    titleId?: string;
    breadcrumbs: BreadcrumbLink[];
}) => {
    const intl = useIntl();
    const currentTitleText = titleId
        ? intl.formatMessage({ id: titleId })
        : title || '';

    const { companyName: storeTitle } = useCurrentStore();

    return (
        <div className={styles.container}>
            <Helmet>
                <title>
                    {currentTitleText} - {storeTitle}
                </title>
            </Helmet>
            <Container>
                <Breadcrumbs separator="›">
                    <CurrentStoreLink
                        className={styles.breadcrumbLink}
                        key="home"
                        to=""
                        data-test-class="breadcrumbsLink"
                    >
                        <Text tertiary uppercase>
                            <FormattedMessage id="home page" />
                        </Text>
                    </CurrentStoreLink>

                    {breadcrumbs.map(item => {
                        const text = (
                            <Text tertiary uppercase>
                                {item.labelId ? (
                                    <FormattedMessage id={item.labelId} />
                                ) : (
                                    item.label
                                )}
                            </Text>
                        );
                        return item.isRelative ? (
                            <CustomLink
                                to={item.to}
                                key={item.to}
                                className={styles.breadcrumbLink}
                                data-test-class="breadcrumbsLink"
                            >
                                {text}
                            </CustomLink>
                        ) : (
                            <CurrentStoreLink
                                to={item.to}
                                key={item.to}
                                className={styles.breadcrumbLink}
                                data-test-class="breadcrumbsLink"
                            >
                                {text}
                            </CurrentStoreLink>
                        );
                    })}
                </Breadcrumbs>
            </Container>
        </div>
    );
};

export default memo(withErrorBoundary(LinkBreadcrumbs));
