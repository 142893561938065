import { gql } from '@apollo/client';
import useCurrentCartId from '../../cart/hooks/useCurrentCartId';
import usePolledQuery from '../../core/hooks/apollo/usePolledQuery';

export interface CustomerPreorderSummary {
    discountTier?: string;
    totalValue: number;
    isDiscountTierOverridden: boolean;
}
export const customerPreorderSummary = gql`
    query customerPreorderSummary($cartId: Int!) {
        customerPreorderSummary(cartId: $cartId) {
            discountTier
            totalValue
            isDiscountTierOverridden
        }
    }
`;

const useCustomerPreorderSummary = () => {
    const cartId = useCurrentCartId();

    const { data, loading, error } = usePolledQuery<{
        customerPreorderSummary?: CustomerPreorderSummary;
    }>(customerPreorderSummary, {
        variables: {
            cartId,
        },
        pollInterval: 180_000,
        skip: !cartId,
    });

    if (error) {
        throw error;
    }

    return {
        customerPreorderSummary: data?.customerPreorderSummary,
        loading,
    };
};

export default useCustomerPreorderSummary;
