import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { getTicketRefetchQuery } from './useTicket';

const deleteTicketEventAttachmentMutation = gql`
    mutation DeleteTicketEventAttachment(
        $ticketId: Int!
        $eventId: String!
        $attachmentId: Int!
    ) {
        deleteTicketEventAttachment(
            ticketId: $ticketId
            eventId: $eventId
            attachmentId: $attachmentId
        )
    }
`;

const useDeleteTicketEventAttachment = (
    refetchQueries?: InternalRefetchQueriesInclude
) => {
    const [deleteAttachment, { loading, error }] = useMutation(
        deleteTicketEventAttachmentMutation,
        { awaitRefetchQueries: true }
    );

    const deleteTicketEventAttachment = useCallback(
        async (ticketId: number, eventId: string, attachmentId: number) => {
            await deleteAttachment({
                variables: { ticketId, eventId, attachmentId },
                refetchQueries: refetchQueries
                    ? refetchQueries
                    : [getTicketRefetchQuery(ticketId)],
            });
        },
        [deleteAttachment, refetchQueries]
    );

    return {
        deleteTicketEventAttachment,
        loading,
        error,
    };
};

export default useDeleteTicketEventAttachment;
