import { memo } from 'react';
import type { ReactNode } from 'react';
import TextBlock from '../typography/TextBlock';
import styles from './FormField.module.scss';

const FormField = ({
    children,
    label,
    hint,
    error,
    id,
    required,
    topHint,
    ...ariaProps
}: {
    children: ReactNode;
    /**
     * Shown on top of input field.
     */
    label?: ReactNode;
    /**
     * Shown below input field as help text.
     */
    hint?: ReactNode;
    /**
     * Shown under label field as help text.
     */
    topHint?: ReactNode;
    /**
     * Error message to be shown (if any).
     */
    error?: ReactNode;
    /**
     * ID of the input field to connect with label.
     */
    id?: string;

    /**
     * The field is required
     */
    required?: boolean;
}) => {
    return (
        <div {...ariaProps}>
            {(label || topHint) && (
                <label htmlFor={id} className={styles.label}>
                    <TextBlock primary>
                        {label}
                        {required && <span className={styles.required}>*</span>}
                    </TextBlock>
                    {topHint && (
                        <div className={styles.topHint}>
                            <TextBlock secondary>{topHint}</TextBlock>
                        </div>
                    )}
                </label>
            )}

            {children}
            {hint && (
                <div className={styles.hint}>
                    <TextBlock secondary>{hint}</TextBlock>
                </div>
            )}
            {error && (
                <div
                    className={styles.error}
                    data-test-class="formFieldError"
                    data-test-state={id}
                >
                    <TextBlock secondary>{error}</TextBlock>
                </div>
            )}
        </div>
    );
};

export default memo(FormField);
