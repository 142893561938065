import { memo } from 'react';
import Stack from '../../layout/Stack';
import type { CheckboxOptionsProps } from './CheckboxOptions';
import CheckboxOptions from './CheckboxOptions';

interface CheckboxGroupProps extends CheckboxOptionsProps {
    /**
     * The direction of radio group.
     */
    direction?: 'column' | 'row';
}

/**
 * The checkbox group component.
 */
const RadioGroup = ({ direction = 'row', ...props }: CheckboxGroupProps) => {
    return (
        <Stack gap="small" direction={direction}>
            <CheckboxOptions {...props} />
        </Stack>
    );
};

export default memo(RadioGroup);
