import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import type { SupportTicket } from '@apiTypes';
import { VStack, Heading, HStack, Text } from '@library';
import TicketStatusView from '../../Common/TicketStatusView';
import { formatTicketNumber } from '../../../domain';
import { DEFAULT_DATE_FORMAT } from '../../../../core/constants';
import { useProductClass } from '../../../queries/useProductClasses';
import styles from './DetailsHeader.module.scss';

const DetailsHeader = ({ ticket }: { ticket: SupportTicket }) => {
    const intl = useIntl();
    const isCreated = ticket.id !== -1;
    const productClass = useProductClass(ticket.type, ticket.productClassId);
    return (
        <div className={styles.detailsHeader}>
            <HStack gap="smallMedium" wrap>
                <div className={styles.detailsBlock}>
                    <VStack gap="mini">
                        <HStack gap="mini">
                            <Heading h2 bold>
                                <span className={styles.titleText}>
                                    <FormattedMessage
                                        id="Tickets Ticket Title"
                                        values={{
                                            id: isCreated
                                                ? formatTicketNumber(ticket.id)
                                                : '',
                                        }}
                                    />
                                </span>
                            </Heading>
                            {isCreated && (
                                <TicketStatusView status={ticket.status} />
                            )}
                        </HStack>
                        <HStack gap="mini" wrap>
                            <HStack gap="micro">
                                <Text medium>
                                    <FormattedMessage id="Tickets Ticket Header Type" />
                                    :
                                </Text>
                                <Text>
                                    {intl.formatMessage({
                                        id: `Tickets Common TicketType_${ticket.type}`,
                                    })}
                                </Text>
                            </HStack>

                            {productClass && (
                                <HStack gap="micro">
                                    <Text medium>
                                        <FormattedMessage id="Tickets Ticket Header ProductClass" />
                                        :
                                    </Text>
                                    <Text>
                                        {intl.formatMessage({
                                            id: productClass.name,
                                        })}
                                    </Text>
                                </HStack>
                            )}

                            {isCreated && (
                                <>
                                    <HStack gap="micro">
                                        <Text medium>
                                            <FormattedMessage id="Tickets Ticket Header LastUpdateDate" />
                                            :
                                        </Text>
                                        <Text>
                                            {DateTime.fromISO(
                                                ticket.updated
                                            ).toFormat(DEFAULT_DATE_FORMAT)}
                                        </Text>
                                    </HStack>
                                    <HStack gap="micro">
                                        <Text medium>
                                            <FormattedMessage id="Tickets Ticket Header CreationDate" />
                                            :
                                        </Text>
                                        <Text>
                                            {DateTime.fromISO(
                                                ticket.created
                                            ).toFormat(DEFAULT_DATE_FORMAT)}
                                        </Text>
                                    </HStack>
                                    <HStack gap="micro">
                                        <Text medium>
                                            <FormattedMessage id="Tickets Ticket Header CreatedBy" />
                                            :
                                        </Text>
                                        <Text>{ticket.creator?.username}</Text>
                                    </HStack>
                                </>
                            )}
                        </HStack>
                    </VStack>
                </div>
            </HStack>
        </div>
    );
};

export default memo(DetailsHeader);
