import { useCallback, useEffect, useState } from 'react';
import { useCartFieldsContext } from '../../cart/contexts/CartFields/CartFieldsContext';
import useImmutableCallback from '../../core/hooks/useImmutableCallback';
import useObservableVariableState from '../../core/hooks/useObservableVariableState';
import type { CartFields } from '../types';
import useSaveCartFields from './useSaveCartFields';

/**
 *
 * @param name Name of the cart field
 * @param preventResetStateOnUpdateFailure A flag to instruct the hook not to reset state when update fails.
 * This is added for specifically text input fields like comment/reference on checkout page where clearing
 * user's input is not the ideal reaction to a server failure.
 */
const useCartField = <T = string>(
    name: keyof CartFields,
    preventResetStateOnUpdateFailure = false
): [T | undefined, (updatedValue?: T) => void, boolean] => {
    const { cartFieldsObservable } = useCartFieldsContext();
    const [cartFields] = useObservableVariableState(cartFieldsObservable);

    const [value, setValue] = useState<T | undefined>(undefined);

    const onError = useCallback(() => {
        if (preventResetStateOnUpdateFailure) {
            return;
        }

        setValue(cartFields?.[name] as T | undefined);
    }, [cartFields, name, preventResetStateOnUpdateFailure]);

    const { isUpdatePending, saveCartFields, isFieldUpdating } =
        useSaveCartFields(onError);

    useEffect(() => {
        if (!isUpdatePending.current) {
            setValue(cartFields?.[name] as T | undefined);
        }
    }, [cartFields, name, isUpdatePending]);

    const updateValue = useImmutableCallback((updatedValue?: T) => {
        setValue(updatedValue);
        // We need to explicitly send null to unset a field. With undefined,
        // the field doesn't get transmitted to server.
        saveCartFields({ [name]: updatedValue || null });
    });

    return [value, updateValue, isFieldUpdating];
};

export default useCartField;
