import { memo } from 'react';
import type { IconProps } from './types';
import BaseIcon from './BaseIcon';

const InfoIcon = memo((props: IconProps) => {
    return (
        <BaseIcon viewBox="0 0 20 21" {...props} fill="none">
            <>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.75 2.25C7.56196 2.25 5.46354 3.11919 3.91637 4.66637C2.36919 6.21354 1.5 8.31196 1.5 10.5C1.5 11.5834 1.71339 12.6562 2.12799 13.6571C2.54259 14.6581 3.15029 15.5675 3.91637 16.3336C4.68245 17.0997 5.59193 17.7074 6.59286 18.122C7.5938 18.5366 8.66659 18.75 9.75 18.75C10.8334 18.75 11.9062 18.5366 12.9071 18.122C13.9081 17.7074 14.8175 17.0997 15.5836 16.3336C16.3497 15.5675 16.9574 14.6581 17.372 13.6571C17.7866 12.6562 18 11.5834 18 10.5C18 8.31196 17.1308 6.21354 15.5836 4.66637C14.0365 3.11919 11.938 2.25 9.75 2.25ZM2.85571 3.60571C4.68419 1.77723 7.16414 0.75 9.75 0.75C12.3359 0.75 14.8158 1.77723 16.6443 3.60571C18.4728 5.43419 19.5 7.91414 19.5 10.5C19.5 11.7804 19.2478 13.0482 18.7578 14.2312C18.2678 15.4141 17.5497 16.4889 16.6443 17.3943C15.7389 18.2997 14.6641 19.0178 13.4812 19.5078C12.2982 19.9978 11.0304 20.25 9.75 20.25C8.46961 20.25 7.20176 19.9978 6.01884 19.5078C4.83591 19.0178 3.76108 18.2997 2.85571 17.3943C1.95034 16.4889 1.23216 15.4141 0.742174 14.2312C0.252191 13.0482 0 11.7804 0 10.5C5.96046e-08 7.91414 1.02723 5.43419 2.85571 3.60571ZM9 6.75C9 6.33579 9.33579 6 9.75 6H9.758C10.1722 6 10.508 6.33579 10.508 6.75V6.758C10.508 7.17221 10.1722 7.508 9.758 7.508H9.75C9.33579 7.508 9 7.17221 9 6.758V6.75ZM8.70958 9.05719C8.96509 8.93044 9.25147 8.87915 9.53515 8.90938C9.82021 8.93976 10.0906 9.0512 10.3143 9.23049C10.538 9.40978 10.7056 9.64943 10.7973 9.92104C10.8891 10.1927 10.901 10.4849 10.8318 10.7631L10.1237 13.5997L10.1581 13.5825C10.5268 13.3936 10.9787 13.5394 11.1675 13.9081C11.3564 14.2768 11.2106 14.7287 10.8419 14.9175L10.7945 14.9418C10.5379 15.0702 10.2498 15.1222 9.96446 15.0918C9.67914 15.0613 9.40854 14.9497 9.18474 14.7701C8.96095 14.5905 8.79336 14.3505 8.70185 14.0786C8.61041 13.8068 8.59878 13.5145 8.66833 13.2363L9.37619 10.4009L9.36984 10.4041L9.32882 10.4241C8.95654 10.6057 8.50753 10.4511 8.32593 10.0788C8.14432 9.70654 8.2989 9.25753 8.67118 9.07593L8.70958 9.05719Z"
                    fill="currentColor"
                />
            </>
        </BaseIcon>
    );
});

export default memo(InfoIcon);
