/* eslint max-lines: ["error", 500] */
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { gql, Observable } from '@apollo/client';
import { debounce } from 'lodash-es';
import type { StoreId } from '../../core/types/stores';
import type { ObservableVariable } from '../../core/util/makeObservableVariable';
import makeObservableVariable from '../../core/util/makeObservableVariable';
import userCartQuery from '../queries/userCartQuery';
import type { Cart, CartItem } from '../types';
import { CartErrors } from '../types';
import updateCartItemQuantityMutation from '../queries/updateCartItemQuantityMutation';
import updateBulkCartItemQuantityMutation from '../queries/updateBulkCartItemQuantityMutation';
import updateCartItemCommentMutation from '../queries/updateCartItemCommentMutation';
import watchPolledQuery from '../../core/util/apollo/watchPolledQuery';
import LocalCart from './LocalCart';

const clearCartMutation = gql`
    mutation clearCart($store: ID!, $cartId: Int!) {
        clearCart(store: $store, cartId: $cartId) {
            success
        }
    }
`;

const requiresPrepayQuery = gql`
    query checkoutRequiresPrepay(
        $language: Language!
        $store: ID!
        $cartId: Int!
    ) {
        checkout(language: $language, store: $store, cartId: $cartId) {
            requiresPrepay
        }
    }
`;

export type RemoteCartType = 'user' | 'customerPreorder';

interface CartException {
    code: CartErrors;
    error: Error;
}

interface CartInCache {
    cart: {
        items: CartItem[];
    };
}

class RemoteCart implements Cart {
    private readonly cartBusyObservableVariable: ObservableVariable<boolean>;

    private readonly cartUpdatedObservableVariable: ObservableVariable<unknown>;

    private quantityUpdatePending = false;

    private commentUpdatePending = false;

    private clearCartPending = false;

    private lastConfirmedCartState: CartItem[] = [];

    readonly cartBusy: Observable<boolean>;

    readonly cartUpdated: Observable<unknown>;

    readonly cartRequiresPrepay: Observable<boolean>;

    readonly items: Observable<CartItem[]>;

    private pendingQuantityUpdates = new Map<string, number>();

    private readonly cartErrorsObservableVariable: ObservableVariable<
        CartException | undefined
    >;

    readonly cartErrors: Observable<CartException | undefined>;

    private refetchCart: () => Promise<void>;

    /* eslint max-params: ['error', 4] */
    constructor(
        private readonly client: ApolloClient<NormalizedCacheObject>,
        private readonly store: StoreId,
        public readonly id?: number
    ) {
        this.cartBusyObservableVariable = makeObservableVariable(false);
        this.cartBusy = this.cartBusyObservableVariable.onValueChange;

        this.cartUpdatedObservableVariable = makeObservableVariable({}, true);
        this.cartUpdated = this.cartUpdatedObservableVariable.onValueChange;

        this.cartRequiresPrepay = this.cartBusyObservableVariable.onValueChange;

        this.cartErrorsObservableVariable = makeObservableVariable<
            CartException | undefined
        >(undefined, true);
        this.cartErrors = this.cartErrorsObservableVariable.onValueChange;

        LocalCart.clearAlert();

        const { observable: queryObservable } = watchPolledQuery<{
            cart: {
                items: CartItem[];
            };
        }>(client, {
            query: userCartQuery,
            pollInterval: 30_000,
            variables: {
                store,
                id,
            },
        });

        const { observable: requiresPrepayQueryObservable } = watchPolledQuery<{
            checkout: {
                requiresPrepay: boolean;
            };
        }>(client, {
            query: requiresPrepayQuery,
            pollInterval: 30_000,
            variables: {
                store,
                language: 'de',
                cartId: id,
            },
            fetchPolicy: 'network-only',
        });

        this.cartRequiresPrepay = requiresPrepayQueryObservable.map(
            result => result.data.checkout.requiresPrepay
        );

        this.refetchCart = async () => {
            if (!this.id) {
                return;
            }

            await queryObservable.refetch({ store, id });
            await requiresPrepayQueryObservable.refetch({
                store,
                language: 'de',
                cartId: id,
            });
        };

        this.items = new Observable(subscriber => {
            if (!id) {
                return;
            }

            const subscription = queryObservable.subscribe(
                ({ data }) => {
                    this.lastConfirmedCartState = data.cart.items;

                    subscriber.next(data.cart.items);
                },
                error => {
                    this.cartErrorsObservableVariable.updateValue({
                        code: CartErrors.REFETCH_ERROR,
                        error,
                    });
                    subscriber.error(error);
                },
                () => {
                    subscriber.complete();
                }
            );

            return () => {
                subscription.unsubscribe();
            };
        });
    }

    fetchCart() {
        this.refetchCart()
            .catch(e => {
                this.cartErrorsObservableVariable.updateValue({
                    code: CartErrors.REFETCH_ERROR,
                    error: e,
                });
            })
            .finally(() => {
                this.finishUpdate();
            });
    }

    private triggerUpdateCartBusy() {
        const isBusy =
            this.quantityUpdatePending ||
            this.commentUpdatePending ||
            this.clearCartPending;

        if (this.cartBusyObservableVariable.currentValue !== isBusy) {
            this.cartBusyObservableVariable.updateValue(isBusy);
        }
    }

    private resetCacheToLastCartState() {
        this.updateCartInCache(() => ({
            cart: {
                items: this.lastConfirmedCartState,
            },
        }));
    }

    private performUpdateItemQuantity = debounce(() => {
        const quantityUpdates = new Map(this.pendingQuantityUpdates.entries());
        this.pendingQuantityUpdates.clear();

        this.updateItemQuantitiesInCache(Array.from(quantityUpdates.entries()));

        Promise.all(
            Array.from(quantityUpdates.entries()).map(async ([sku, quantity]) =>
                this.client.mutate({
                    mutation: updateCartItemQuantityMutation,
                    variables: {
                        sku,
                        quantity,
                        store: this.store,
                        cartId: this.id,
                    },
                })
            )
        )
            .catch(async error => {
                this.resetCacheToLastCartState();
                this.fetchCart();

                this.cartErrorsObservableVariable.updateValue({
                    code: CartErrors.QUANTITY_UPDATE_ERROR,
                    error: error,
                });
            })
            .finally(() => {
                this.finishUpdateQuantity();
            });
    }, 1000);

    updateItemQuantitiesInCache(
        quantityUpdates: [sku: string, quantity: number][]
    ) {
        this.updateCartInCache(data => {
            let currentItems = [...(data?.cart.items ?? [])];

            for (const [sku, quantity] of quantityUpdates) {
                const index = currentItems.findIndex(
                    currentItem => currentItem.sku === sku
                );
                const item = { ...currentItems[index] };

                if (index > -1) {
                    if (quantity === 0) {
                        currentItems.splice(index, 1);
                    } else {
                        currentItems.splice(index, 1, {
                            ...item,
                            quantity,
                        });
                    }
                } else {
                    currentItems = [
                        ...currentItems,
                        {
                            sku,
                            quantity,
                            comment: '',
                        },
                    ];
                }
            }

            return {
                cart: {
                    items: currentItems,
                },
            };
        });
    }

    updateItemQuantity(sku: string, quantity: number) {
        this.quantityUpdatePending = true;
        this.triggerUpdateCartBusy();

        this.pendingQuantityUpdates.set(sku, quantity);
        this.performUpdateItemQuantity();
    }

    updateItemQuantityAndComment(
        sku: string,
        quantity: number,
        comment?: string
    ) {
        this.updateCartInCache(data => {
            const currentItems = [...(data?.cart.items ?? [])];

            const index = currentItems.findIndex(
                currentItem => currentItem.sku === sku
            );

            const item = { ...currentItems[index] };

            if (index > -1) {
                if (quantity === 0) {
                    currentItems.splice(index, 1);
                } else {
                    currentItems.splice(index, 1, {
                        ...item,
                        quantity,
                        comment,
                    });
                }
            } else {
                currentItems.push({
                    sku,
                    quantity,
                    comment,
                });
            }

            return {
                cart: {
                    items: currentItems,
                },
            };
        });
        this.client
            .mutate({
                mutation: updateCartItemQuantityMutation,
                variables: {
                    sku,
                    quantity,
                    store: this.store,
                    cartId: this.id,
                },
            })
            .then(async () => {
                return this.client.mutate({
                    mutation: updateCartItemCommentMutation,
                    variables: {
                        sku,
                        comment,
                        store: this.store,
                        cartId: this.id,
                    },
                });
            })
            .catch(error => {
                throw error;
            })
            .finally(() => {
                this.quantityUpdatePending = false;
                this.commentUpdatePending = false;
                this.finishUpdate();
            });
    }

    updateBulkItemQuantities(
        items: { sku: string; quantity: number }[],
        replace = false
    ) {
        this.quantityUpdatePending = true;
        this.triggerUpdateCartBusy();

        (async () => {
            if (replace) {
                await this.runClearCartMutation();
            }

            this.updateItemQuantitiesInCache(
                items.map(({ sku, quantity }) => [sku, quantity])
            );

            await this.client.mutate({
                mutation: updateBulkCartItemQuantityMutation,
                variables: {
                    cartId: this.id,
                    items,
                },
            });
        })()
            .catch(error => {
                this.resetCacheToLastCartState();
                this.fetchCart();

                this.cartErrorsObservableVariable.updateValue({
                    code: CartErrors.BULK_QUANTITY_UPDATE_ERROR,
                    error: error,
                });
            })
            .finally(() => {
                this.finishUpdateQuantity();
            });
    }

    finishUpdateQuantity() {
        this.quantityUpdatePending = false;
        this.finishUpdate();
    }

    async updateItemComment(sku: string, comment: string) {
        this.updateCartInCache(data => {
            const currentItems = [...(data?.cart.items ?? [])];
            const index = currentItems.findIndex(
                currentItem => currentItem.sku === sku
            );

            const item = { ...currentItems[index] };

            currentItems.splice(index, 1, {
                ...item,
                comment,
            });

            return {
                cart: {
                    items: currentItems,
                },
            };
        });

        await this.client.mutate({
            mutation: updateCartItemCommentMutation,
            variables: {
                sku,
                comment,
                store: this.store,
                cartId: this.id,
            },
        });
    }

    clearCart() {
        this.clearCartPending = true;
        this.triggerUpdateCartBusy();

        this.updateCartInCache(() => ({ cart: { items: [] } }));

        this.runClearCartMutation()
            .catch(error => {
                this.resetCacheToLastCartState();
                this.fetchCart();

                this.cartErrorsObservableVariable.updateValue({
                    code: CartErrors.CLEAR_CART_ERROR,
                    error: error,
                });
            })
            .finally(() => {
                this.clearCartPending = false;
                this.finishUpdate();
            });
    }

    private async runClearCartMutation() {
        return this.client.mutate({
            mutation: clearCartMutation,
            variables: {
                store: this.store,
                cartId: this.id,
            },
        });
    }

    private updateCartInCache(
        update: (data: CartInCache | null) => CartInCache
    ) {
        this.client.cache.updateQuery<CartInCache>(
            {
                query: userCartQuery,
                variables: { store: this.store, id: this.id },
                broadcast: true,
            },
            update
        );
    }

    private finishUpdate() {
        this.triggerUpdateCartBusy();
        this.cartUpdatedObservableVariable.updateValue({});
    }
}

export default RemoteCart;
