import notFalsy from '../../core/util/notFalsy';

const getUserName = ({
    username,
    firstName,
    lastName,
}: {
    username: string;
    firstName?: string;
    lastName?: string;
}) => {
    if (firstName || lastName) {
        return [firstName, lastName].filter(notFalsy).join(' ');
    }

    return username;
};

export default getUserName;
