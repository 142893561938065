import type { ReactNode } from 'react';
import { memo, useContext, useRef } from 'react';
import { clsx } from 'clsx';
import { FormattedMessage } from 'react-intl';
import { HStack, VStack } from '../../layout';
import { Text } from '../../typography';
import { LayoutContext } from '../../../contexts';
import type { UseUppyInputParams } from './hooks/useUppyInput';
import useUppyInput from './hooks/useUppyInput';
import styles from './FileField.module.scss';
import FilePreview from './FilePreview';
import FileInput from './FileInput';

const FileField = ({
    files: filesExternal,
    accept,
    internalState,
    onFilesUpdate,
    onUploadComplete,
    disabled,
    theme,
    maxFileSize,
    children,
}: Pick<
    UseUppyInputParams,
    | 'accept'
    | 'internalState'
    | 'onUploadComplete'
    | 'onFilesUpdate'
    | 'files'
    | 'maxFileSize'
> & {
    /**
     * Disabled state of field
     */
    disabled?: boolean;
    /**
     * The theme prop for customization
     */
    theme?: string;
    children?: ReactNode;
}) => {
    const dropRef = useRef<HTMLDivElement>(null);

    const { uppy, dropActive, dropError, files, removeFile } = useUppyInput({
        accept,
        dropUploadRef: dropRef,
        onUploadComplete,
        multiple: true,
        autoProceed: true,
        internalState: internalState,
        files: filesExternal,
        onFilesUpdate,
        maxFileSize,
    });

    const { isInMobileView } = useContext(LayoutContext);

    return (
        <VStack gap="mini">
            <div
                ref={dropRef}
                className={clsx(styles.area, theme, {
                    [styles.active]: dropActive,
                    [styles.dropError]: dropError,
                    [styles.disabled]: disabled,
                })}
            >
                <div className={styles.content}>
                    <VStack gap="small" expanded>
                        <div className={styles.controlBlock}>
                            <VStack gap="mini">
                                <HStack
                                    align={
                                        isInMobileView ? 'center' : 'baseline'
                                    }
                                    gap="micro"
                                >
                                    {!isInMobileView && (
                                        <Text>
                                            <FormattedMessage id="Library FileField Placeholder" />
                                        </Text>
                                    )}

                                    <FileInput uppy={uppy} />
                                </HStack>
                            </VStack>
                        </div>

                        {dropError && (
                            <Text>
                                <FormattedMessage id="Library FileField DropError" />
                            </Text>
                        )}

                        {(children || files.length > 0) && (
                            <HStack wrap gap="small" expanded>
                                {children}
                                {files.map(file => {
                                    return (
                                        <FilePreview
                                            key={file.id}
                                            size={file.size}
                                            preview={file.preview}
                                            name={file.name}
                                            action={
                                                file.progress?.uploadComplete
                                                    ? 'delete'
                                                    : 'loading'
                                            }
                                            onAction={() => {
                                                removeFile(file.id);
                                            }}
                                        />
                                    );
                                })}
                            </HStack>
                        )}
                    </VStack>
                </div>
            </div>
        </VStack>
    );
};

export default memo(FileField);
