import { useMutation } from '@apollo/client';
import { storeAccessToken } from '../../core/util/auth/credentialsStorage';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import assert from '../../core/util/assert';
import impersonateCustomerMutation, {
    stopImpersonatingCustomerMutation,
} from './impersonateCustomerMutation';

const impersonateToToken = (token: string, targetPath: string) => {
    storeAccessToken(token, false);
    window.location.href = `${document.baseURI}${targetPath.slice(
        1,
        targetPath.length
    )}`;
};

const useLoginAsCustomerOrUser = () => {
    const { storeRootPath } = useStoreAndLanguageParams();

    const [impersonate] = useMutation<{
        impersonate: {
            username: string;
            accessToken: string;
        };
    }>(impersonateCustomerMutation);

    return async ({
        customerNumber,
        username,
    }: {
        customerNumber?: number;
        username?: string;
    }) => {
        assert(
            !(customerNumber && username),
            'You can either impersonate a customer or a user, but not both'
        );
        assert(
            customerNumber || username,
            'Either customerNumber or username must be provided'
        );

        const { data } = await impersonate({
            variables: {
                customerNumber,
                username,
            },
        });

        if (data) {
            impersonateToToken(
                data.impersonate.accessToken,
                `${storeRootPath}/account`
            );
        }
    };
};

export const useStopImpersonation = () => {
    const { storeRootPath } = useStoreAndLanguageParams();

    const [stopImpersonation, { loading }] = useMutation<{
        stopImpersonating: {
            username: string;
            accessToken: string;
        };
    }>(stopImpersonatingCustomerMutation);

    return {
        stopImpersonation: async () => {
            const { data } = await stopImpersonation();

            if (data) {
                impersonateToToken(
                    data.stopImpersonating.accessToken,
                    `${storeRootPath}/account`
                );
            }
        },
        loading,
    };
};

export default useLoginAsCustomerOrUser;
