import { memo } from 'react';
import type { SupportTicketEventDocumentTypes } from '@apiTypes';
import type { FileDetails } from '@library';
import { VStack } from '@library';
import useTicketSchema from '../../../queries/useTicketSchema';
import { isTypeNameFilter } from '../../../types';
import FileSection from './FileSection';
import { getFileSections, type FilesSectionProps } from './FilesSection';
import { attachmentsEventTypeNames } from './types';

const FilesSectionForm = ({
    currentProductClass,
    id,
    type,
    submitted,
    readonly,
    qrCode,
    detailView,
    uploadPermissionToken,
    filesMap,
    onFileMapChange,
    disabled,
    events,
}: FilesSectionProps & {
    filesMap: Record<
        SupportTicketEventDocumentTypes,
        FileDetails[] | undefined
    >;
    onFileMapChange: (
        value: Record<
            SupportTicketEventDocumentTypes,
            FileDetails[] | undefined
        >
    ) => void;
    disabled?: boolean;
}) => {
    const { ticketSchema } = useTicketSchema();

    const fileSections = getFileSections({
        type,
        currentProductClass,
        readonly,
        ticketSchema,
    });

    const attachmentsEvents = events.filter(
        isTypeNameFilter(attachmentsEventTypeNames.slice())
    );

    for (const fileSection of fileSections) {
        const attachmentsOfSection = attachmentsEvents
            .filter(item => item.__typename === fileSection.eventType)
            .flatMap(item => item.attachments || []);
        if (attachmentsOfSection.length > 0 && fileSection.requiredFiles > 0) {
            fileSection.requiredFiles = Math.max(
                0,
                fileSection.requiredFiles - attachmentsOfSection.length
            );
        }
    }

    return (
        <VStack gap="smallMedium">
            {fileSections.map(fileSection => {
                return (
                    <FileSection
                        key={fileSection.eventType}
                        config={fileSection}
                        submitted={submitted}
                        readonly={readonly}
                        events={[]}
                        id={id}
                        files={filesMap[fileSection.documentType] || []}
                        onFilesUpdate={files => {
                            onFileMapChange({
                                ...filesMap,
                                [fileSection.documentType]: files,
                            });
                        }}
                        uploadPermissionToken={uploadPermissionToken}
                        qrCode={qrCode}
                        detailView={detailView}
                        disabled={disabled}
                    />
                );
            })}
        </VStack>
    );
};

export default memo(FilesSectionForm);
