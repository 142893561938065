import { memo } from 'react';
import type { ReactNode } from 'react';
import { clsx } from 'clsx';
import { Text } from '@library';
import styles from './Select.module.css';

const shouldShowPlaceholder = (
    hasSelectedValue: boolean,
    hasPlaceholder: boolean,
    search?: string
) => {
    return (
        hasPlaceholder &&
        !hasSelectedValue &&
        (search ? search.length === 0 : true)
    );
};

const SelectPlaceholder = ({
    hasSelectedValue,
    search,
    title,
    noPadding,
    fullWidth,
    open,
    formStyle,
    placeholder,
}: {
    search?: string;
    hasSelectedValue: boolean;
    title?: string;
    noPadding?: boolean;
    fullWidth?: boolean;
    open?: boolean;
    formStyle?: boolean;
    placeholder?: ReactNode;
}) => {
    const showPlaceholder = shouldShowPlaceholder(
        hasSelectedValue,
        !!placeholder,
        search
    );
    return (
        <>
            {showPlaceholder && (
                <span
                    title={title}
                    className={clsx(styles.placeholder, {
                        [styles.padding]: !noPadding,
                        [styles.notFullWidth]: !fullWidth,
                        [styles.focused]: open,
                        [styles.formStyle]: formStyle,
                    })}
                >
                    <Text secondary>{placeholder}</Text>
                </span>
            )}
        </>
    );
};

export default memo(SelectPlaceholder);
