import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from '@library';
import CheckmarkIcon from '../icons/CheckmarkIcon';
import styles from './SuccessUpdateMessage.module.css';

const SuccessUpdateMessage = ({ messageId }: { messageId?: string }) => {
    return (
        <div className={styles.successMessage}>
            <CheckmarkIcon small />
            <div className={styles.successMessageText}>
                <Text tertiary regular lineHeightAsFontSize>
                    <FormattedMessage id={messageId ?? 'saved'} />
                </Text>
            </div>
        </div>
    );
};

export default memo(SuccessUpdateMessage);
