import { memo } from 'react';
import { HStack } from '@library';
import type { StackProps } from '@library';
import notEmpty from '../../../../core/util/notEmpty';
import TicketFilePreview from './TicketFilePreview';
import type { AttachmentDetails } from './types';

const FileList = ({
    gap = 'small',
    attachments,
    deleteFile,
    detailView,
}: {
    gap?: StackProps['gap'];
    attachments?: AttachmentDetails[];
    deleteFile?: (attachmentId: number) => Promise<unknown>;
    detailView?: boolean;
}) => {
    if (!attachments || attachments.length === 0) {
        return null;
    }
    return (
        <HStack wrap gap={gap} expanded>
            {attachments
                .map((item, idx) => {
                    return (
                        <TicketFilePreview
                            key={idx.toString()}
                            attachment={item}
                            attachmentId={idx}
                            deleteFile={deleteFile}
                            detailView={detailView}
                        />
                    );
                })
                .filter(notEmpty)}
        </HStack>
    );
};

export default memo(FileList);
