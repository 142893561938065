import { useMemo } from 'react';
import { gql } from '@apollo/client';
import type { QuerySupportTicketArgs, SupportTicket } from '@apiTypes';
import getGroupedTicketItems from '../util/getGroupedTicketItems';
import usePolledQuery from '../../core/hooks/apollo/usePolledQuery';
import transformUnionTypes from '../util/transformUnionTypes';
import { isTypeNameFilter } from '../types';
import { eventsFragment } from './commonFragments';

export const ticketQuery = gql`
    ${eventsFragment}

    query ticketDetail($id: Int!) {
        supportTicket(id: $id) {
            created
            creator {
                customerNumber
                username
            }
            customerReference
            description
            id
            informationRequested
            shipmentRequested
            status
            type
            updated
            productClassId
            customerContactName
            customerContactEmail
            customerContactPhone
            language
            uploadPermissionToken
            returnNoteUrl
            shippingLabelUrl
            fromAddress {
                name
                address
                address2
                country
                city
                postcode
            }
            items {
                ... on SupportTicketReturnItem {
                    upstreamRecId
                    orderDate
                    SupportTicketReturnItem_sku: sku
                    quantity
                    originalQuantity
                    reason
                    resolution
                    received
                    SupportTicketReturnItem_serialNumber: serialNumber
                    orderNumber
                    SupportTicketReturnItem_brandName: brandName
                    SupportTicketReturnItem_productName: productName {
                        de
                        en
                        fr
                        it
                    }
                    product {
                        id
                        name {
                            en
                            de
                            fr
                            it
                        }
                        media {
                            url
                            renditionUrl
                        }
                        manufacturer
                        parentId
                    }
                    confirmedQuantity
                }
                ... on SupportTicketWarrantyOrRepairItem {
                    upstreamRecId
                    quantity
                    orderDate
                    SupportTicketWarrantyOrRepairItem_sku: sku
                    resolution
                    received
                    SupportTicketWarrantyOrRepairItem_serialNumber: serialNumber
                    orderNumber
                    SupportTicketWarrantyOrRepairItem_brandName: brandName
                    SupportTicketWarrantyOrRepairItem_productName: productName {
                        de
                        en
                        fr
                        it
                    }
                    product {
                        id
                        name {
                            en
                            de
                            fr
                            it
                        }
                        media {
                            url
                            renditionUrl
                        }
                        manufacturer
                        parentId
                    }
                    confirmedQuantity
                }
                ... on SupportTicketManualItem {
                    orderDate
                    SupportTicketManualItem_sku: sku
                    resolution
                    received
                    SupportTicketManualItem_serialNumber: serialNumber
                    orderNumber
                    SupportTicketManualItem_brandName: brandName
                    SupportTicketManualItem_productName: productName
                    quantity
                }
            }
            ...EventsFragment
        }
    }
`;

export const getTicketRefetchQuery = (id: number) => {
    return {
        query: ticketQuery,
        variables: {
            id,
        },
    };
};

const useTicket = (id: number, pollInterval = 20_000) => {
    const { data, loading, error } = usePolledQuery<
        {
            supportTicket: SupportTicket;
        },
        QuerySupportTicketArgs
    >(ticketQuery, {
        variables: {
            id,
        },
        pollInterval,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const { ticket, ticketItems } = useMemo(() => {
        if (!data?.supportTicket) {
            return {
                ticket: undefined,
                ticketItems: [],
            };
        }
        const transformedTicketItems = transformUnionTypes(
            data.supportTicket.items
        );

        const resolutions = transformUnionTypes(
            data.supportTicket.events
                .filter(
                    isTypeNameFilter([
                        'SupportTicketItemRejectionEvent',
                        'SupportTicketItemResolutionEvent',
                    ])
                )
                .map(item => {
                    return {
                        ...item,
                        salesLines: transformUnionTypes(item.salesLines),
                    };
                })
        );

        return {
            ticket: {
                ...data.supportTicket,
                items: transformedTicketItems,
                events: transformUnionTypes(data.supportTicket.events),
            },
            ticketItems: getGroupedTicketItems(
                transformedTicketItems,
                resolutions
            ),
        };
    }, [data?.supportTicket]);

    return {
        ticket,
        ticketItems,
        error,
        loading,
    };
};

export default useTicket;
