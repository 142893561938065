import { gql } from '@apollo/client';

const deliveryDatesQuery = gql`
    query deliveryDatesQuery($store: ID!, $language: Language!, $cartId: Int!) {
        checkout(store: $store, language: $language, cartId: $cartId) {
            applicableDeliveryDates {
                start
                end
            }
        }
    }
`;

export default deliveryDatesQuery;
