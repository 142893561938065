import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import type { MutationDiscardTicketArgs } from '@apiTypes';
import { getTicketRefetchQuery } from './useTicket';

export const submitTicketQuery = gql`
    mutation submitTicket($id: Int!) {
        submitTicket(id: $id)
    }
`;

const useSubmitTicket = () => {
    const [mutation, { loading }] = useMutation<
        { submitTicket: boolean },
        MutationDiscardTicketArgs
    >(submitTicketQuery, {
        awaitRefetchQueries: true,
    });

    const submitTicket = useCallback(
        async (id: number) => {
            const { data } = await mutation({
                variables: { id },
                refetchQueries: [getTicketRefetchQuery(id)],
            });
            return !!data?.submitTicket;
        },
        [mutation]
    );
    return {
        submitTicket,
        loading,
    };
};

export default useSubmitTicket;
