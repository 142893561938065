import { clsx } from 'clsx';
import { memo, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text, CloseIcon } from '@library';
import Button from '../../../core/components/Button';
import Container from '../../../core/components/Container';
import withErrorBoundary from '../../../core/components/errors/withErrorBoundary';
import CheckmarkIcon from '../../../core/components/icons/CheckmarkIcon';
import ImpersonationMessage from '../../../core/components/ImpersonationMessage';
import Select from '../../../core/components/input/Select/Select';
import Switch from '../../../core/components/input/Switch';
import { AppContext } from '../../../core/context';
import useCustomNavigate from '../../../core/hooks/router/useCustomNavigate';
import useCurrentUser from '../../../core/hooks/useCurrentUser';
import useStoreAndLanguageParams from '../../../core/hooks/useStoreAndLanguageParams';
import useRealUserDuringImpersonation from '../../../core/queries/useRealUserDuringImpersonation';
import type { Language } from '../../../core/types/language';
import assert from '../../../core/util/assert';
import { useStopImpersonation } from '../../../user/queries/useLoginAsCustomerOrUser';
import PreorderModeContext from '../../PreorderModeContext';
import type { ActivePreorderSeason } from '../../queries/useActivePreorderSeasons';
import PreorderDiscountTier from './PreorderDiscountTier';
import styles from './PreorderModeHeader.module.scss';

const getLocalizedPreorderName = (
    language: Language,
    preorder: ActivePreorderSeason
) => {
    return preorder[
        `name${language.toUpperCase()}` as keyof ActivePreorderSeason
    ] as string;
};

const PreorderModeHeaderTitle = memo(
    ({
        onlyPreorderProducts,
        setOnlyPreorderProducts,
        switchAvailable,
    }: {
        onlyPreorderProducts: boolean;
        setOnlyPreorderProducts: (value: boolean) => void;
        switchAvailable: boolean;
    }) => {
        const { language } = useStoreAndLanguageParams();
        const {
            selectedPreorderSeason,
            activePreorderSeasons,
            updateSelectedPreorderSeason,
        } = useContext(PreorderModeContext);

        assert(selectedPreorderSeason, 'selectedPreorderSeason is required.');

        return (
            <div className={styles.titleBlock}>
                <div className={styles.titleRow}>
                    {activePreorderSeasons.length > 1 ? (
                        <Select
                            small
                            noPadding
                            noBackground
                            menuSize="max"
                            material={false}
                            options={activePreorderSeasons.map(item => ({
                                label: getLocalizedPreorderName(language, item),
                                value: item.id.toString(),
                            }))}
                            value={selectedPreorderSeason.id.toString()}
                            onChange={(id: string) =>
                                updateSelectedPreorderSeason(+id)
                            }
                        />
                    ) : (
                        <Text primary>
                            {getLocalizedPreorderName(
                                language,
                                selectedPreorderSeason
                            )}
                        </Text>
                    )}
                    <PreorderDiscountTier />
                </div>

                {switchAvailable && (
                    <div className={styles.switchBlock}>
                        <Switch
                            name="onlyPreorderProducts"
                            value={onlyPreorderProducts}
                            onChange={setOnlyPreorderProducts}
                            large
                            showOnOffLabels
                            data-test-class="onlyPreorderProductsSwitch"
                            onLabel={<CheckmarkIcon />}
                            offLabel={<div />}
                            primary
                        />
                        <Text secondary>
                            <FormattedMessage id="show only preorder products" />
                        </Text>
                    </div>
                )}
            </div>
        );
    }
);

const PreorderModeHeader = ({
    onlyPreorderProducts,
    setOnlyPreorderProducts,
}: {
    onlyPreorderProducts: boolean;
    setOnlyPreorderProducts: (value: boolean) => void;
}) => {
    const navigate = useCustomNavigate();
    const currentUser = useCurrentUser();
    const { realUser } = useRealUserDuringImpersonation();
    const { isImpersonating } = useContext(AppContext);
    const { selectedPreorderSeason } = useContext(PreorderModeContext);

    const { stopImpersonation, loading } = useStopImpersonation();

    assert(selectedPreorderSeason, 'selectedPreorderSeason is required.');
    const switchAvailable = !selectedPreorderSeason.disallowNonPreorderProducts;

    return (
        <div
            className={clsx(styles.wrapper, {
                [styles.impersonated]: isImpersonating,
            })}
            data-test-class="preorderModeHeader"
        >
            <Container>
                <div
                    className={clsx(styles.layout, {
                        [styles.switchAvailable]: switchAvailable,
                    })}
                >
                    <PreorderModeHeaderTitle
                        onlyPreorderProducts={onlyPreorderProducts}
                        setOnlyPreorderProducts={setOnlyPreorderProducts}
                        switchAvailable={switchAvailable}
                    />

                    <div className={styles.seasonLogo}>
                        {selectedPreorderSeason.logoUrl && (
                            <img
                                className={styles.seasonLogoImage}
                                src={selectedPreorderSeason.logoUrl}
                                alt="Logo for this season"
                            />
                        )}
                    </div>

                    <div className={styles.controlBlock}>
                        <div className={styles.textBlock}>
                            <Text secondary>
                                {isImpersonating ? (
                                    <ImpersonationMessage
                                        isPreorder
                                        impersonator={realUser?.username}
                                        impersonatedUser={currentUser?.username}
                                    />
                                ) : (
                                    <FormattedMessage id="You are in pre-order mode" />
                                )}
                            </Text>
                        </div>

                        <div className={styles.buttonsBlock}>
                            {isImpersonating && (
                                <Button
                                    size="small"
                                    outlineLight
                                    rounded
                                    disabled={loading}
                                    onPress={stopImpersonation}
                                >
                                    <div className={styles.buttonText}>
                                        <Text
                                            tertiary
                                            uppercase
                                            lineHeightAsFontSize
                                        >
                                            <FormattedMessage id="sign out" />
                                        </Text>
                                    </div>
                                </Button>
                            )}
                            <Button
                                size="small"
                                outlineLight
                                rounded
                                onPress={() => {
                                    navigate('../');
                                }}
                            >
                                <div className={styles.buttonText}>
                                    <Text
                                        tertiary
                                        uppercase
                                        lineHeightAsFontSize
                                    >
                                        <FormattedMessage id="leave" />
                                    </Text>
                                    <CloseIcon small />
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default memo(withErrorBoundary(PreorderModeHeader));
