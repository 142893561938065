import { clsx } from 'clsx';
import { memo, useCallback } from 'react';
import { FloatingFocusManager } from '@floating-ui/react';
import { Checkbox } from '../Checkbox';
import { TextBlock } from '../../typography';
import useSelectInput from './hooks/useSelectInput';
import SelectTrigger from './SelectTrigger';
import SelectDropdown from './SelectDropdown';
import type { MultiSelectProps } from './types';
import useFloatingSelect from './hooks/useFloatingSelect';
import styles from './Select.module.scss';
import MultiSelectTriggerContent from './MultiSelectTriggerContent';

const MultiSelect = ({
    id,
    value,
    header: HeaderElement,
    onChange,
    options,
    searchable = false,
    placeholder,
    selectedCountBadge = false,
    noSelectedLabels = false,
    deselectableLabels,
    allOptionsSelectedText,
    disabled = false,
    success = false,
    error = false,
    rounded = false,
    small = false,
    filled = false,
    theme,
    autoWidth,
    ...ariaProps
}: MultiSelectProps) => {
    const { showDropdown, searchValue, setSearchValue, filteredOptions } =
        useSelectInput(options);

    const handleChange = useCallback(
        (newValue: string) => {
            const currentValues = new Set(value);

            if (currentValues.has(newValue)) {
                currentValues.delete(newValue);
            } else {
                currentValues.add(newValue);
            }

            onChange(Array.from(currentValues));
        },
        [onChange, value]
    );

    const {
        refs: { setReference: selectTriggerReference, setFloating },
        getReferenceProps,
        context,
        strategy,
        y,
        x,
        getFloatingProps,
        setPointer,
        activeIndex,
        handleSelect,
        setOpen,
        listElementsRef,
        getItemProps,
        setActiveIndex,
        styles: animationStyles,
        isMounted,
    } = useFloatingSelect({
        options: filteredOptions,
        onChange: handleChange,
        preventCloseOnSelect: true,
    });

    const selectedOptions = options.filter(item => value.includes(item.value));

    return (
        <div
            className={clsx(styles.select, theme, {
                [styles.autoWidth]: autoWidth,
            })}
            {...ariaProps}
        >
            <SelectTrigger
                ref={selectTriggerReference}
                isOpen={showDropdown}
                value={value.join(', ')}
                small={small}
                deselectableLabels={deselectableLabels}
                disabled={disabled}
                success={
                    success || (noSelectedLabels && selectedOptions.length > 0)
                }
                error={error}
                getReferenceProps={getReferenceProps}
                rounded={rounded}
                filled={selectedOptions.length > 0 && filled}
            >
                <MultiSelectTriggerContent
                    allOptionsSelectedText={allOptionsSelectedText}
                    deselectableLabels={deselectableLabels}
                    handleChange={handleChange}
                    optionsCount={options.length}
                    placeholder={placeholder}
                    selectedOptions={selectedOptions}
                    selectedCountBadge={selectedCountBadge}
                    noSelectedLabels={noSelectedLabels}
                    filled={selectedOptions.length > 0 && filled}
                />
            </SelectTrigger>
            {isMounted && (
                <FloatingFocusManager
                    modal={false}
                    returnFocus={false}
                    context={context}
                >
                    <div
                        className={styles.dropdownFloatingMenu}
                        ref={setFloating}
                        tabIndex={-1}
                        style={{
                            position: strategy,
                            top: y,
                            left: x,
                            overflow: 'auto',
                            ...animationStyles,
                        }}
                    >
                        <SelectDropdown
                            ref={listElementsRef}
                            searchable={searchable}
                            searchText={searchValue}
                            setSearchText={setSearchValue}
                            optionsCount={filteredOptions.length}
                            header={
                                HeaderElement ? (
                                    <HeaderElement options={filteredOptions} />
                                ) : undefined
                            }
                            context={context}
                            getFloatingProps={getFloatingProps}
                            setPointer={setPointer}
                            activeIndex={activeIndex}
                            handleSelect={handleSelect}
                            setOpen={setOpen}
                            setActiveIndex={setActiveIndex}
                        >
                            {filteredOptions.map((item, index) => (
                                <div
                                    key={item.value}
                                    className={styles.optionItem}
                                    role="option"
                                    tabIndex={-1}
                                    ref={node => {
                                        listElementsRef.current[index + 1] =
                                            node;
                                    }}
                                    {...getItemProps({
                                        onClick: () => handleSelect(),
                                        onKeyDown: e => {
                                            if (
                                                [' ', 'Enter'].includes(e.key)
                                            ) {
                                                handleSelect();
                                            }
                                        },
                                    })}
                                    aria-selected={value.includes(item.value)}
                                >
                                    <div
                                        tabIndex={0}
                                        className={clsx(
                                            styles.dropdownMenuItem,
                                            styles.contentHorizontalPadding,
                                            {
                                                [styles.active]:
                                                    activeIndex === index + 1,
                                                [styles.selected]:
                                                    value.includes(item.value),
                                            }
                                        )}
                                    >
                                        <Checkbox
                                            id={`${id}-select-option-${item.value}`}
                                            checked={value.includes(item.value)}
                                            onChange={() =>
                                                handleChange(item.value)
                                            }
                                        >
                                            <TextBlock primary>
                                                {item.label}
                                            </TextBlock>
                                        </Checkbox>
                                    </div>
                                </div>
                            ))}
                        </SelectDropdown>
                    </div>
                </FloatingFocusManager>
            )}
        </div>
    );
};

export default memo(MultiSelect);
