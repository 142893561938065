const getTicketItemKey = ({
    sku,
    upstreamRecId,
}: {
    sku?: string;
    upstreamRecId?: string;
}) => {
    return `${sku}:${upstreamRecId}`;
};

export default getTicketItemKey;
