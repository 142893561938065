import { memo } from 'react';
import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import type { SupportTicketEvent, TicketSchemaResult } from '@apiTypes';
import { SupportTicketEventDocumentTypes, SupportTicketType } from '@apiTypes';
import type { FileTypes } from '@library';
import { VStack, notify } from '@library';
import type { ProductClass } from '../../../queries/useProductClasses';
import useTicketEvents from '../../../queries/useTicketEvents';
import notEmpty from '../../../../core/util/notEmpty';
import { isTypeNameFilter } from '../../../types';
import useTicketSchema from '../../../queries/useTicketSchema';
import useUploadTicketDocument from '../../../queries/useUploadTicketDocument';
import useDeleteTicketEventAttachment from '../../../queries/useDeleteTicketEventAttachments';
import {
    attachmentsEventTypeNames,
    type AttachmentsEvent,
    type FileSectionConfig,
} from './types';
import FileSection from './FileSection';

export interface FilesSectionProps {
    events: SupportTicketEvent[];
    currentProductClass?: ProductClass;
    submitted?: boolean;
    readonly?: boolean;
    qrCode?: boolean;
    detailView?: boolean;
    id: number;
    type: SupportTicketType;
    uploadPermissionToken?: string;
    refetchQueries?: InternalRefetchQueriesInclude;
}

const getName = (type: SupportTicketType) => {
    if (
        type === SupportTicketType.Repair ||
        type === SupportTicketType.Warranty
    ) {
        return 'Tickets Files OthersForWarrantyRepair';
    }
    return undefined;
};

const commonAccept: FileTypes[] = ['image', 'pdf', 'word', 'excel', 'text'];

export const getFileSections = ({
    type,
    readonly,
    currentProductClass,
    ticketSchema,
}: {
    type: SupportTicketType;
    readonly?: boolean;
    currentProductClass?: ProductClass;
    ticketSchema: TicketSchemaResult | undefined;
}) => {
    if (!ticketSchema) {
        return [];
    }

    const fileSections: Partial<FileSectionConfig>[] = [
        {
            label: getName(type),
            eventType: 'SupportTicketOtherDocumentEvent',
            documentType: SupportTicketEventDocumentTypes.OtherDocuments,
            additionalEventTypes: readonly ? ['SupportMessageEvent'] : [],
            accept: [...commonAccept, 'video'] as FileTypes[],
            maxFileSize: 262144000,
        },
        {
            label: 'Tickets Files PictureOfFault',
            eventType: 'SupportTicketImageOfFaultDocumentEvent',
            documentType: SupportTicketEventDocumentTypes.ImageOfFault,
            additionalEventTypes: [],
            accept: ['image', 'video'],
            maxFileSize: 262144000,
        },
        {
            label: 'Tickets Files SerialNumber',
            eventType: 'SupportTicketImageOfSerialNumberDocumentEvent',
            documentType: SupportTicketEventDocumentTypes.ImageOfSerialNumber,
            additionalEventTypes: [],
            accept: ['image', 'video'],
            maxFileSize: 262144000,
        },
        {
            label: 'Tickets Files ImageOfProduct',
            eventType: 'SupportTicketImageOfProductDocumentEvent',
            documentType: SupportTicketEventDocumentTypes.ImageOfProduct,
            additionalEventTypes: [],
            accept: ['image', 'video'],
            maxFileSize: 262144000,
        },
        {
            label: 'Tickets Files CertificateOfGuarantee',
            eventType: 'SupportTicketCertificateOfGuaranteeDocumentEvent',
            documentType:
                SupportTicketEventDocumentTypes.CertificateOfGuarantee,
            additionalEventTypes: [],
            accept: commonAccept,
            maxFileSize: 262144000,
        },
    ];

    const currentTicketSchema = ticketSchema.ticketTypes.find(
        item => item.ticketType === type
    );
    if (!currentTicketSchema) {
        return [];
    }
    return currentTicketSchema.availableDocuments
        .filter(document => {
            return document.productClassId && currentProductClass
                ? document.productClassId === currentProductClass.productClassId
                : true;
        })
        .map(availableDocument => {
            const sectionDetails = fileSections.find(
                section =>
                    section.documentType === availableDocument.documentType
            );
            if (!sectionDetails) {
                return null;
            }
            return {
                ...sectionDetails,
                requiredFiles: availableDocument.requiredFiles,
            } as FileSectionConfig;
        })
        .filter(notEmpty);
};

const FilesSection = ({
    currentProductClass,
    events,
    id,
    type,
    submitted,
    readonly,
    qrCode,
    detailView,
    uploadPermissionToken,
    refetchQueries,
}: FilesSectionProps) => {
    const { uploadDocuments } = useUploadTicketDocument(refetchQueries);
    const { deleteTicketEventAttachment } =
        useDeleteTicketEventAttachment(refetchQueries);
    const isAnyFileInProcess = events
        .map(item => {
            if (
                attachmentsEventTypeNames.includes(
                    item.__typename as AttachmentsEvent
                )
            ) {
                const attachmentEvent = item as Extract<
                    SupportTicketEvent,
                    {
                        __typename: AttachmentsEvent;
                    }
                >;

                return !!attachmentEvent.attachments
                    ?.filter(
                        isTypeNameFilter(['SupportTicketEventAttachmentItem'])
                    )
                    .some(attachment => attachment.processing);
            }
            return false;
        })
        .some(loading => loading);

    useTicketEvents(id, 5000, !isAnyFileInProcess);

    const { ticketSchema } = useTicketSchema();

    const fileSections = getFileSections({
        type,
        currentProductClass,
        readonly,
        ticketSchema,
    });

    return (
        <VStack gap="small">
            {fileSections.map(fileSection => {
                return (
                    <FileSection
                        key={fileSection.eventType}
                        config={fileSection}
                        submitted={submitted}
                        readonly={readonly}
                        events={events}
                        id={id}
                        uploadPermissionToken={uploadPermissionToken}
                        qrCode={qrCode}
                        detailView={detailView}
                        uploadDocuments={async value => {
                            return uploadDocuments(value).catch(() => {
                                notify({
                                    type: 'error',
                                    title: (
                                        <FormattedMessage id="Failed to upload" />
                                    ),
                                    message: (
                                        <FormattedMessage id="an error occurred. please try again later" />
                                    ),

                                    id: 'FAILED_TO_UPLOAD',
                                });
                            });
                        }}
                        deleteTicketEventAttachment={
                            deleteTicketEventAttachment
                        }
                    />
                );
            })}
        </VStack>
    );
};

export default memo(FilesSection);
