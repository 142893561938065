import type { InMemoryCacheConfig, FieldPolicy } from '@apollo/client';
import currentUserFieldPolicy from './util/currentUserFieldPolicy';
import mergePaginatedResults from './util/mergePaginatedResults';

const stockQuantityPolicy = {
    keyFields: ['store', 'sku', 'location'],
};

const getStockQuantitiesCachePolicy = (typename: string) => {
    return {
        keyArgs: ['store', 'skuList', 'location'],
        read: (existing, { toReference, args, readField }) => {
            const { store, skuList, location } = args as {
                store: string;
                skuList: string[];
                location: string;
            };

            const references = skuList.map(sku => {
                return toReference({
                    __typename: typename,
                    store,
                    sku,
                    location,
                });
            });

            const allSkusInCache = references.every(id => {
                return !!readField('sku', id);
            });

            return allSkusInCache ? references : existing;
        },
    } as FieldPolicy;
};

const getStockQuantityCachePolicy = (typename: string) => {
    return {
        keyArgs: ['store', 'sku', 'location'],
        read: (_, { toReference, args }) => {
            const { store, sku, location } = args as {
                store: string;
                sku: string;
                location: string;
            };

            return toReference({
                __typename: typename,
                store,
                sku,
                location,
            });
        },
    } as FieldPolicy;
};

const clientCacheConfiguration: InMemoryCacheConfig = {
    typePolicies: {
        Category: {
            keyFields: ['key'],
        },
        Dealer: {
            keyFields: ['id'],
        },
        Product: {
            keyFields: ['key'],
        },
        ProductVariant: {
            keyFields: ['key'],
        },
        Store: {
            keyFields: false,
        },
        Cart: {
            merge: true,
        },
        UserProductPrice: {
            keyFields: price => {
                return [
                    'store',
                    'sku',
                    'sku',
                    'cacheKey',
                    'cartType',
                    'cartId',
                ].filter(key => price[key] !== undefined);
            },
            merge: true,
        },
        PreorderCart: {
            merge: true,
        },
        PreorderSeason: {
            keyFields: ['id'],
            merge: true,
        },
        IntercyclePreorderPrice: {
            keyFields: ['sku', 'discountTier'],
        },
        ProductAvailability: {
            merge: true,
        },
        StockQuantity: stockQuantityPolicy,
        OptionalStockQuantity: stockQuantityPolicy,
        FutureStockQuantityResult: stockQuantityPolicy,
        ProductOrderRestriction: {
            keyFields: ['store', 'sku'],
        },
        PaymentTerm: {
            keyFields: ['id', 'name'],
        },
        CurrentUser: {
            keyFields: ['username'],
        },
        SupportTicket: {
            keyFields: ['id'],
        },
        MetaProduct: {
            keyFields: ['id', 'category'],
        },
        CartFields: {
            keyFields: ['id'],
        },
        Order: {
            keyFields: ['id'],
        },
        OrderItem: {
            keyFields: ['id'],
        },
        // Optimization of apollo caching.
        // https://www.apollographql.com/docs/react/caching/advanced-topics
        // /#cache-redirects-using-field-policy-read-functions
        Query: {
            fields: {
                products: {
                    merge: true,
                },
                cart: {
                    merge: true,
                },
                orderItems: {
                    merge: true,
                },
                category: {
                    read: (_, { toReference, args }) => {
                        const { id, language } = args as {
                            id: string;
                            language: string;
                        };

                        return toReference({
                            __typename: 'Category',
                            key: language + id,
                        });
                    },
                },
                customerPrice: {
                    keyArgs: ['store', 'sku', 'cacheKey', 'cartType', 'cartId'],
                    read: (_, { toReference, args }) => {
                        const { store, sku, cacheKey, cartType, cartId } =
                            args as {
                                store: string;
                                sku: string;
                                cacheKey?: string;
                                cartId?: string;
                                cartType: string;
                            };

                        return toReference({
                            __typename: 'UserProductPrice',
                            store,
                            sku,
                            cacheKey,
                            cartType,
                            cartId,
                        });
                    },
                },
                customerPrices: {
                    keyArgs: [
                        'store',
                        'cacheKey',
                        'skuList',
                        'cartId',
                        'cartType',
                    ],
                    read: (existing, { toReference, args, readField }) => {
                        const { store, skuList, cacheKey, cartType, cartId } =
                            args as {
                                store: string;
                                skuList: string[];
                                cacheKey?: string;
                                cartId?: string;
                                cartType: string;
                            };

                        const references = skuList.map(sku => {
                            return toReference({
                                __typename: 'UserProductPrice',
                                store,
                                sku,
                                cacheKey: cacheKey,
                                cartType,
                                cartId,
                            });
                        });
                        const allPricesInCache = references.every(id => {
                            return !!readField('sku', id);
                        });
                        return allPricesInCache ? references : existing;
                    },
                },
                currentUser: currentUserFieldPolicy,
                singleStockQuantity: getStockQuantityCachePolicy(
                    'OptionalStockQuantity'
                ),
                stockQuantities: getStockQuantitiesCachePolicy(
                    'OptionalStockQuantity'
                ),
                productFutureStock: getStockQuantityCachePolicy(
                    'FutureStockQuantityResult'
                ),
                productListFutureStock: getStockQuantitiesCachePolicy(
                    'FutureStockQuantityResult'
                ),
                productOrderRestriction: getStockQuantityCachePolicy(
                    'ProductOrderRestriction'
                ),
                productListOrderRestriction: getStockQuantitiesCachePolicy(
                    'ProductOrderRestriction'
                ),
                notifications: {
                    keyArgs: ['filters', 'since', 'types', 'unreadOnly'],
                    merge: mergePaginatedResults,
                },
            },
        },
    },
};

export default clientCacheConfiguration;
