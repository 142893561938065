import { memo } from 'react';
import type { IconProps } from './types';
import BaseIcon from './BaseIcon';

const DownloadIcon = memo((props: IconProps) => {
    return (
        <BaseIcon viewBox="0 0 24 24" {...props} fill="none">
            <path
                d="M3 16.5V18.75C3 19.3467 3.23705 19.919 3.65901 20.341C4.08097 20.7629 4.65326 21 5.25 21H18.75C19.3467 21 19.919 20.7629 20.341 20.341C20.7629 19.919 21 19.3467 21 18.75V16.5M16.5 12L12 16.5M12 16.5L7.5 12M12 16.5V3"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </BaseIcon>
    );
});

export default memo(DownloadIcon);
