import {
    FloatingFocusManager,
    FloatingOverlay,
    FloatingPortal,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole,
    useTransitionStatus,
} from '@floating-ui/react';
import type { ReactNode } from 'react';
import { memo } from 'react';
import { clsx } from 'clsx';
import { CloseIcon } from '@library';
import { Button } from '../buttons';
import { HStack } from '../layout';
import { Heading } from '../typography';
import styles from './Modal.module.scss';
import useNoScrollingWindow from './hooks/useNoScrollingWindow';

export interface ModalProp {
    /**
     * Control show state outside the component
     */
    open?: boolean;
    /**
     * Open state changed
     */
    onOpenChange?: (open: boolean) => void;
    /**
     * Disable Dismiss modal by outside click and esc button
     */
    disableDismiss?: boolean;
    children: ReactNode;
    /**
     * The header of modal
     */
    header?: ReactNode;
    /**
     * The justify of modal
     */
    horizontalPlacement?: 'start' | 'center' | 'end';
    /**
     * The align of modal
     */
    verticalPlacement?: 'start' | 'center' | 'end';
    /**
     * The theme
     */
    theme?: string;
    /**
     * Hide Close Icon
     */
    hiddenCloseButton?: boolean;
}

const Modal = ({
    children,
    open,
    onOpenChange,
    disableDismiss,
    header,
    horizontalPlacement,
    verticalPlacement,
    theme,
    hiddenCloseButton,
}: ModalProp) => {
    const {
        context,
        refs: { setFloating },
    } = useFloating({
        open,
        onOpenChange: onOpenChange,
    });

    const click = useClick(context);
    const dismiss = useDismiss(context, { enabled: !disableDismiss });
    const role = useRole(context, { role: 'dialog' });

    const { getFloatingProps } = useInteractions([click, dismiss, role]);
    const { isMounted, status } = useTransitionStatus(context, {
        duration: 200,
    });

    useNoScrollingWindow(isMounted);

    if (!isMounted) {
        return null;
    }

    return (
        <FloatingPortal>
            (
            <FloatingOverlay
                className={clsx(styles.root, {
                    [styles[`${horizontalPlacement}HorizontalPlacement`]]:
                        horizontalPlacement,
                    [styles[`${verticalPlacement}VerticalPlacement`]]:
                        verticalPlacement,
                })}
                data-status={status}
            >
                <FloatingFocusManager context={context} initialFocus={-1}>
                    <HStack align="center" justify="center">
                        <div
                            {...getFloatingProps()}
                            ref={setFloating}
                            className={clsx(
                                styles.modal,
                                {
                                    [styles.withCustomHeader]: header,
                                },
                                theme
                            )}
                        >
                            <div className={styles.header}>
                                {header && (
                                    <div>
                                        <Heading h3>{header}</Heading>
                                    </div>
                                )}
                                {!hiddenCloseButton && (
                                    <div className={styles.close}>
                                        <Button
                                            secondary
                                            text
                                            inline
                                            onClick={() => {
                                                onOpenChange?.(false);
                                            }}
                                            data-test-class="close"
                                        >
                                            <CloseIcon bold />
                                        </Button>
                                    </div>
                                )}
                            </div>

                            {children}
                        </div>
                    </HStack>
                </FloatingFocusManager>
            </FloatingOverlay>
            )
        </FloatingPortal>
    );
};
export default memo(Modal);
