import { memo } from 'react';

import LazyComponent from '../core/components/router/LazyComponent';
import useCustomNavigate from '../core/hooks/router/useCustomNavigate';
import useStoreAndLanguageParams from '../core/hooks/useStoreAndLanguageParams';

const ResetPassword = LazyComponent(
    async () => import('./components/ResetPassword')
);
const PleaseLoginPage = LazyComponent(
    async () => import('../core/components/pages/PleaseLoginPage')
);
const UserModals = LazyComponent(
    async () => import('./components/UserMenuModals')
);

const LoginPage = memo(() => {
    const { storeRootPath } = useStoreAndLanguageParams();
    const navigate = useCustomNavigate();

    return (
        <>
            <UserModals
                onClose={() => {
                    navigate(storeRootPath);
                }}
            />
            <PleaseLoginPage />
        </>
    );
});

const authRoutes = [
    { path: 'password-reset', element: <ResetPassword /> },
    { path: 'access-denied', element: <PleaseLoginPage /> },
    {
        path: 'login',
        element: <LoginPage />,
    },
];

export default authRoutes;
