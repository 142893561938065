import { memo } from 'react';
import type { IconProps } from './types';
import BaseIcon from './BaseIcon';

const RefreshIcon = memo((props: IconProps) => {
    return (
        <BaseIcon viewBox="0 0 18 17" {...props} fill="none">
            <>
                <path
                    d="M12.5703 6.0895H17L14.1773 3.19636C13.2685 2.26549 12.1366 1.59608 10.8951 1.25542C9.65373 0.914762 8.34664 0.914861 7.10528 1.25571C5.86392 1.59656 4.73203 2.26614 3.8234 3.19715C2.91478 4.12815 2.26144 5.28777 1.92907 6.55942M1.00089 15.4479V10.9105M1.00089 10.9105H5.43059M1.00089 10.9105L3.82269 13.8036C4.73145 14.7345 5.86344 15.4039 7.10485 15.7446C8.34627 16.0852 9.65336 16.0851 10.8947 15.7443C12.1361 15.4034 13.268 14.7339 14.1766 13.8029C15.0852 12.8718 15.7386 11.7122 16.0709 10.4406M17 1.55209V6.08768"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </>
        </BaseIcon>
    );
});

export default memo(RefreshIcon);
