import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { notify } from '@library';
import useCartField from '../../checkout/queries/useCartField';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import captureException from '../../core/util/captureException';
import stripTypenameField from '../../core/util/stripTypenameField';
import type { DeliveryDate } from '../contexts/DeliveryDates/DeliveryDatesContext';
import useCurrentCartId from '../hooks/useCurrentCartId';
import usePolledQuery from '../../core/hooks/apollo/usePolledQuery';
import deliveryDatesQuery from './deliveryDatesQuery';

const useDeliveryDatesQuery = () => {
    const { store, language } = useStoreAndLanguageParams();
    const cartId = useCurrentCartId();

    const [
        defaultDeliveryDate,
        saveDefaultDeliveryDate,
        defaultDeliveryDateUpdating,
    ] = useCartField('defaultDeliveryDate');

    const [deliveryDatesFromServer, saveDeliveryDates, deliveryDatesUpdating] =
        useCartField<DeliveryDate[]>('deliveryDates');

    const deliveryDates = useMemo(() => {
        return deliveryDatesFromServer?.map(item => {
            return stripTypenameField({ ...item });
        });
    }, [deliveryDatesFromServer]);

    const {
        data: { checkout } = {
            checkout: {},
        },
        loading,
        error,
    } = usePolledQuery<{
        checkout: {
            applicableDeliveryDates?: { start: string; end: string }[];
        };
    }>(
        deliveryDatesQuery,
        {
            variables: {
                store,
                language,
                cartId,
            },
            pollInterval: 180_000,
            skip: !cartId,
        },
        true
    );

    const applicableDeliveryDates = useMemo(() => {
        const applicableDates: string[] = [];
        const dateRanges = checkout.applicableDeliveryDates || [];

        for (const { start, end } of dateRanges) {
            let currentDate = DateTime.fromISO(start);
            const endingDate = DateTime.fromISO(end);

            while (currentDate <= endingDate) {
                applicableDates.push(currentDate.toISODate());
                currentDate = currentDate.plus({ day: 1 });
            }
        }

        return applicableDates;
    }, [checkout.applicableDeliveryDates]);

    useEffect(() => {
        if (error) {
            notify({
                type: 'error',
                title: <FormattedMessage id="Failed to update cart settings" />,
                message: (
                    <FormattedMessage id="Your requested delivery dates could not be retrieved from the server. The displayed values might be out of date. Please verify the delivery dates before proceeding." />
                ),

                id: 'FAILED_TO_FETCH_DELIVERY_DATES',
            });

            captureException(error);
        }
    }, [error]);

    return {
        loading,
        defaultDeliveryDate,
        deliveryDates,
        saveDefaultDeliveryDate,
        saveDeliveryDates,
        applicableDeliveryDates,
        defaultDeliveryDateUpdating,
        deliveryDatesUpdating,
    };
};

export default useDeliveryDatesQuery;
