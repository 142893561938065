import type {
    ApolloQueryResult,
    ServerError,
    ServerParseError,
} from '@apollo/client';
import {
    ApolloClient,
    ApolloLink,
    createHttpLink,
    InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import type { GraphQLError, OperationDefinitionNode } from 'graphql';
import { print } from 'graphql';
import requestsLog from '../core/services/RequestsLog';
import startRefreshTokenCheck from '../core/util/auth/refreshTokenCheck';
import clientCacheConfiguration from './clientCacheConfiguration';
import initStoresAndCategoriesData from './initStoresAndCategoriesData';

const httpOptions = {
    uri: APP_ENV.API_URL || 'https://api.dev.hostettler.com',
    credentials: 'same-origin',
};

const requestLink = createHttpLink(httpOptions);

const queriesMap = new Map();

let maxQueryId = 0;

const logLink = new ApolloLink((operation, forward) => {
    if (APP_ENV.DISABLE_APOLLO_LOG) {
        return forward(operation);
    }
    const queryName = `${operation.operationName}(${JSON.stringify(
        operation.variables
    )})`;

    queriesMap.set(queryName, Date.now());

    const queryId = ++maxQueryId;

    if (APP_ENV.VERBOSE_GRAPHQL_LOGGING) {
        // eslint-disable-next-line no-console
        console.debug(
            `🔶${queryId}`,
            print(operation.query),
            JSON.stringify(operation.variables)
        );
    } else {
        // eslint-disable-next-line no-console
        console.debug(`🔶${queryName}[${queryId}]`);
    }

    const warningTimeout = setTimeout(() => {
        // eslint-disable-next-line no-console
        console.error(
            `♦️${queryId}`,
            print(operation.query),
            JSON.stringify(operation.variables),
            'REQUEST TAKING TOO LONG'
        );
    }, 50_000);

    return forward(operation).map(result => {
        clearTimeout(warningTimeout);
        const finishedTime = Date.now();
        const startTime = queriesMap.get(queryName);
        queriesMap.delete(queryName);

        const duration = (finishedTime - startTime) / 1000;
        const durationMessage =
            duration > 1 ? `${duration}s ❗️` : `${duration}s`;
        // eslint-disable-next-line no-console
        console.debug(
            `🔹${queryId} ${queryName}`,
            durationMessage,
            result.data
        );

        if (result.errors) {
            // eslint-disable-next-line no-console
            console.error(result.errors);
        }

        if (result.errors) {
            requestsLog.logRequestWithError({
                queryName,
                timeTaken: `${finishedTime - startTime}ms`,
                data: result.data,
                errors: result.errors as GraphQLError[],
                timestamp: finishedTime,
            });
        } else {
            requestsLog.logSuccessfulRequest({
                queryName,
                timeTaken: `${finishedTime - startTime}ms`,
                data: result.data,
                errors: result.errors,
                timestamp: finishedTime,
            });
        }

        return result;
    });
});

const isServerError = (
    networkError: Error | ServerError | ServerParseError
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): networkError is ServerError => !!(networkError as any).statusCode;

const onErrorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) =>
                // eslint-disable-next-line no-console
                console.error(
                    `❌ [GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            );
        }

        if (networkError && isServerError(networkError)) {
            // eslint-disable-next-line no-console
            console.error(`[VERBOSE] ${networkError.statusCode}`);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            (
                networkError.result as ApolloQueryResult<unknown> | undefined
            )?.errors?.forEach((error: { message: string }) => {
                // eslint-disable-next-line no-console
                console.error(`[VERBOSE] ${error.message}`, error);
            });
        }

        return forward(operation);
    }
);

const authLink = (accessToken?: string, permissionToken?: string) =>
    setContext(() => {
        const headers: Record<string, string | undefined> = {};

        if (permissionToken) {
            headers['X-Permission-Token'] = permissionToken;
            return {
                headers: {
                    ...headers,
                },
            };
        }

        if (accessToken) {
            headers['X-Access-Token'] = accessToken;
        }

        return {
            headers: {
                ...headers,
            },
        };
    });

const headersLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            'X-GQL-Client': `Webshop ${APP_ENV.VERSION}`,
            'X-GQL-Application': APP_ENV.APPLICATION,
            'X-GQL-Path-Origin': window.location.pathname,
        },
    }));

    return forward(operation);
});

export const createClient = (
    setAccessToken: (token?: string, persisted?: boolean) => void,
    accessToken?: string,
    permissionToken?: string
) => {
    const client = new ApolloClient({
        link: ApolloLink.from([
            new RetryLink({
                delay: {
                    initial: 15_000,
                    max: 120_000,
                    jitter: true,
                },
                attempts: {
                    max: Infinity,
                    retryIf(error, operation) {
                        if (!navigator.onLine) {
                            return !operation.query.definitions.some(
                                definition =>
                                    (definition as OperationDefinitionNode)
                                        .operation === 'mutation'
                            );
                        }

                        return !(
                            error.statusCode >= 400 && error.statusCode < 500
                        );
                    },
                },
            }),
            authLink(accessToken, permissionToken),
            headersLink,
            onErrorLink,
            logLink,
            requestLink,
        ]),
        cache: new InMemoryCache(clientCacheConfiguration),
        connectToDevTools: true,
        defaultOptions: {
            query: {
                fetchPolicy: 'network-only',
            },
            mutate: {
                awaitRefetchQueries: true,
            },
        },
    });
    const refreshInterval = startRefreshTokenCheck(client, setAccessToken);

    initStoresAndCategoriesData(client);

    return { client, refreshInterval };
};
