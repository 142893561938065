/* eslint-disable no-param-reassign */
import type { RefObject } from 'react';
import { useLayoutEffect, useState } from 'react';

const useOverflowingElement = (
    ref: RefObject<HTMLElement | null>,
    containerRef?: RefObject<HTMLElement | null>
) => {
    const [isOverflowing, setOverflowing] = useState(false);

    useLayoutEffect(() => {
        const handler = () => {
            if (!ref.current) {
                return setOverflowing(false);
            }

            const { left: containerLeft, width: containerWidth } =
                containerRef?.current?.getBoundingClientRect() || {
                    left: 0,
                    width: window.outerWidth,
                };

            const { left: elementLeft, width: elementWidth } =
                ref.current.getBoundingClientRect();

            return setOverflowing(
                elementLeft + elementWidth > containerLeft + containerWidth
            );
        };

        handler();

        window.addEventListener('resize', handler);
        return () => {
            window.removeEventListener('resize', handler);
        };
    }, [ref, containerRef]);

    return isOverflowing;
};

export default useOverflowingElement;
