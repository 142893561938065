import { memo } from 'react';
import type { IconProps } from './types';
import BaseIcon from './BaseIcon';

const MessageIcon = memo((props: IconProps) => {
    return (
        <BaseIcon viewBox="0 0 18 17" {...props} fill="none">
            <>
                <path
                    d="M6 8.17384C6 8.26032 5.96488 8.34327 5.90237 8.40442C5.83986 8.46557 5.75507 8.49993 5.66667 8.49993C5.57826 8.49993 5.49348 8.46557 5.43096 8.40442C5.36845 8.34327 5.33333 8.26032 5.33333 8.17384C5.33333 8.08736 5.36845 8.00442 5.43096 7.94327C5.49348 7.88211 5.57826 7.84776 5.66667 7.84776C5.75507 7.84776 5.83986 7.88211 5.90237 7.94327C5.96488 8.00442 6 8.08736 6 8.17384ZM6 8.17384H5.66667M9.33333 8.17384C9.33333 8.26032 9.29821 8.34327 9.2357 8.40442C9.17319 8.46557 9.08841 8.49993 9 8.49993C8.91159 8.49993 8.82681 8.46557 8.7643 8.40442C8.70179 8.34327 8.66667 8.26032 8.66667 8.17384C8.66667 8.08736 8.70179 8.00442 8.7643 7.94327C8.82681 7.88211 8.91159 7.84776 9 7.84776C9.08841 7.84776 9.17319 7.88211 9.2357 7.94327C9.29821 8.00442 9.33333 8.08736 9.33333 8.17384ZM9.33333 8.17384H9M12.6667 8.17384C12.6667 8.26032 12.6315 8.34327 12.569 8.40442C12.5065 8.46557 12.4217 8.49993 12.3333 8.49993C12.2449 8.49993 12.1601 8.46557 12.0976 8.40442C12.0351 8.34327 12 8.26032 12 8.17384C12 8.08736 12.0351 8.00442 12.0976 7.94327C12.1601 7.88211 12.2449 7.84776 12.3333 7.84776C12.4217 7.84776 12.5065 7.88211 12.569 7.94327C12.6315 8.00442 12.6667 8.08736 12.6667 8.17384ZM12.6667 8.17384H12.3333M17 8.17384C17 12.1355 13.4178 15.3477 9 15.3477C8.23305 15.3486 7.46937 15.2501 6.72889 15.0546C5.68512 15.7728 4.41184 16.0991 3.14222 15.9738C3.00106 15.9604 2.8605 15.9416 2.72089 15.9172C3.15899 15.4121 3.4582 14.8061 3.59022 14.1564C3.67022 13.759 3.472 13.3729 3.17511 13.0903C1.82667 11.8068 1 10.0773 1 8.17384C1 4.21214 4.58222 1 9 1C13.4178 1 17 4.21214 17 8.17384Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </>
        </BaseIcon>
    );
});

export default memo(MessageIcon);
