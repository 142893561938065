import { DateTime } from 'luxon';
import { createContext } from 'react';

export interface DeliveryDate {
    brandName?: string;
    productGroupId?: string;
    variantGroupId?: string;
    sku?: string;
    quantity?: number;
    sortIndex?: number;
    deliveryDate: string;
}

export interface DeliveryDatesContextValue {
    loading: boolean;
    deliveryDatesUpdating: boolean;
    defaultDeliveryDateUpdating: boolean;
    defaultDeliveryDate: DateTime;
    defaultDeliveryDateState?: DateTime;
    deliveryDates: DeliveryDate[];
    applicableDeliveryDates?: string[];
    updateDefaultDeliveryDate(deliveryDate?: DateTime): void;
    updateBrandDeliveryDate(brandName: string, deliveryDate?: DateTime): void;
    updateProductGroupDeliveryDate(
        productGroupId: string,
        deliveryDate?: DateTime
    ): void;
    updateVariantGroupDeliveryDate(
        variantGroupId: string,
        deliveryDate?: DateTime
    ): void;
    updateProductDeliveryDate(args: {
        sku: string;
        sortIndex: number;
        deliveryDate?: DateTime;
        quantity?: number;
    }): void;
}

/* istanbul ignore next -- @preserve */
const dummyFn = () => {
    // noop
};

const DeliveryDatesContext = createContext<DeliveryDatesContextValue>({
    loading: false,
    deliveryDatesUpdating: false,
    defaultDeliveryDateUpdating: false,
    defaultDeliveryDate: DateTime.now(),
    deliveryDates: [],
    updateDefaultDeliveryDate: dummyFn,
    updateBrandDeliveryDate: dummyFn,
    updateProductGroupDeliveryDate: dummyFn,
    updateVariantGroupDeliveryDate: dummyFn,
    updateProductDeliveryDate: dummyFn,
});

export default DeliveryDatesContext;
