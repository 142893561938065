import type { Uppy } from '@uppy/core';
import DropTarget from '@uppy/drop-target';
import type { MutableRefObject } from 'react';
import { useEffect, useState } from 'react';
import { supportedMimoTypes } from '../supportedMimoTypes';
import type { UseUppyInputParams } from './useUppyInput';

const useDragAndDrop = ({
    dropUploadRef,
    uppy,
    allowedFileTypes,
}: Pick<UseUppyInputParams, 'dropUploadRef'> & {
    /**
     * The uppy reference
     */
    uppy: MutableRefObject<Uppy>;
    /**
     * Allowed types
     */
    allowedFileTypes: string[];
}) => {
    const [dropActive, setDropActive] = useState(false);
    const [dropError, setDropError] = useState(false);

    useEffect(() => {
        if (dropUploadRef.current) {
            const plugin = uppy.current.getPlugin('react:DropTarget');
            if (plugin) {
                uppy.current.removePlugin(plugin);
            }
            uppy.current.use(DropTarget, {
                id: 'react:DropTarget',
                target: dropUploadRef.current,
                onDragOver: event => {
                    const dragEvent = event as unknown as {
                        /**
                         *
                         */
                        dataTransfer?: DataTransfer;
                    };
                    if (dragEvent.dataTransfer) {
                        const files = [...dragEvent.dataTransfer.items];

                        const isAllowed = files.every(file =>
                            allowedFileTypes
                                .map(type => type.substring(1))
                                .find(type => {
                                    return (
                                        supportedMimoTypes[type] === file.type
                                    );
                                })
                        );
                        if (isAllowed) {
                            setDropError(false);
                        } else {
                            setDropError(true);
                        }
                    }
                    setDropActive(true);
                },
                onDragLeave: () => {
                    setDropActive(false);
                    setDropError(false);
                },
                onDrop() {
                    setDropActive(false);
                    setDropError(false);
                },
            });
        }
    }, [dropUploadRef, allowedFileTypes, uppy]);
    return {
        dropActive,
        dropError,
    };
};

export default useDragAndDrop;
