const getNumberLocale = (locale: string) => {
    if (locale.toLocaleLowerCase() === 'fr-ch') {
        return {
            thousandSeparator: '’',
            decimalSeparator: '.',
        };
    }

    const thousandSeparator = new Intl.NumberFormat(locale)
        .format(1111)
        .replace(/1/g, '');
    const decimalSeparator = new Intl.NumberFormat(locale)
        .format(1.1)
        .replace(/1/g, '');
    return {
        thousandSeparator,
        decimalSeparator,
    };
};
export default getNumberLocale;
