import { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import type { SupportTicket, SupportTicketEvent } from '@apiTypes';
import { Text, Button, SidePane, VStack } from '@library';
import type { ProductClass } from '../../queries/useProductClasses';
import FilesSection from '../Common/Files/FilesSection';
import { isTypeNameFilter } from '../../types';
import { attachmentsEventTypeNames } from '../Common/Files/types';
import styles from './UploadedFiles.module.scss';

const UploadedFiles = ({
    events,
    id,
    type,
    currentProductClass,
}: {
    events: SupportTicketEvent[];
    id: SupportTicket['id'];
    type: SupportTicket['type'];
    currentProductClass?: ProductClass;
}) => {
    const [show, setShow] = useState(false);
    const attachmentsEvents = events.filter(
        isTypeNameFilter(attachmentsEventTypeNames.slice())
    );

    if (
        !attachmentsEvents.some(event => {
            return event.attachments?.length;
        })
    ) {
        return null;
    }

    const filesCounts = attachmentsEvents.reduce((total, event) => {
        return total + (event.attachments?.length || 0);
    }, 0);

    return (
        <>
            <Button
                text
                inline
                onClick={() => {
                    setShow(true);
                }}
            >
                <Text>
                    <FormattedMessage
                        id="Tickets MobileUpload AttachedFilesButton"
                        values={{ count: filesCounts }}
                    />
                </Text>
            </Button>
            <SidePane
                header={
                    <FormattedMessage id="Tickets MobileUpload AttachedFilesSidePane Title" />
                }
                side="bottom"
                open={show}
                autoHeight
                onOpenChange={setShow}
                footer={
                    <VStack>
                        <Button
                            outline
                            onClick={() => setShow(false)}
                            justify="center"
                        >
                            <FormattedMessage id="close" />
                        </Button>
                    </VStack>
                }
            >
                <div className={styles.container}>
                    <FilesSection
                        events={events}
                        id={id}
                        type={type}
                        currentProductClass={currentProductClass}
                        readonly
                    />
                </div>
            </SidePane>
        </>
    );
};

export default memo(UploadedFiles);
