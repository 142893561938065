import { useMemo } from 'react';
import { debounce } from 'lodash-es';
import useImmutableCallback from './useImmutableCallback';

const useDebouncedCallback = <T extends (...args: never[]) => unknown>(
    callback: T,
    wait: number
) => {
    const immutableCallback = useImmutableCallback(callback);
    return useMemo(
        () => debounce(immutableCallback, wait),
        [wait, immutableCallback]
    );
};

export default useDebouncedCallback;
