import { memo, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { HStack, LayoutContext, Text } from '@library';
import { ProgressSpinner } from '../../../core/components/ProgressIndicators';
import { useUpdateTicketInCreation } from '../../context/TicketCreationContext/TicketCreationContext';
import useEffectWithoutMount from '../../../core/util/useEffectWithoutMount';
import styles from './DraftIndicator.module.scss';

const DraftIndicator = memo(() => {
    const { syncPending } = useUpdateTicketInCreation();

    const [changes, setChanges] = useState(false);
    const { isInMobileView } = useContext(LayoutContext);

    useEffectWithoutMount(() => {
        if (syncPending) setChanges(true);
    }, [syncPending]);

    if (!changes || isInMobileView) {
        return null;
    }

    if (syncPending) {
        return (
            <div className={styles.indicator}>
                <HStack gap="small" align="center">
                    <ProgressSpinner />
                    <Text>
                        <FormattedMessage id="Tickets Creation Draft SavingUpdates" />
                    </Text>
                </HStack>
            </div>
        );
    }
    return (
        <div className={styles.indicator}>
            <Text>
                <FormattedMessage id="Tickets Creation Draft DraftSaved" />
            </Text>
        </div>
    );
});

export default DraftIndicator;
