import { gql } from '@apollo/client';
import type { QuerySupportTicketArgs, SupportTicket } from '@apiTypes';
import usePolledQuery from '../../core/hooks/apollo/usePolledQuery';
import transformUnionTypes from '../util/transformUnionTypes';
import { eventsFragment } from './commonFragments';

export const ticketEventsQuery = gql`
    ${eventsFragment}
    query ticketDetail($id: Int!) {
        supportTicket(id: $id) {
            id
            ...EventsFragment
        }
    }
`;

const useTicketEvents = (id: number, pollInterval = 20_000, skip?: boolean) => {
    const { data, loading, error } = usePolledQuery<
        {
            supportTicket: Pick<SupportTicket, 'events' | 'id'>;
        },
        QuerySupportTicketArgs
    >(ticketEventsQuery, {
        variables: {
            id,
        },
        pollInterval,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip,
    });

    return {
        events: transformUnionTypes(data?.supportTicket.events ?? []),
        error,
        loading,
    };
};

export default useTicketEvents;
