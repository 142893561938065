import { gql } from '@apollo/client';
import type { QuerySupportTicketArgs, SupportTicket } from '@apiTypes';
import usePolledQuery from '../../core/hooks/apollo/usePolledQuery';

export const ticketOverviewQuery = gql`
    query ticketOverview($id: Int!) {
        supportTicket(id: $id) {
            id
            type
            status
            productClassId
            __typename
        }
    }
`;

const useTicketOverview = (id: number, pollInterval = 20_000) => {
    const { data, loading, error } = usePolledQuery<
        {
            supportTicket: Pick<
                SupportTicket,
                'id' | 'type' | 'status' | 'productClassId'
            >;
        },
        QuerySupportTicketArgs
    >(ticketOverviewQuery, {
        variables: {
            id,
        },
        pollInterval,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    return {
        ticket: data?.supportTicket,
        error,
        loading,
    };
};

export default useTicketOverview;
