import { memo } from 'react';
import type { IconProps } from './types';

const MinusIcon = ({ iconLabel, color }: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9.746"
            height="10.352"
            viewBox="0 0 9.746 10.352"
            data-icon={iconLabel}
            color={color}
        >
            <g transform="translate(-199.892 -414.035)">
                <rect
                    fill="none"
                    width="9.746"
                    height="10.352"
                    transform="translate(199.892 414.035)"
                />
                <g style={{ isolation: 'isolate' }}>
                    <path
                        fill="currentColor"
                        d="M200.534,417.871H209v2.68h-8.462Z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default memo(MinusIcon);
