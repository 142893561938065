import { memo } from 'react';
import BaseIcon from './BaseIcon';
import type { IconProps } from './types';

const BellIcon = (props: IconProps) => {
    return (
        <BaseIcon viewBox="0 0 28 30" {...props} fill="none">
            <path
                d="M25.4331 25.9081H2.56645C2.02775 25.9081 1.50271 25.743 1.06571 25.4362C0.628707 25.1294 0.301909 24.6964 0.131613 24.1986C-0.0386831 23.7008 -0.0438432 23.1634 0.116864 22.6626C0.277571 22.1618 0.595996 21.7229 1.02703 21.4081L2.17991 20.5659C2.61379 20.2501 2.90489 19.7826 2.9919 19.2619L4.43836 10.7091C4.79152 8.48523 5.95004 6.45783 7.70441 4.99359C9.45877 3.52935 11.6932 2.72494 14.0035 2.72583C16.3138 2.72672 18.5475 3.53285 20.3007 4.99844C22.0539 6.46403 23.2108 8.49232 23.5621 10.7165L25.0076 19.2619C25.0946 19.7826 25.3857 20.2501 25.8196 20.5659L26.9728 21.4081C27.4039 21.7229 27.7224 22.1618 27.8831 22.6627C28.0439 23.1636 28.0387 23.701 27.8683 24.1989C27.6979 24.6967 27.371 25.1297 26.9339 25.4365C26.4968 25.7433 25.9716 25.9083 25.4328 25.9081H25.4331ZM14.0008 4.08997C12.0216 4.08061 10.1053 4.76629 8.60296 6.02135C7.10064 7.27641 6.11298 9.01677 5.82099 10.9235L4.37348 19.4834C4.22825 20.3512 3.74306 21.1302 3.02004 21.6565L1.86681 22.4991C1.67085 22.6421 1.52608 22.8416 1.45303 23.0693C1.37997 23.297 1.38233 23.5413 1.45978 23.7676C1.53722 23.9939 1.68581 24.1907 1.8845 24.3301C2.0832 24.4696 2.3219 24.5446 2.5668 24.5445H25.4334C25.6782 24.5445 25.9169 24.4695 26.1155 24.3301C26.3141 24.1907 26.4627 23.9939 26.5401 23.7677C26.6175 23.5414 26.6199 23.2972 26.5469 23.0696C26.4739 22.842 26.3293 22.6425 26.1334 22.4994L24.9798 21.6565C24.2568 21.1302 23.7716 20.3512 23.6264 19.4834L22.1795 10.9308C21.889 9.02302 20.9021 7.2812 19.3999 6.02473C17.8977 4.76827 15.9808 4.08136 14.0008 4.08997Z"
                fill="currentColor"
            />
            <path
                d="M16.0999 4.33563C15.9142 4.33563 15.7362 4.2638 15.6049 4.13593C15.4736 4.00807 15.3999 3.83464 15.3999 3.65381V2.72654C15.3999 2.36488 15.2524 2.01804 14.9899 1.7623C14.7273 1.50657 14.3712 1.3629 13.9999 1.3629C13.6286 1.3629 13.2725 1.50657 13.01 1.7623C12.7474 2.01804 12.5999 2.36488 12.5999 2.72654V3.65381C12.5999 3.83464 12.5262 4.00807 12.3949 4.13593C12.2636 4.2638 12.0856 4.33563 11.8999 4.33563C11.7143 4.33563 11.5362 4.2638 11.405 4.13593C11.2737 4.00807 11.2 3.83464 11.2 3.65381V2.72654C11.2 2.00322 11.4949 1.30953 12.02 0.798067C12.5451 0.286604 13.2573 -0.000732422 13.9999 -0.000732422C14.7425 -0.000732422 15.4547 0.286604 15.9798 0.798067C16.5049 1.30953 16.7999 2.00322 16.7999 2.72654V3.65381C16.7999 3.83464 16.7261 4.00807 16.5949 4.13593C16.4636 4.2638 16.2855 4.33563 16.0999 4.33563Z"
                fill="currentColor"
            />
            <path
                d="M14 30.0007C12.7009 29.9993 11.4555 29.496 10.5369 28.6012C9.61825 27.7065 9.10154 26.4933 9.1001 25.228C9.1001 25.0471 9.17385 24.8737 9.30512 24.7458C9.43639 24.618 9.61444 24.5461 9.80009 24.5461C9.98574 24.5461 10.1638 24.618 10.2951 24.7458C10.4263 24.8737 10.5001 25.0471 10.5001 25.228C10.5001 26.1321 10.8688 26.9992 11.5252 27.6386C12.1816 28.2779 13.0718 28.6371 14 28.6371C14.9283 28.6371 15.8185 28.2779 16.4749 27.6386C17.1312 26.9992 17.5 26.1321 17.5 25.228C17.5 25.0471 17.5737 24.8737 17.705 24.7458C17.8363 24.618 18.0143 24.5461 18.2 24.5461C18.3856 24.5461 18.5637 24.618 18.695 24.7458C18.8262 24.8737 18.9 25.0471 18.9 25.228C18.8985 26.4933 18.3818 27.7065 17.4632 28.6012C16.5446 29.496 15.2991 29.9993 14 30.0007Z"
                fill="currentColor"
            />
        </BaseIcon>
    );
};

export default memo(BellIcon);
