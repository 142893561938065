import type { ReactNode } from 'react';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from '../Checkbox';
import { Text } from '../../typography';
import MultiSelect from './MultiSelect';
import type { MultiSelectProps } from './types';
import styles from './MultiSelectWithSelectAll.module.scss';

const MultiSelectWithSelectAll = ({
    id,
    value,
    options,
    onChange,
    allOptionsSelectedText,
    selectAllVisibleOptionsText,
    ...props
}: Omit<MultiSelectProps, 'header'> & {
    /**
     * Text to be used for "Select All" trigger
     * when the list is filtered by search
     */
    selectAllVisibleOptionsText?: ReactNode;
}) => {
    const allOptions = options.map(item => item.value);

    const selectAllOptions = (visibleOptions = allOptions) => {
        onChange([...value, ...visibleOptions]);
    };

    const unselectAllOptions = (visible = allOptions) => {
        onChange(value.slice().filter(item => !visible.includes(item)));
    };

    return (
        <MultiSelect
            {...props}
            id={id}
            value={value}
            onChange={onChange}
            options={options}
            header={memo(({ options: visibleOptions }) => {
                const isAllSelected = useMemo(() => {
                    const visibleOptionsValues = visibleOptions?.map(
                        item => item.value
                    );

                    return (visibleOptionsValues ?? allOptions).every(option =>
                        value.includes(option)
                    );
                }, [visibleOptions]);

                if (visibleOptions?.length === 0) return null;

                return (
                    <div className={styles.selectAllControl}>
                        <Checkbox
                            checked={isAllSelected}
                            indeterminate={value.length > 0 && !isAllSelected}
                            onChange={() =>
                                isAllSelected
                                    ? unselectAllOptions(
                                          visibleOptions?.map(
                                              item => item.value
                                          )
                                      )
                                    : selectAllOptions(
                                          visibleOptions?.map(
                                              item => item.value
                                          )
                                      )
                            }
                        >
                            {visibleOptions?.length === allOptions.length ? (
                                <Text secondary bold>
                                    {allOptionsSelectedText || (
                                        <FormattedMessage id="Select all" />
                                    )}
                                </Text>
                            ) : (
                                <Text secondary bold>
                                    {selectAllVisibleOptionsText || (
                                        <FormattedMessage id="select all visible" />
                                    )}
                                </Text>
                            )}
                        </Checkbox>
                    </div>
                );
            })}
        />
    );
};

export default memo(MultiSelectWithSelectAll);
