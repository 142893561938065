import type { ComponentProps, ReactElement } from 'react';
import { memo } from 'react';
import type Collapsible from './Collapsible';
import styles from './CollapsibleGroup.module.scss';

const CollapsibleGroup = ({
    children,
}: {
    children: ReactElement<ComponentProps<typeof Collapsible>>[];
}) => {
    return <div className={styles.collapsibleGroup}>{children}</div>;
};

export default memo(CollapsibleGroup);
