import type {
    SupportTicketItem,
    SupportTicketItemReason,
    SupportTicketItemRejectionEvent,
    SupportTicketItemResolution,
    SupportTicketItemResolutionEvent,
} from '@apiTypes';
import type { GroupedTicketItem } from '../types';
import { isTypename } from '../types';
import getTicketItemKey from './getTicketItemKey';

const getResolutionReason = (
    resolutions?:
        | (SupportTicketItemRejectionEvent | SupportTicketItemResolutionEvent)[]
        | undefined
) => {
    return resolutions && resolutions.length > 0
        ? resolutions[resolutions.length - 1]?.reason
        : undefined;
};

const getData = (
    item: SupportTicketItem,
    resolutions?: (
        | SupportTicketItemRejectionEvent
        | SupportTicketItemResolutionEvent
    )[]
): {
    quantity: number;
    reason?: SupportTicketItemReason;
    resolution?: SupportTicketItemResolution;
    sku?: string;
    confirmedQuantity?: number;
    resolutionReason?: string;
} => {
    const { resolution, sku } = item;
    const common = { resolution, sku };
    if (isTypename(item, 'SupportTicketReturnItem')) {
        const currentRejections = resolutions?.filter(rejection => {
            return rejection.salesLines.find(
                saleLine =>
                    isTypename(saleLine, 'SupportTicketReturnItem') &&
                    saleLine.reason === item.reason &&
                    saleLine.sku === item.sku &&
                    saleLine.upstreamRecId === item.upstreamRecId
            );
        });
        return {
            quantity: item.quantity,
            reason: item.reason,
            resolutionReason: getResolutionReason(currentRejections),
            ...common,
            confirmedQuantity: item.confirmedQuantity,
        };
    }
    if (isTypename(item, 'SupportTicketWarrantyOrRepairItem')) {
        const currentRejections = resolutions?.filter(rejection => {
            return rejection.salesLines.find(
                saleLine =>
                    isTypename(saleLine, 'SupportTicketWarrantyOrRepairItem') &&
                    saleLine.sku === item.sku &&
                    saleLine.upstreamRecId === item.upstreamRecId
            );
        });
        return {
            quantity: 1,
            resolutionReason: getResolutionReason(currentRejections),
            ...common,
        };
    }

    return {
        quantity: 1,
        resolutionReason: getResolutionReason(resolutions),
        ...common,
    };
};

const getGroupedTicketItems = (
    items: SupportTicketItem[],
    resolutions?: (
        | SupportTicketItemRejectionEvent
        | SupportTicketItemResolutionEvent
    )[]
): GroupedTicketItem[] => {
    const itemsMap: Map<string, GroupedTicketItem> = new Map();

    for (const item of items) {
        const {
            quantity,
            reason,
            confirmedQuantity,
            resolution,
            resolutionReason,
        } = getData(item, resolutions);
        const key = getTicketItemKey(item);
        const existingItem = itemsMap.get(key);
        if (existingItem) {
            existingItem.breakdown.push({
                quantity,
                reason,
                resolution,
                resolutionReason,
            });
            existingItem.restQuantity -= quantity;
            itemsMap.set(key, existingItem);
        } else {
            itemsMap.set(key, {
                supportTicketItem: item,
                breakdown: [
                    {
                        quantity,
                        reason,
                        resolution,
                        resolutionReason,
                    },
                ],
                id: [item.orderNumber, item.sku].join('-'),
                restQuantity: confirmedQuantity
                    ? confirmedQuantity - quantity
                    : 0,
            });
        }
    }

    return Array.from(itemsMap.values());
};

export default getGroupedTicketItems;
