import { gql } from '@apollo/client';
import { useContext } from 'react';
import type { CustomerPreorderPricing } from '@apiTypes';
import { AppContext } from '../../core/context';
import useCurrentUser from '../../core/hooks/useCurrentUser';
import PreorderModeContext from '../../preorder/PreorderModeContext';

import usePolledQuery from '../../core/hooks/apollo/usePolledQuery';
import { useCanEditPreorderDiscounts } from '../../user/queries/useCapabilities';
export const customerPreorderPricingQuery = gql`
    query customerPreorderPricing(
        $customerNumber: ID!
        $preorderSeasonId: ID!
    ) {
        customerPreorderPricing(
            customerNumber: $customerNumber
            preorderSeasonId: $preorderSeasonId
        ) {
            preorderDiscounts {
                rebateCode
                productGroupId
                variantGroupId
                sku
                discount
                price
            }
        }
    }
`;

const useCustomerPreorderPricing = () => {
    const { selectedPreorderSeason } = useContext(PreorderModeContext);
    const currentUser = useCurrentUser();
    const customerNumber = currentUser?.customerNumber;
    const canEditPreorderDiscounts = useCanEditPreorderDiscounts();

    const { isImpersonating } = useContext(AppContext);
    const skip = !(
        selectedPreorderSeason &&
        customerNumber &&
        isImpersonating &&
        canEditPreorderDiscounts
    );

    const { loading, data, error } = usePolledQuery<{
        customerPreorderPricing: CustomerPreorderPricing;
    }>(customerPreorderPricingQuery, {
        skip,
        variables: {
            preorderSeasonId: selectedPreorderSeason?.id,
            customerNumber,
        },
        pollInterval: 180_000,
    });

    if (error) {
        throw error;
    }

    return {
        loading: loading || skip,
        customerPreorderPricing: data?.customerPreorderPricing,
    };
};

export default useCustomerPreorderPricing;
