import type { ComponentType } from 'react';
import { useState, memo } from 'react';
import type { ListChildComponentProps } from 'react-window';
import { FixedSizeList } from 'react-window';
import FadeScrollEffect from '../../../../core/components/layout/FadeScroll/FadeScrollEffect';
import type { SelectOption } from './types';

export interface WindowRenderOptions {
    /**
     * Height of scrollable aria
     */
    height: number;
    /**
     * Width of scrollable aria
     */
    width?: string;
}

const ITEM_SIZE = 36;
const MARGIN_TOP = 9;

/* eslint-disable jsdoc/require-jsdoc */
const SingleSelectWindowRender = memo(
    ({
        filteredOptions,
        windowRender,
        children,
    }: {
        filteredOptions: SelectOption[];
        windowRender: WindowRenderOptions;
        children: ComponentType<ListChildComponentProps>;
    }) => {
        const fullHeight = MARGIN_TOP + ITEM_SIZE * filteredOptions.length;
        const isScroll = fullHeight - windowRender.height > 0;

        const [top, setTop] = useState(false);
        const [bottom, setBottom] = useState(fullHeight > windowRender.height);

        return (
            <FadeScrollEffect
                mode="vertical"
                isBottom={bottom}
                isTop={top}
                mediumFade
            >
                <FixedSizeList
                    height={Math.min(
                        windowRender.height,
                        fullHeight,
                        filteredOptions.length === 0 ? 0 : Number.MAX_VALUE
                    )}
                    width={windowRender.width || '100%'}
                    itemCount={filteredOptions.length}
                    itemSize={ITEM_SIZE}
                    onScroll={params => {
                        setBottom(
                            isScroll &&
                                params.scrollOffset !==
                                    fullHeight - windowRender.height
                        );
                        setTop(params.scrollOffset !== 0);
                    }}
                    overscanCount={16}
                >
                    {children}
                </FixedSizeList>
            </FadeScrollEffect>
        );
    }
);

export default SingleSelectWindowRender;
