import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from '../../typography';
import type { UseUppyInputParams } from './hooks/useUppyInput';
import AcceptFilesMessage from './AcceptFilesMessage';

const FileRestrictionMessage = ({
    accept,
    maxFileSize,
}: Pick<UseUppyInputParams, 'accept' | 'maxFileSize'>) => {
    const { locale } = useIntl();
    const byteValueNumberFormatter = Intl.NumberFormat(locale, {
        notation: 'compact',
        style: 'unit',
        unit: 'megabyte',
        unitDisplay: 'narrow',
    });

    return accept || maxFileSize ? (
        <div>
            <Text secondary>
                <AcceptFilesMessage accept={accept} />
            </Text>
            <Text secondary>
                {' '}
                {maxFileSize && (
                    <FormattedMessage
                        id="Library FileField MaxFileSizeHint"
                        values={{
                            maxFileSize: byteValueNumberFormatter.format(
                                maxFileSize / 1024 / 1024
                            ),
                        }}
                    />
                )}
            </Text>
        </div>
    ) : null;
};

export default FileRestrictionMessage;
