import { gql, useMutation } from '@apollo/client';
import type {
    CreateSupportTicketResult,
    MutationCreateSupportTicketArgs,
} from '@apiTypes';

export const createTicketMutation = gql`
    mutation createTicket(
        $record: NewSupportTicketInput!
        $items: [SupportTicketItemUpdateInput!]!
    ) {
        createSupportTicket(record: $record, items: $items) {
            id
        }
    }
`;

const useCreateTicket = () => {
    return useMutation<
        { createTicket: CreateSupportTicketResult },
        MutationCreateSupportTicketArgs
    >(createTicketMutation, {
        awaitRefetchQueries: true,
    });
};

export default useCreateTicket;
