import { CartFieldsProvider } from '../cart/contexts/CartFields/CartFieldsContext';
import AccessDeniedRedirect from '../core/components/router/AccessDeniedRedirect';
import LazyComponent from '../core/components/router/LazyComponent';
import RestrictedContent from '../core/components/router/RestrictedContent';

const CheckoutProvider = LazyComponent(
    async () => import('./context/CheckoutProvider')
);
const Checkout = LazyComponent(async () => import('./components/Checkout'));

const checkoutRoutes = [
    {
        path: 'checkout',
        element: (
            <RestrictedContent
                loggedInOnly
                restrictedPage
                fallback={AccessDeniedRedirect}
            >
                <CartFieldsProvider>
                    <CheckoutProvider>
                        <Checkout />
                    </CheckoutProvider>
                </CartFieldsProvider>
            </RestrictedContent>
        ),
    },
];
export default checkoutRoutes;
