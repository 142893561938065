import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloProvider, gql, useQuery } from '@apollo/client';
import type { ReactNode } from 'react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import ContentClientProvider from '../../../content/components/ContentClientProvider';
import { createClient } from '../../../main/client';
import type { CurrentUserQuery, User } from '../../../user/types';
import useNotifyNetworkStatus from '../../hooks/useNotifyNetworkStatus';
import checkUrlToken from '../../util/auth/checkUrlToken';
import {
    getNotExpiredToken,
    clearAccessToken,
    storeAccessToken,
    getPermissionToken,
} from '../../util/auth/credentialsStorage';
import SpinnerPage from '../pages/SpinnerPage';
import type { UserContextValue } from '../UserContext';
import UserContext from '../UserContext';
import captureException from '../../util/captureException';

const currentUserQuery = gql`
    query currentUser {
        currentUser {
            username
            customerNumber
            email
            language
            impersonating
            lastName
            firstName
            stores {
                id: code
                url
                languages {
                    language
                }
            }
            dealerPricesEnabled
        }
        currentCustomer {
            id
            name
            locationCode
            requiresPrepay
            yamahaPortalEnabled
            phone
            branches {
                id
                isMainStock
                nameEN
                nameDE
                nameFR
                nameIT
            }
        }
    }
`;

checkUrlToken();

const UserProvider = memo(
    ({
        value,
        children,
        client,
    }: {
        value: Pick<UserContextValue, 'accessToken' | 'setAccessToken'>;
        children: ReactNode;
        client: ApolloClient<NormalizedCacheObject>;
    }) => {
        // eslint-disable-next-line @hostettler/handle-query-error
        const { data } = useQuery<CurrentUserQuery>(currentUserQuery, {
            fetchPolicy: 'cache-only',
            client,
        });

        return (
            <UserContext.Provider
                value={{
                    user: data?.currentUser,
                    customer: data?.currentCustomer,
                    ...value,
                }}
            >
                {children}
            </UserContext.Provider>
        );
    }
);

const ApolloClientProvider = ({ children }: { children: ReactNode }) => {
    const [accessToken, setAccessTokenState] = useState<string | undefined>(
        getNotExpiredToken()
    );
    const [state, setState] = useState<{
        user?: User;
        client: ApolloClient<NormalizedCacheObject>;
    }>();

    const previousClient = useRef<ReturnType<typeof createClient>>();

    const setAccessToken = useCallback(
        (token?: string, persist?: boolean, skipStorageChanges?: boolean) => {
            if (!skipStorageChanges) {
                if (token) {
                    storeAccessToken(token, persist);
                } else {
                    clearAccessToken();
                }
            }
            setAccessTokenState(token);
        },
        []
    );

    const permissionToken = getPermissionToken();

    useEffect(() => {
        const create = async () => {
            const createClientResult = createClient(
                setAccessToken,
                accessToken,
                permissionToken
            );
            previousClient.current = createClientResult;
            if (permissionToken) {
                setState({
                    user: undefined,
                    client: createClientResult.client,
                });
                return;
            }

            if (accessToken) {
                try {
                    const { data } =
                        await createClientResult.client.query<CurrentUserQuery>(
                            {
                                query: currentUserQuery,
                            }
                        );

                    if (!data.currentUser) {
                        setAccessToken();
                    }
                    setState({
                        user: data.currentUser,
                        client: createClientResult.client,
                    });
                } catch (error) {
                    setAccessToken();
                    captureException(error);
                }
            } else {
                setState({
                    user: undefined,
                    client: createClientResult.client,
                });
            }
        };
        create().catch(error => {
            captureException(error);
        });

        return () => {
            if (previousClient.current) {
                previousClient.current.client.stop();
                clearInterval(previousClient.current.refreshInterval);
            }
        };
    }, [accessToken, setAccessToken, permissionToken]);

    useNotifyNetworkStatus();

    // Don't render anything if we are not done determining if we are logged in
    if (!state) {
        return <SpinnerPage />;
    }

    return (
        <UserProvider
            client={state.client}
            value={{ accessToken, setAccessToken }}
        >
            <ApolloProvider client={state.client}>
                <ContentClientProvider>{children}</ContentClientProvider>
            </ApolloProvider>
        </UserProvider>
    );
};

export default memo(ApolloClientProvider);
