import { memo, useContext } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { importComponents } from '@library';
import SpinnerPage from '../core/components/pages/SpinnerPage';
import AccessDeniedRedirect from '../core/components/router/AccessDeniedRedirect';
import HomeRedirect from '../core/components/router/HomeRedirect';
import RestrictedContent from '../core/components/router/RestrictedContent';
import PreorderModeContext from '../preorder/PreorderModeContext';
import useActivePreorderSeasons from '../preorder/queries/useActivePreorderSeasons';

const { PostPage, StaticPage, PostsPage, HomePage } =
    importComponents(async () => {
        return import('./content.module');
    }, ['PostPage', 'StaticPage', 'PostsPage', 'HomePage']);

const PreorderPages = memo(() => {
    const { '*': page = '' } = useParams<{
        '*': string;
    }>();

    const { search } = useLocation();
    const { selectedPreorderSeason } = useContext(PreorderModeContext);
    const { activePreorderSeasons, loading } = useActivePreorderSeasons();
    if (loading) {
        return <SpinnerPage />;
    }

    const [preorderRootFolder] = page.split('/');
    const preorderSeason = activePreorderSeasons.find(
        season => season.cmsRootFolder === preorderRootFolder
    );
    if (!preorderSeason) {
        return <Navigate to={'../account'} replace />;
    }

    if (selectedPreorderSeason?.id !== preorderSeason.id) {
        return (
            <Navigate
                to={{
                    pathname: `../preorder/${preorderSeason.id}/preorder-pages/${page}`,
                    search,
                }}
                replace
            />
        );
    }
    return <StaticPage prefix="preorder-pages/" />;
});

const ContentRoutes: RouteObject[] = [
    {
        path: '',
        element: <HomePage />,
    },
    {
        path: 'news',
        children: [
            {
                index: true,
                element: <PostsPage />,
            },
            {
                path: ':page',
                element: <PostPage />,
            },
        ],
    },
    {
        path: '*',
        element: <StaticPage />,
    },
    {
        path: 'preorder-pages/*',
        element: (
            <RestrictedContent loggedInOnly fallback={AccessDeniedRedirect}>
                <RestrictedContent
                    preorder
                    shouldShowSpinnerPage
                    fallback={HomeRedirect}
                >
                    <PreorderPages />
                </RestrictedContent>
            </RestrictedContent>
        ),
    },
];

export default ContentRoutes;
