import { memo, useRef } from 'react';
import type { ReactNode, RefObject } from 'react';
import { clsx } from 'clsx';
import useSelectDropdownInteractions from '../../../hooks/useSelectDropdownInteractions';
import useOverflowingElement from '../../../hooks/dom/useOverflowingElement';
import styles from './Select.module.css';
import SelectDropdownItem from './SelectDropdownItem';

export interface SelectDropdownOptions {
    label: ReactNode;
    value: string;
    disabled?: boolean;
}

export interface SelectDropdownProps {
    standalone?: boolean;
    value: string | string[];
    options: SelectDropdownOptions[];
    onChange(newValue: string): void;
    closeDropdown(): void;
    rightAligned?: boolean;
    menuSize?: 'min' | 'max' | 'full';
    boundsRef?: RefObject<HTMLElement | null>;
    small?: boolean;
    formStyle?: boolean;
}

const SelectDropdown = ({
    standalone = false,
    value,
    onChange,
    options,
    closeDropdown,
    rightAligned = false,
    menuSize = 'min',
    boundsRef,
    small,
    formStyle,
}: SelectDropdownProps) => {
    const [arrayOfOptionsRefs, handleOptionsEvents] =
        useSelectDropdownInteractions(onChange, closeDropdown);

    const menuRef = useRef<HTMLDivElement>(null);
    const isOverflowing = useOverflowingElement(menuRef, boundsRef);

    return (
        <div
            ref={menuRef}
            role="menu"
            tabIndex={0}
            className={clsx(styles.dropdown, styles[menuSize], {
                [styles.rightAligned]: isOverflowing || rightAligned,
                [styles.standalone]: standalone,
                [styles.small]: small,
                [styles.formStyle]: formStyle,
            })}
        >
            {options.map((option, index) => (
                <SelectDropdownItem
                    key={option.value}
                    value={value}
                    option={option}
                    formStyle={formStyle}
                    handleOptionsEvents={(optionValue, e) =>
                        handleOptionsEvents(optionValue, index, e)
                    }
                    ref={(element: HTMLDivElement | null) => {
                        if (element !== null) {
                            arrayOfOptionsRefs.current.push(element);
                        }
                    }}
                />
            ))}
        </div>
    );
};

export default memo(SelectDropdown);
