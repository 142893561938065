const transformUnionType = <
    T extends Record<string, unknown> & { __typename: string },
>(
    item: T
) => {
    return Object.keys(item).reduce(
        (result, key) => {
            if (key.includes(item.__typename)) {
                const newKey = key.replace(`${item.__typename}_`, '');
                result[newKey] = item[key];
            } else {
                result[key] = item[key];
            }
            return result;
        },
        {} as Record<string, unknown>
    ) as T;
};

export default transformUnionType;
