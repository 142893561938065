import { FormattedMessage } from 'react-intl';
import { memo } from 'react';
import type { FileTypes } from './hooks/useUppyInput';

const AcceptFilesMessage = ({
    accept,
}: {
    /**
     The accept types
     */
    accept?: FileTypes[];
}) => {
    if (!accept) {
        return null;
    }
    if (
        accept.includes('image') &&
        accept.includes('pdf') &&
        accept.includes('video')
    ) {
        return (
            <FormattedMessage id="Library FileField AcceptFiles ImageDocumentVideo" />
        );
    }
    if (accept.includes('image') && accept.includes('pdf')) {
        return (
            <FormattedMessage id="Library FileField AcceptFiles ImageDocument" />
        );
    }
    if (accept.includes('image') && accept.length === 1) {
        return <FormattedMessage id="Library FileField AcceptFiles Image" />;
    }
    return null;
};

export default memo(AcceptFilesMessage);
