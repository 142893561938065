import type { ReactNode } from 'react';
import { memo, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import notEmpty from '../../../../../core/util/notEmpty';
import type { BaseQuantityInputProps } from './BaseQuantityInput';
import BaseQuantityInput from './BaseQuantityInput';

const MaxMessage = memo(
    ({
        max,
    }: {
        /**
            max for quantity input
         */
        max: number;
    }) => (
        <FormattedMessage
            id="maximum {quantity}"
            values={{
                quantity: max,
            }}
        />
    )
);

export type QuantityInputProps = {
    /**
     * The value
     */
    value: number;
    /**
     * The onChange event callback
     */
    onChange: (value: number) => void;
    /**
     * The maximum
     */
    max?: number;
    /**
     * The max time.  Time can't be more than 23:30
     */
    maxMessage?: ReactNode;
    /**
     * The min time message
     */
    minMessage?: ReactNode;
    /**
     * Indicates whether max quantity is being loaded. Useful when max is determined asynchronously.
     */
    maxQuantityLoading?: boolean;
    /**
     * Indicates whether a persistent error message show be show instead of in a tooltip on user action.
     */
    showErrorMessage?: boolean;
    /**
     * Specify a custom max quantity change message which is different from the max message.
     */
    customQuantityChangeMessage?: ReactNode;
    /**
     * Specify a custom message to show when the max quantity is 0 instead of some finite number.
     */
    zeroMaxMessage?: ReactNode;
} & Pick<
    BaseQuantityInputProps,
    'loading' | 'small' | 'id' | 'disabled' | 'error' | 'errorMessage' | 'mini'
>;

const QuantityInput = ({
    value,
    onChange,
    max = Infinity,
    maxMessage: providedMaxMessage,
    minMessage,
    error: providedError,
    errorMessage: providedErrorMessage,
    maxQuantityLoading,
    showErrorMessage,
    customQuantityChangeMessage,
    zeroMaxMessage,
    ...props
}: QuantityInputProps) => {
    const [tempValue, setTempValue] = useState<number | null>(null);

    const errorProps = useMemo(() => {
        if (maxQuantityLoading) {
            return {
                error: providedError,
                errorMessage: undefined,
                maxMessage: providedMaxMessage,
            };
        }

        const maxMessage = providedMaxMessage || <MaxMessage max={max} />;

        if (max === 0) {
            return {
                error: value > 0,
                maxMessage: !showErrorMessage && (zeroMaxMessage || maxMessage),
                errorMessage:
                    value > 0 &&
                    showErrorMessage &&
                    (zeroMaxMessage || maxMessage),
            };
        } else if (max !== Infinity) {
            if (value > max) {
                return {
                    error: true,
                    maxMessage: !showErrorMessage && maxMessage,
                    errorMessage: showErrorMessage && maxMessage,
                };
            } else if (value === max) {
                return {
                    error: false,
                    maxMessage: maxMessage,
                };
            }
        }

        return {
            error: providedError,
            errorMessage: undefined,
            maxMessage: maxMessage,
        };
    }, [
        max,
        maxQuantityLoading,
        providedError,
        providedMaxMessage,
        showErrorMessage,
        value,
        zeroMaxMessage,
    ]);

    const validate = (newValue: number) => {
        if (notEmpty(max) && newValue > max) {
            return max;
        }

        if (newValue < 0) {
            return 0;
        }

        return newValue;
    };

    const onDecrease = () => {
        onChange(validate(value - 1));
    };
    const onIncrease = () => {
        if (notEmpty(max) && value >= max) {
            return;
        }

        onChange(validate(value + 1));
    };

    return (
        <BaseQuantityInput
            value={tempValue?.toString() || value.toString() || ''}
            onDecrease={onDecrease}
            onIncrease={onIncrease}
            onChange={({ floatValue }) => {
                setTempValue(floatValue || 0);
            }}
            maxMessage={
                notEmpty(max) &&
                value >= max &&
                (customQuantityChangeMessage || errorProps.maxMessage)
            }
            minMessage={value <= 0 && minMessage}
            onFocus={e => {
                e.target.select();
            }}
            onBlur={() => {
                if (tempValue !== null) {
                    onChange(validate(tempValue));
                    setTempValue(null);
                }
            }}
            numberFormatProps={{ allowNegative: false }}
            error={errorProps.error}
            errorMessage={errorProps.errorMessage}
            {...props}
        />
    );
};

export default memo(QuantityInput);
