import { memo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    Button,
    HStack,
    Heading,
    Text,
    VStack,
    CheckmarkCircleIcon,
} from '@library';
import useTicketOverview from '../../queries/useTicketOverview';
import useTicketAttachments, {
    getTicketAttachmentsRefetchQuery,
} from '../../queries/useTicketAttachments';
import SpinnerPage from '../../../core/components/pages/SpinnerPage';
import { useProductClass } from '../../queries/useProductClasses';

import { formatTicketNumber, parseTicketNumber } from '../../domain';
import FilesSectionForm from '../Common/Files/FilesSectionForm';
import PageNotFound from '../../../core/components/router/PageNotFound';
import useUploadTicketsDocumentForm from '../../queries/useUploadTicketsDocumentForm';
import HeadlessPage from '../../../core/components/layout/HeadlessPage';
import UploadedFiles from './UploadedFiles';

const QrUpload = () => {
    const [searchParams] = useSearchParams();
    const idParam = searchParams.get('id');
    const id = idParam ? parseTicketNumber(idParam) : -1;

    const { ticket, loading: ticketLoading } = useTicketOverview(id);
    const { attachments } = useTicketAttachments(id, 30_000);

    const productClass = useProductClass(ticket?.type, ticket?.productClassId);

    const {
        filesMap,
        setFilesMap,
        uploadAllDocuments,
        uploaded,
        anyAttachments,
        processing,
        setUploaded,
    } = useUploadTicketsDocumentForm(id);

    const intl = useIntl();

    if (ticketLoading) {
        return <SpinnerPage />;
    }
    if (!ticket) {
        return <PageNotFound />;
    }

    const title = intl.formatMessage(
        { id: 'Tickets MobileUpload Title' },
        {
            id: formatTicketNumber(ticket.id),
        }
    );

    return (
        <HeadlessPage
            title={title}
            footer={
                !uploaded && (
                    <VStack>
                        <Button
                            justify="center"
                            onClick={uploadAllDocuments}
                            loading={processing}
                            disabled={!anyAttachments}
                        >
                            <Heading h4 regular>
                                <FormattedMessage id="Tickets MobileUpload Submit" />
                            </Heading>
                        </Button>
                    </VStack>
                )
            }
            stickyFooter
        >
            {uploaded ? (
                <VStack gap="smallMedium">
                    <div />
                    <HStack align="center" gap="small">
                        <div>
                            <CheckmarkCircleIcon />
                        </div>
                        <VStack gap="micro">
                            <Heading h4 medium>
                                <FormattedMessage id="Tickets MobileUpload Success Title" />
                            </Heading>
                            <Text>
                                <FormattedMessage id="Tickets MobileUpload Success Message" />
                            </Text>
                        </VStack>
                    </HStack>

                    <Button
                        justify="center"
                        secondary
                        onClick={() => {
                            setUploaded(false);
                        }}
                    >
                        <FormattedMessage id="Tickets MobileUpload Success UploadMore" />
                    </Button>
                </VStack>
            ) : (
                <>
                    <HStack
                        align="center"
                        wrap
                        gap="small"
                        justify="spaceBetween"
                    >
                        <VStack gap="mini">
                            <Heading h2>{title}</Heading>
                            <Text>
                                <FormattedMessage id="Tickets MobileUpload Hint" />
                            </Text>
                            <UploadedFiles
                                events={attachments}
                                id={ticket.id}
                                type={ticket.type}
                                currentProductClass={productClass}
                            />
                        </VStack>
                    </HStack>

                    <FilesSectionForm
                        events={attachments}
                        id={ticket.id}
                        type={ticket.type}
                        filesMap={filesMap}
                        onFileMapChange={setFilesMap}
                        currentProductClass={productClass}
                        refetchQueries={[
                            getTicketAttachmentsRefetchQuery(ticket.id),
                        ]}
                        disabled={processing}
                    />
                </>
            )}
        </HeadlessPage>
    );
};

export default memo(QrUpload);
