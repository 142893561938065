/* eslint max-lines: ["error", 500] */
import type {
    NormalizedCacheObject,
    ApolloClient,
    ObservableSubscription,
} from '@apollo/client';
import { debounce, omit, pick } from 'lodash-es';
import { SupportTicketStatus } from '@apiTypes';
import type {
    SupportTicketEvent,
    CreateSupportTicketResult,
    MutationCreateSupportTicketArgs,
    MutationUpdateSupportTicketArgs,
    SupportTicket,
    SupportTicketItem,
    UpdateSupportTicketInput,
    QuerySupportTicketArgs,
    MutationDiscardTicketArgs,
} from '@apiTypes';
import assert from '../../../core/util/assert';
import type { SupportTicketType } from '../../types';
import { updateTicketMutation } from '../../queries/useUpdateTicket';
import { createTicketMutation } from '../../queries/useCreateTicket';
import type { ObservableVariable } from '../../../core/util/makeObservableVariable';
import makeObservableVariable from '../../../core/util/makeObservableVariable';
import watchPolledQuery from '../../../core/util/apollo/watchPolledQuery';
import { ticketQuery } from '../../queries/useTicket';
import { submitTicketQuery } from '../../queries/useSubmitTicket';
import { discardTicketQuery } from '../../queries/useDiscardTicket';
import transformTicketItemsToUpdateTicketItems from '../../util/transformTicketItemsToUpdateTicketItems';
import transformUnionTypes from '../../util/transformUnionTypes';
import stripTypenameField from '../../../core/util/stripTypenameField';
import notifyTicketCreationError, {
    ErrorType,
} from './notifyTicketCreationError';

const createBlankTicket = (type: SupportTicketType): SupportTicket => {
    return {
        created: '',
        id: -1,
        items: [] as SupportTicketItem[],
        status: SupportTicketStatus.Draft,
        type,
        updated: '',
        events: [] as SupportTicketEvent[],
        customer: { companyCode: '', id: -1 },
        language: '',
        companyCode: '',
        informationRequested: false,
        shipmentRequested: false,
        uploadPermissionToken: '',
    } as SupportTicket;
};

const getUpdateSupportTicketInput = (
    record: Partial<SupportTicket>
): UpdateSupportTicketInput => {
    const fromAddress = record.fromAddress
        ? stripTypenameField(record.fromAddress)
        : undefined;
    return pick({ ...record, fromAddress }, [
        'customerContactEmail',
        'customerContactName',
        'customerContactPhone',
        'customerReference',
        'description',
        'id',
        'language',
        'productClassId',
        'type',
        'fromAddress',
    ]) as UpdateSupportTicketInput;
};

export class TicketSync {
    syncPending: ObservableVariable<boolean>;

    isSubmitting: ObservableVariable<boolean>;

    isDiscarding: ObservableVariable<boolean>;

    ticketObservable: ObservableVariable<SupportTicket>;

    error: ObservableVariable<boolean>;

    creationPending = false;

    changedDuringCreation = false;

    // eslint-disable-next-line max-params
    constructor(
        private client: ApolloClient<NormalizedCacheObject>,
        type: SupportTicketType,
        private initialValue: Partial<SupportTicket>,
        initialId?: number
    ) {
        const initTicket = {
            ...createBlankTicket(type),
            id: initialId ? initialId : -1,
            ...initialValue,
        };

        this.ticketObservable = makeObservableVariable(initTicket, true);
        this.syncPending = makeObservableVariable(false, true);
        this.isSubmitting = makeObservableVariable(false, true);
        this.isDiscarding = makeObservableVariable(false, true);
        this.error = makeObservableVariable(false, true);
        if (initialId) {
            this.startPoll();
        }
    }

    changeTicket = (
        value: Partial<SupportTicket>,
        notSyncChanges?: boolean
    ) => {
        if (this.isDiscarding.currentValue || this.isSubmitting.currentValue) {
            return;
        }
        const newValue = {
            ...this.ticketObservable.currentValue,
            ...value,
        };
        this.ticketObservable.updateValue({
            ...newValue,
            items: transformUnionTypes(newValue.items),
            events: transformUnionTypes(newValue.events),
        });
        if (!notSyncChanges) {
            this.syncPending.updateValue(true);
            this.syncTicketDebounced(this.ticketObservable.currentValue)?.catch(
                ex => {
                    throw ex;
                }
            );
        }
    };

    private syncTicketDebounced = debounce(
        async (ticketValue: Partial<SupportTicket>) => {
            if (this.creationPending) {
                this.changedDuringCreation = true;
                return;
            }
            if (ticketValue.id === -1) {
                return this.createTicket(ticketValue);
            } else {
                return this.syncTicket(ticketValue);
            }
        },
        500
    );

    // eslint-disable-next-line max-statements
    private async createTicket(ticketUpdate: Partial<SupportTicket>) {
        this.creationPending = true;

        try {
            const result = await this.client.mutate<
                { createSupportTicket: CreateSupportTicketResult },
                MutationCreateSupportTicketArgs
            >({
                mutation: createTicketMutation,
                variables: {
                    record: omit(
                        getUpdateSupportTicketInput({
                            ...ticketUpdate,
                            ...this.initialValue,
                        }),
                        'id'
                    ),
                    items: ticketUpdate.items
                        ? transformTicketItemsToUpdateTicketItems(
                              ticketUpdate.items
                          )
                        : [],
                },
            });
            const ticketId = result.data?.createSupportTicket.id;
            assert(ticketId, 'wrong response');

            const ticket = await this.fetchTicket(ticketId);
            this.creationPending = false;
            if (this.changedDuringCreation) {
                this.changeTicket({
                    ...ticket.data.supportTicket,
                    ...pick(this.ticketObservable.currentValue, [
                        'items',
                        'description',
                    ]),
                    id: ticketId,
                });
            } else {
                this.syncPending.updateValue(false);
                this.changeTicket(
                    {
                        ...ticket.data.supportTicket,
                        id: ticketId,
                    },
                    true
                );
            }
            this.ignoreFirstPoll = true;
            this.startPoll();
        } catch (error) {
            notifyTicketCreationError(ErrorType.CreateTicket);
            this.creationPending = false;
            throw error;
        }
    }

    private fetchTicket = async (ticketId: number) => {
        return this.client.query<
            {
                supportTicket: SupportTicket;
            },
            QuerySupportTicketArgs
        >({
            query: ticketQuery,
            variables: {
                id: ticketId,
            },
            fetchPolicy: 'network-only',
        });
    };

    private async syncTicket(ticketUpdate: Partial<SupportTicket>) {
        try {
            await this.client.mutate<
                { updateTicket: boolean },
                MutationUpdateSupportTicketArgs
            >({
                mutation: updateTicketMutation,
                variables: {
                    updates: omit(
                        getUpdateSupportTicketInput({
                            ...ticketUpdate,
                        }),
                        'type'
                    ),
                    items: ticketUpdate.items
                        ? transformTicketItemsToUpdateTicketItems(
                              ticketUpdate.items
                          )
                        : [],
                },
            });
        } catch (ex) {
            notifyTicketCreationError(ErrorType.UpdateTicket);
            throw ex;
        } finally {
            this.syncPending.updateValue(false);
        }
    }

    private pollSubscriber: ObservableSubscription | undefined = undefined;

    private ignoreFirstPoll = false;

    private startPoll() {
        const { observable: queryObservable } = watchPolledQuery<
            {
                supportTicket: SupportTicket;
            },
            QuerySupportTicketArgs
        >(this.client, {
            query: ticketQuery,
            pollInterval: 15_000,
            variables: {
                id: this.ticketObservable.currentValue.id,
            },
            nextFetchPolicy: 'cache-first',
        });
        this.pollSubscriber = queryObservable.subscribe(
            value => {
                if (this.ignoreFirstPoll) {
                    this.ignoreFirstPoll = false;
                    return;
                }
                if (
                    !this.syncPending.currentValue &&
                    this.ticketObservable.currentValue.id !== -1
                ) {
                    this.changeTicket(value.data.supportTicket, true);
                }
            },
            () => {
                this.error.updateValue(true);
            }
        );
    }

    unsubscribe = () => {
        if (this.pollSubscriber) {
            this.pollSubscriber.unsubscribe();
        }
    };

    submitTicket = async () => {
        if (this.anyActionPending) {
            return false;
        }
        try {
            this.isSubmitting.updateValue(true);
            const result = await this.client.mutate<
                { submitTicket: boolean },
                MutationDiscardTicketArgs
            >({
                mutation: submitTicketQuery,
                variables: {
                    id: this.ticketObservable.currentValue.id,
                },
            });
            this.isSubmitting.updateValue(false);
            await this.fetchTicket(this.ticketObservable.currentValue.id);
            return !!result.data?.submitTicket;
        } catch (ex) {
            notifyTicketCreationError(ErrorType.SubmitTicket);
            throw ex;
        } finally {
            this.isSubmitting.updateValue(false);
        }
    };

    discardTicket = async () => {
        if (this.anyActionPending) {
            return false;
        }
        try {
            this.isDiscarding.updateValue(true);
            const result = await this.client.mutate<
                { discardTicket: boolean },
                MutationDiscardTicketArgs
            >({
                mutation: discardTicketQuery,
                variables: {
                    id: this.ticketObservable.currentValue.id,
                },
            });
            this.isDiscarding.updateValue(false);
            this.reset();
            return !!result.data?.discardTicket;
        } catch (ex) {
            notifyTicketCreationError(ErrorType.DiscardTicket);
            throw ex;
        } finally {
            this.isDiscarding.updateValue(false);
        }
    };

    reset = () => {
        this.unsubscribe();
        this.changeTicket(
            createBlankTicket(
                this.ticketObservable.currentValue.type as SupportTicketType
            ),
            true
        );
    };

    get anyActionPending() {
        return (
            this.isDiscarding.currentValue &&
            this.isSubmitting.currentValue &&
            this.syncPending
        );
    }
}

export default TicketSync;
