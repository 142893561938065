import { memo } from 'react';
import type { IconProps } from './types';
import BaseIcon from './BaseIcon';

const DocumentIcon = memo((props: IconProps) => {
    return (
        <BaseIcon viewBox="0 0 32 33" {...props} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M 28 9 V 28 C 28 30.2091 26.2091 32 24 32 H 12 V 30 H 24 C 25.1046 30 26 29.1046 26 28 V 9 H 22 C 20.3431 9 19 7.6569 19 6 V 2 H 8 C 6.8954 2 6 2.8954 6 4 V 22 H 4 V 4 C 4 1.7909 5.7909 0 8 0 H 19 L 28 9 Z"
                fill="currentColor"
            />
        </BaseIcon>
    );
});

export default memo(DocumentIcon);
