import { useState, useCallback, useEffect } from 'react';
import type { SupportTicketEventDocumentTypes } from '@apiTypes';
import type { FileDetails } from '@library';
import notEmpty from '../../core/util/notEmpty';
import sleep from '../../core/util/sleep';
import useUploadTicketDocument from './useUploadTicketDocument';
import { ticketAttachmentsQuery } from './useTicketAttachments';

const useUploadTicketsDocumentForm = (id: number) => {
    const [filesMap, setFilesMap] = useState(
        {} as Record<SupportTicketEventDocumentTypes, FileDetails[] | undefined>
    );
    const { uploadDocuments } = useUploadTicketDocument([
        {
            query: ticketAttachmentsQuery,
            variables: {
                id,
            },
        },
    ]);

    const [loading, setLoading] = useState(false);
    const [uploaded, setUploaded] = useState(false);

    const [triggerSend, setTriggerSend] = useState(false);

    const anyAttachments = Object.values(filesMap).some(
        files => files && files.length > 0
    );

    const filesIsNotReady = Object.values(filesMap)
        .flatMap(item => item)
        .filter(notEmpty)
        .some(file => !file.s3Key);

    const upload = useCallback(
        async (
            filesToSend: {
                id: number;
                documentType: SupportTicketEventDocumentTypes;
                attachments: string[];
            }[]
        ) => {
            let attempts = filesToSend.length;
            while (filesToSend.length > 0) {
                const files = filesToSend.pop();
                if (files) {
                    try {
                        await uploadDocuments(files);
                    } catch {
                        if (attempts > 0) {
                            attempts--;
                            filesToSend.unshift(files);
                        } else {
                            throw new Error();
                        }
                        await sleep(1000);
                    }
                }
            }
        },
        [uploadDocuments]
    );

    const uploadAllDocuments = useCallback(async () => {
        if (loading) {
            return;
        }
        if (filesIsNotReady) {
            setTriggerSend(true);
            return;
        }
        setTriggerSend(false);
        setLoading(true);

        const filesToSend = Object.entries(filesMap)
            .filter(async ([, value]) => {
                return value && value.length === 0;
            })
            .map(([key, value]) => {
                return {
                    id,
                    documentType: key as SupportTicketEventDocumentTypes,
                    attachments: value!.map(item => item.s3Key!),
                };
            });

        await upload(filesToSend);
        setFilesMap(
            {} as Record<
                SupportTicketEventDocumentTypes,
                FileDetails[] | undefined
            >
        );
        setUploaded(true);

        setLoading(false);
    }, [filesIsNotReady, filesMap, id, loading, upload]);

    useEffect(() => {
        if (triggerSend && !filesIsNotReady) {
            setTimeout(() => {
                uploadAllDocuments().catch(ex => {
                    throw ex;
                });
            }, 2000);
        }
    }, [filesIsNotReady, triggerSend, uploadAllDocuments]);

    const processing = loading || triggerSend;
    return {
        filesMap,
        setFilesMap,
        uploadAllDocuments,
        loading,
        uploaded,
        anyAttachments,
        filesIsNotReady,
        processing,
        setUploaded,
    };
};
export default useUploadTicketsDocumentForm;
