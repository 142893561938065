import { useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { notify } from '@library';
import { useCartFieldsContext } from '../../cart/contexts/CartFields/CartFieldsContext';
import useDebouncedCallback from '../../core/hooks/useDebouncedCallback';
import captureException from '../../core/util/captureException';
import { CheckoutErrors } from '../context/CheckoutProvider';
import type { CartFields } from '../types';

/**
 *
 * @param onError Needs to be useCallback
 */
const useSaveCartFields = (onError?: () => void) => {
    const intl = useIntl();
    const { setUpdatesPending, setCartFieldsHasError, saveCartFields } =
        useCartFieldsContext();
    const isUpdatePending = useRef(false);
    const [isFieldUpdating, setIsFieldUpdating] = useState(false);

    const performSaveCartFields = useDebouncedCallback(
        (fields: Partial<CartFields>) => {
            saveCartFields(fields)
                .catch(error => {
                    setCartFieldsHasError(true);
                    for (const field of Object.keys(fields)) {
                        notify({
                            type: 'error',
                            title: (
                                <FormattedMessage
                                    id="Failed to update {fieldName}"
                                    values={{
                                        fieldName: intl.formatMessage({
                                            id: `${field} field`,
                                        }),
                                    }}
                                />
                            ),
                            message: (
                                <FormattedMessage id="Your changes were not submitted to the server. Please check your input and try again." />
                            ),

                            id: `${
                                CheckoutErrors.FAILED_TO_UPDATE_
                            }${field.toUpperCase()}`,
                        });
                    }

                    onError?.();

                    captureException(error);
                })
                .finally(() => {
                    isUpdatePending.current = false;
                    setIsFieldUpdating(false);
                });
        },
        500
    );

    const setCartFields = useCallback(
        (fields: Partial<CartFields>) => {
            isUpdatePending.current = true;
            setCartFieldsHasError(false);
            setIsFieldUpdating(true);
            setUpdatesPending(true);
            performSaveCartFields(fields);
        },
        [performSaveCartFields, setCartFieldsHasError, setUpdatesPending]
    );

    return {
        isUpdatePending,
        isFieldUpdating,
        saveCartFields: setCartFields,
    };
};

export default useSaveCartFields;
