import { useState } from 'react';
import type { SelectDropdownOptions } from '../components/input/Select/SelectDropdown';

export interface TextOnlySelectDropdownOptions extends SelectDropdownOptions {
    label: string;
}

const useSearchableSelectOptions = (
    options: SelectDropdownOptions[],
    isActive = true
) => {
    const [search, setSearch] = useState('');
    if (!isActive || search === '') {
        return { search, setSearch, filteredOptions: options };
    }

    const filteredOptions = (
        options.filter(
            item => typeof item.label === 'string'
        ) as TextOnlySelectDropdownOptions[]
    ).filter(({ label }) => {
        const searchTermParts = search.split(/[ \-.,]/g);

        return searchTermParts.every(
            part =>
                label
                    .trim()
                    .replace(/[ \-.,]/g, '')
                    .toLowerCase()
                    .indexOf(part.trim().toLowerCase()) !== -1
        );
    });
    return { search, setSearch, filteredOptions };
};

export default useSearchableSelectOptions;
