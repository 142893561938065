import { memo, createContext, useContext, useMemo, useRef } from 'react';
import type { ReactNode } from 'react';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import assert from '../../../core/util/assert';
import type { SupportTicketType } from '../../types';
import useStoreAndLanguageParams from '../../../core/hooks/useStoreAndLanguageParams';
import useEffectWithoutMount from '../../../core/util/useEffectWithoutMount';
import useObservableVariableState from '../../../core/hooks/useObservableVariableState';
import { useCurrentUserLoggedInOnly } from '../../../core/hooks/useCurrentUser';
import notEmpty from '../../../core/util/notEmpty';
import type { Customer, User } from '../../../user/types';
import { useProductClass } from '../../queries/useProductClasses';
import { useCurrentCustomerLoggedInOnly } from '../../../core/hooks/useCurrentCustomer';
import { TicketSync } from './TicketSync';

interface TicketCreationContextValue {
    ticketSync: TicketSync;
}

const TicketCreationContext = createContext<
    Partial<TicketCreationContextValue>
>({});

export const getDefaultContactInfo = (
    currentUser: User,
    currentCustomer: Customer
) => {
    const name = [currentUser.firstName, currentUser.lastName]
        .filter(notEmpty)
        .join(' ');
    return {
        customerContactEmail: currentUser.email,
        customerContactName: name,
        customerContactPhone: currentCustomer.phone,
    };
};

export const TicketCreationProvider = memo(
    ({
        children,
        type,
        id,
    }: {
        children: ReactNode;
        type: SupportTicketType;
        id?: number;
    }) => {
        const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
        const { store, language } = useStoreAndLanguageParams();

        const prevSync = useRef<TicketSync>();

        const currentUser = useCurrentUserLoggedInOnly();
        const currentCustomer = useCurrentCustomerLoggedInOnly();

        const ticketSync = useMemo(() => {
            if (
                prevSync.current &&
                prevSync.current.ticketObservable.currentValue.id === id
            ) {
                return prevSync.current;
            }
            if (prevSync.current) {
                prevSync.current.unsubscribe();
            }
            prevSync.current = new TicketSync(
                client,
                type,
                {
                    ...getDefaultContactInfo(currentUser, currentCustomer),
                    language,
                },
                id
            );
            return prevSync.current;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [client, type, language, store, id]);

        useEffectWithoutMount(() => {
            return () => {
                if (prevSync.current) {
                    prevSync.current.unsubscribe();
                }
            };
        }, []);

        const value: TicketCreationContextValue = useMemo(
            () => ({
                ticketSync,
            }),
            [ticketSync]
        );

        return (
            <TicketCreationContext.Provider value={value}>
                {children}
            </TicketCreationContext.Provider>
        );
    }
);

export const useCreationTicketContext = () => {
    const context = useContext(TicketCreationContext);

    assert(
        context.ticketSync,
        'Please check that TicketContext is present in the tree'
    );

    return context as TicketCreationContextValue;
};

export const useTicketInCreation = () => {
    const { ticketSync } = useCreationTicketContext();
    const [ticket] = useObservableVariableState(ticketSync.ticketObservable);
    const [error] = useObservableVariableState(ticketSync.error);
    const isNotCreated = !ticket.created;
    const loading = ticket.id !== -1 && isNotCreated;

    const { productClassId } = ticket;

    const currentProductClass = useProductClass(ticket.type, productClassId);

    return {
        ticket,
        loading,
        reset: ticketSync.reset,
        isNotCreated,
        productClassId,
        currentProductClass,
        error,
    };
};

export const useUpdateTicketInCreation = () => {
    const { ticketSync } = useCreationTicketContext();
    const [syncPending] = useObservableVariableState(ticketSync.syncPending);
    return {
        updateTicket: ticketSync.changeTicket,
        syncPending,
    };
};

export const useSubmitTicketInCreation = () => {
    const { ticketSync } = useCreationTicketContext();
    const [isSubmitting] = useObservableVariableState(ticketSync.isSubmitting);
    return {
        submitTicket: ticketSync.submitTicket,
        isSubmitting,
    };
};

export const useDiscardTicketInCreation = () => {
    const { ticketSync } = useCreationTicketContext();
    const [isDiscarding] = useObservableVariableState(ticketSync.isDiscarding);
    return {
        discardTicket: ticketSync.discardTicket,
        isDiscarding,
    };
};
