import { memo } from 'react';
import type { NumberMaskInputProps } from './NumberMaskInput';
import NumberMaskInput from './NumberMaskInput';

/**
 *
 */
type PercentageFieldProps = Omit<
    NumberMaskInputProps,
    'numberFormatProps' | 'theme'
>;
/**
 * The percentage field component for editing percentage.
 */
const PercentageField = ({ onChange, ...props }: PercentageFieldProps) => {
    return (
        <NumberMaskInput
            {...props}
            numberFormatProps={{
                suffix: '%',
                onValueChange: ({ floatValue }) => {
                    onChange?.(floatValue?.toString() || '');
                },
                isAllowed: values => {
                    const { floatValue } = values;
                    return floatValue
                        ? floatValue >= 0 && floatValue <= 100
                        : true;
                },
            }}
        />
    );
};

export default memo(PercentageField);
