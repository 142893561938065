import { clsx } from 'clsx';
import { memo, useState } from 'react';
import type { ReactNode } from 'react';

import useEffectWithoutMount from '../../../core/util/useEffectWithoutMount';
import { Placeholder } from '../content';
import styles from './Image.module.scss';

export interface ImageProps {
    /**
     * The image src.
     */
    src?: string;
    /**
     * The image src 2x rendition.
     */
    src2x?: string;
    /**
     * Default content to show when the image is not presented.
     */
    placeholder?: ReactNode;
    /**
     * The Html alt attribute.
     */
    alt: string;
    /**
     * Disable the lazy loading of image
     */
    disableLazy?: boolean;
    /**
     * The size of image. Width = height = size if aspectRation is not provided.
     */
    size?: string;
    /**
     * The aspect ratio of image. Width = size, heigh = aspectRatio * width
     */
    aspectRatio?: number;
}

const parseSize = /(\d*)([a-z]*)/;
const getImageSize = ({
    size,
    aspectRatio,
}: {
    /**
     *
     */
    size?: string;
    /**
     *
     */
    aspectRatio?: number;
}) => {
    if (size) {
        if (!aspectRatio) {
            return {
                width: size,
                height: size,
            };
        }
        const result = size.match(parseSize);
        if (result?.length && result.length > 2) {
            const sizeInt = parseInt(result[1]);
            const [, , dimension] = result;
            return {
                width: size,
                height: `${sizeInt * aspectRatio}${dimension}`,
            };
        }
    }

    return {
        width: '100%',
        height: '100%',
    };
};

/**
 * The Image component for showing responsive images. Image takes whole width and height of the parent container.
 * Supports fallback placeholder.
 */
const Image = ({
    src,
    src2x,
    placeholder,
    alt,
    disableLazy,
    size,
    aspectRatio,
    ...ariaProps
}: ImageProps) => {
    const [showPlaceholder, setShowPlaceholder] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const loadFallbackImageOnError = () => {
        setShowPlaceholder(true);
        return placeholder;
    };

    const onLoad = () => {
        setLoading(false);
    };

    const srcSet = src2x ? `${src} 1x, ${src2x} 2x` : undefined;

    useEffectWithoutMount(() => {
        if (showPlaceholder) {
            setShowPlaceholder(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src, src2x]);

    const sizeStyles = getImageSize({ size, aspectRatio });

    if (showPlaceholder || !src) {
        return (
            <div style={sizeStyles} {...ariaProps}>
                {placeholder}
            </div>
        );
    }

    return (
        <div className={styles.imageContainer} style={sizeStyles}>
            <img
                onError={loadFallbackImageOnError}
                onLoad={onLoad}
                className={clsx(styles.responsiveImage)}
                style={sizeStyles}
                src={src}
                alt={alt}
                srcSet={srcSet}
                loading={disableLazy ? 'eager' : 'lazy'}
                {...ariaProps}
            />
            {isLoading && (
                <div className={styles.skeletonContainer}>
                    <Placeholder width="100%" height="100%" />
                </div>
            )}
        </div>
    );
};

export default memo(Image);
