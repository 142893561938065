import { FormattedMessage } from 'react-intl';
import { notify } from '@library';
export enum ErrorType {
    CreateTicket = 'CreateTicket',
    UpdateTicket = 'UpdateTicket',
    SubmitTicket = 'SubmitTicket',
    GetTicket = 'GetTicket',
    DiscardTicket = 'DiscardTicket',
}

const notifyTicketCreationError = (type: ErrorType) => {
    return notify({
        type: 'error',
        title: (
            <FormattedMessage id="an error occurred. please try again later" />
        ),
        id: type,
    });
};

export default notifyTicketCreationError;
