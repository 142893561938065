import { memo } from 'react';
import type { IconProps } from './types';

const StickerIcon = ({ iconLabel, color }: IconProps) => {
    return (
        <svg
            width="50"
            height="54"
            viewBox="0 0 50 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-icon={iconLabel}
            color={color}
        >
            <path
                d="M43.3196 46.6525L41.0112 44.8502C40.8739 44.7429 40.6813 44.7429 40.5441 44.8502L37.856 46.9489C37.7187 47.0561 37.526 47.0561 37.3887 46.9489L34.7005 44.8501C34.5632 44.7429 34.3707 44.7429 34.2333 44.8501L31.545 46.9489C31.4078 47.0561 31.2152 47.0561 31.078 46.9489L28.3902 44.8502C28.2528 44.7429 28.0602 44.7429 27.9229 44.8502L25.235 46.9489C25.0977 47.0561 24.9051 47.0561 24.7679 46.9489L22.0796 44.8501C21.9423 44.7429 21.7498 44.7429 21.6125 44.8501L18.9241 46.9489C18.7868 47.0561 18.5942 47.0561 18.457 46.9489L15.7685 44.8501C15.6312 44.7429 15.4386 44.7429 15.3013 44.8501L12.6129 46.9489C12.4756 47.0561 12.283 47.0561 12.1458 46.9489L9.45685 44.8501C9.31965 44.7429 9.12711 44.7429 8.98991 44.8501L6.68043 46.6527C6.43132 46.8473 6.06738 46.6697 6.06738 46.3536V8.09526C6.06738 7.56939 6.49367 7.1431 7.01955 7.1431H42.9806C43.5065 7.1431 43.9328 7.56939 43.9328 8.09526V46.3534C43.9328 46.6696 43.5688 46.8472 43.3196 46.6525Z"
                fill="white"
            />
            <path d="M13.5879 17.8999H35.6879" stroke="#647FB1" />
            <path d="M13.5879 20.9331H35.6879" stroke="#647FB1" />
            <path d="M13.5879 23.9668H35.6879" stroke="#647FB1" />
            <path d="M13.5879 27H35.6879" stroke="#647FB1" />
            <path d="M13.5879 30.0332H35.6879" stroke="#647FB1" />
            <path d="M13.5879 33.0664H27.4546" stroke="#647FB1" />
            <path
                d="M42.9801 7.1429H40.6982C41.2241 7.1429 41.6504 7.56919 41.6504 8.09506V45.3494L43.3191 46.6523C43.5683 46.8469 43.9322 46.6693 43.9322 46.3531V8.09506C43.9322 7.56919 43.5059 7.1429 42.9801 7.1429Z"
                fill="#D8DFEB"
            />
            <path
                d="M43.9328 39.5133V8.09533C43.9328 7.56946 43.5065 7.14317 42.9806 7.14317H7.01955C6.49367 7.14317 6.06738 7.56946 6.06738 8.09533V46.3535C6.06738 46.6697 6.43133 46.8472 6.68052 46.6528L8.98991 44.8501C9.12711 44.7429 9.31965 44.7429 9.45694 44.8501L12.1458 46.949C12.2831 47.0561 12.4756 47.0561 12.6129 46.949L15.3013 44.8501C15.4386 44.7429 15.6313 44.7429 15.7685 44.8501L18.457 46.949C18.5942 47.0561 18.7868 47.0561 18.9242 46.949L21.6126 44.8501C21.7498 44.7429 21.9423 44.7429 22.0796 44.8501L24.7679 46.949C24.9052 47.0561 25.0978 47.0561 25.235 46.949L27.9229 44.8501C28.0602 44.7429 28.2529 44.7429 28.3902 44.8501L31.078 46.949C31.2152 47.0561 31.4078 47.0561 31.5451 46.949L34.2334 44.8501C34.3707 44.7429 34.5632 44.7429 34.7005 44.8501L37.3888 46.949C37.5261 47.0561 37.7187 47.0561 37.856 46.949L40.544 44.8501C40.6814 44.7429 40.8739 44.7429 41.0112 44.8501L43.3196 46.6526C43.5688 46.8471 43.9328 46.6696 43.9328 46.3534V42.2611"
                stroke="#647FB1"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M45.8185 17.1153L45.9518 16.3658C45.9673 16.2787 46.0331 16.2092 46.1192 16.1888L47.1179 15.9529C47.204 15.9326 47.2696 15.863 47.2852 15.776L47.4652 14.7649C47.4807 14.6779 47.5464 14.6083 47.6325 14.588L48.3738 14.4127C48.5454 14.3721 48.6033 14.1569 48.4751 14.0358L34.7227 1.06091C34.5945 0.939814 34.383 1.00955 34.352 1.18323L34.2187 1.93281C34.2033 2.01989 34.1375 2.08934 34.0515 2.10972L33.0527 2.34565C32.9666 2.36592 32.9009 2.43547 32.8854 2.52255L32.7053 3.53355C32.6898 3.62063 32.6241 3.69018 32.5381 3.71045L31.7969 3.88584C31.6252 3.92649 31.5674 4.14148 31.6955 4.26267L45.4479 17.2375C45.576 17.3587 45.7876 17.289 45.8185 17.1153Z"
                fill="#D8DFEB"
            />
            <path
                d="M37.1312 9.39114L45.4479 17.2375C45.5761 17.3587 45.7876 17.2889 45.8186 17.1152L45.9519 16.3657C45.9673 16.2787 46.0331 16.2091 46.1191 16.1887L47.1179 15.9529C47.204 15.9325 47.2697 15.8631 47.2852 15.776L47.4652 14.7649C47.4808 14.6778 47.5465 14.6084 47.6325 14.588L48.3737 14.4126C48.5454 14.372 48.6032 14.157 48.4751 14.0358L34.7226 1.06094C34.5945 0.939748 34.383 1.00958 34.3521 1.18326L34.2188 1.93265C34.2033 2.01982 34.1375 2.08928 34.0514 2.10965L33.0526 2.34558C32.9666 2.36586 32.9009 2.4355 32.8853 2.52248L32.7054 3.53358C32.6898 3.62066 32.6242 3.69011 32.5381 3.71048L31.7968 3.88577C31.6252 3.92642 31.5673 4.14151 31.6955 4.2627L35.1317 7.50463"
                stroke="#647FB1"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M48.0523 38.8075L47.3028 38.6741C47.2157 38.6587 47.1462 38.5928 47.1258 38.5068L46.8899 37.508C46.8696 37.422 46.8001 37.3563 46.713 37.3408L45.7019 37.1608C45.6149 37.1452 45.5454 37.0796 45.525 36.9934L45.3497 36.2522C45.3091 36.0806 45.094 36.0227 44.9728 36.1509L31.9979 49.9033C31.8768 50.0315 31.9466 50.243 32.1202 50.2739L32.8698 50.4073C32.9569 50.4227 33.0264 50.4885 33.0467 50.5745L33.2827 51.5733C33.3029 51.6594 33.3725 51.7251 33.4596 51.7406L34.4706 51.9206C34.5576 51.9362 34.6272 52.0018 34.6475 52.0879L34.8229 52.8291C34.8635 53.0008 35.0785 53.0586 35.1997 52.9305L48.1745 39.178C48.2957 39.0499 48.226 38.8383 48.0523 38.8075Z"
                fill="#D8DFEB"
            />
            <path
                d="M40.3282 47.4947L48.1746 39.1781C48.2957 39.0499 48.2259 38.8384 48.0522 38.8074L47.3027 38.6741C47.2157 38.6587 47.1461 38.5929 47.1257 38.5069L46.8899 37.5081C46.8695 37.422 46.8001 37.3563 46.713 37.3407L45.7019 37.1608C45.6148 37.1452 45.5454 37.0795 45.525 36.9935L45.3496 36.2522C45.309 36.0805 45.094 36.0227 44.9728 36.1509L31.9979 49.9033C31.8768 50.0314 31.9466 50.243 32.1203 50.2739L32.8697 50.4072C32.9568 50.4227 33.0263 50.4885 33.0467 50.5745L33.2826 51.5733C33.3029 51.6594 33.3725 51.7251 33.4595 51.7407L34.4706 51.9206C34.5577 51.9361 34.6271 52.0018 34.6475 52.0879L34.8228 52.8292C34.8634 53.0008 35.0785 53.0587 35.1997 52.9305L38.4416 49.4943"
                stroke="#647FB1"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.18022 17.1153L4.04691 16.3658C4.03146 16.2787 3.96561 16.2092 3.87958 16.1888L2.8808 15.9529C2.79476 15.9326 2.729 15.863 2.71356 15.776L2.53344 14.7649C2.51799 14.6779 2.45233 14.6083 2.3662 14.588L1.62496 14.4127C1.45327 14.3721 1.39547 14.1569 1.52367 14.0358L15.276 1.06091C15.4042 0.939814 15.6157 1.00955 15.6467 1.18323L15.7799 1.93281C15.7955 2.01989 15.8611 2.08934 15.9473 2.10972L16.946 2.34565C17.0322 2.36592 17.0978 2.43547 17.1134 2.52255L17.2934 3.53355C17.3089 3.62063 17.3746 3.69018 17.4606 3.71045L18.2019 3.88584C18.3736 3.92649 18.4314 4.14148 18.3032 4.26267L4.5508 17.2375C4.42269 17.3587 4.21111 17.289 4.18022 17.1153Z"
                fill="#D8DFEB"
            />
            <path
                d="M10.963 11.1878L18.3032 4.2627C18.4314 4.14151 18.3736 3.92642 18.2019 3.88587L17.4605 3.71048C17.3746 3.69011 17.3089 3.62066 17.2934 3.53358L17.1134 2.52248C17.0978 2.4355 17.0321 2.36595 16.946 2.34558L15.9473 2.10965C15.8611 2.08937 15.7955 2.01982 15.7799 1.93265L15.6467 1.18326C15.6157 1.00958 15.4042 0.939748 15.276 1.06094L1.52367 14.0358C1.39547 14.157 1.45327 14.372 1.62496 14.4126L2.3663 14.588C2.45224 14.6084 2.51799 14.6778 2.53344 14.7649L2.71356 15.776C2.729 15.8631 2.79476 15.9325 2.8808 15.9529L3.87958 16.1887C3.96561 16.2091 4.03146 16.2787 4.04691 16.3657L4.18022 17.1152C4.21111 17.2889 4.42269 17.3587 4.5508 17.2375L8.96349 13.0742"
                stroke="#647FB1"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.8804 50.2739L17.1309 50.4072C17.0439 50.4226 16.9743 50.4885 16.9539 50.5745L16.718 51.5733C16.6977 51.6593 16.6282 51.7251 16.5411 51.7405L15.53 51.9207C15.443 51.9361 15.3735 52.0018 15.3531 52.0879L15.1778 52.8291C15.1373 53.0008 14.9221 53.0586 14.8009 52.9304L1.82604 39.1781C1.70495 39.0499 1.77469 38.8384 1.94837 38.8074L2.69795 38.6742C2.78503 38.6586 2.85448 38.593 2.87485 38.5068L3.11078 37.5081C3.13106 37.4219 3.20061 37.3563 3.28769 37.3407L4.29869 37.1607C4.38577 37.1452 4.45531 37.0795 4.47559 36.9935L4.65098 36.2522C4.69162 36.0805 4.90662 36.0227 5.0278 36.1509L18.0026 49.9033C18.1238 50.0314 18.0541 50.243 17.8804 50.2739Z"
                fill="#D8DFEB"
            />
            <path
                d="M11.953 43.4911L5.02783 36.1509C4.90664 36.0227 4.69156 36.0805 4.651 36.2522L4.47562 36.9936C4.45525 37.0795 4.38579 37.1453 4.29872 37.1607L3.28762 37.3407C3.20064 37.3563 3.13109 37.422 3.11072 37.5081L2.87479 38.5068C2.85451 38.593 2.78496 38.6586 2.69779 38.6742L1.9484 38.8074C1.77472 38.8384 1.70488 39.0499 1.82607 39.1781L14.8009 52.9304C14.9221 53.0586 15.1371 53.0008 15.1777 52.8291L15.3531 52.0878C15.3735 52.0019 15.443 51.9361 15.53 51.9207L16.5411 51.7405C16.6282 51.7251 16.6977 51.6593 16.718 51.5733L16.9539 50.5745C16.9742 50.4885 17.0439 50.4226 17.1309 50.4072L17.8804 50.2739C18.054 50.243 18.1239 50.0314 18.0027 49.9033L13.8394 45.4906"
                stroke="#647FB1"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default memo(StickerIcon);
