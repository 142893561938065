import { clsx } from 'clsx';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import type { SupportTicketStatus } from '@apiTypes';
import { HStack, Text } from '@library';
import styles from './TicketStatusView.module.scss';

const TicketStatusView = memo(({ status }: { status: SupportTicketStatus }) => {
    return (
        <HStack align="center" gap="mini">
            <div className={clsx(styles.statusIcon, styles[status])} />
            <Text>
                <FormattedMessage id={`Tickets Common Status_${status}`} />
            </Text>
        </HStack>
    );
});

export default TicketStatusView;
