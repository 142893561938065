import { memo } from 'react';
import type { IconProps } from './types';

const ChevronRightIcon = ({ iconLabel, color }: IconProps) => (
    <svg
        width="12"
        height="7"
        viewBox="0 0 15 9"
        fill="none"
        data-icon={iconLabel}
        color={color}
    >
        <path
            d="M1.0001 7.15234L7.15045 1.00189L13.301 7.15234"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default memo(ChevronRightIcon);
