import { default as Render } from 'qrcode-svg';
import { memo, useMemo } from 'react';
const QRCode = ({ link, className }: { link: string; className: string }) => {
    const svg = useMemo(() => {
        return new Render({
            content: link,
            padding: 0,
            width: 80,
            height: 80,
            ecl: 'L',
            background: 'transparent',
        }).svg({
            container: 'svg',
        });
    }, [link]);
    return (
        <div
            className={className}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: svg,
            }}
        />
    );
};

export default memo(QRCode);
