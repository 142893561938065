import { clsx } from 'clsx';
import type { HTMLProps, MutableRefObject, ReactNode } from 'react';
import { memo, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import type { FloatingContext } from '@floating-ui/react';
import { Text } from '../../typography';
import FadeScroll from '../../../../core/components/layout/FadeScroll/FadeScroll';
import SearchField from '../text/SearchField';
import styles from './SelectDropdown.module.scss';

interface SelectDropdownProps {
    /**
     * Show/hide the dropdown.
     */
    isOpen?: boolean;
    /**
     * Show/hide search bar for options in the dropdown.
     */
    searchable?: boolean;
    /**
     * Current state of search bar.
     */
    searchText?: string;
    setSearchText(newSearchValue: string): void;
    /**
     * Total number of options.
     */
    optionsCount: number;
    /**
     * Content to show on top of all options (below
     * search bar if select is searchable).
     */
    header?: ReactNode;
    children: ReactNode;
    /**
     * Floating context of parent element
     */
    context: FloatingContext<HTMLDivElement>;
    /**
     * Generates interaction props for the dropdown.
     * This is provided by floating-ui
     */
    getFloatingProps(
        userProps?: HTMLProps<HTMLElement> | undefined
    ): Record<string, unknown>;
    setPointer(pointer: boolean): void;
    /**
     * Index of currently focused/selected item.
     */
    activeIndex: number | null;
    handleSelect(): void;
    setOpen(isOpen: boolean): void;
    setActiveIndex(index: number): void;
}

const SelectDropdown = forwardRef<(HTMLElement | null)[], SelectDropdownProps>(
    (
        {
            searchable,
            searchText,
            setSearchText,
            optionsCount,
            header,
            children,
            context,
            getFloatingProps,
            setPointer,
            activeIndex,
            handleSelect,
            setOpen,
            setActiveIndex,
        },
        ref
    ) => {
        return (
            <>
                {searchable && (
                    <div
                        role="group"
                        className={clsx(
                            styles.header,
                            styles.contentHorizontalPadding,
                            styles.searchField
                        )}
                        onKeyDown={e => {
                            // Stop these keys when typed in the search from
                            // triggering select events in parent.
                            if (e.key === ' ' || e.key === 'Enter') {
                                e.stopPropagation();
                            }
                        }}
                        onKeyUp={e => {
                            // Stop these keys when typed in the search from
                            // triggering select events in parent.
                            if (e.key === ' ' || e.key === 'Enter') {
                                e.stopPropagation();
                            }

                            if (e.key === 'ArrowDown') {
                                setActiveIndex(1);
                            }
                        }}
                    >
                        <SearchField
                            small
                            ref={node => {
                                if (!ref) {
                                    return;
                                }

                                (
                                    ref as MutableRefObject<
                                        (HTMLElement | null)[]
                                    >
                                ).current[0] = node as HTMLElement;
                            }}
                            value={searchText}
                            onChange={updatedValue => {
                                setSearchText(updatedValue);
                            }}
                        />
                        <hr className={styles.searchDivider} />
                    </div>
                )}
                <FadeScroll
                    mode="vertical"
                    className={styles.dropdownMenu}
                    largeFade
                    {...getFloatingProps({
                        onKeyDown(e) {
                            setPointer(false);

                            if (e.key === 'Enter' && activeIndex !== null) {
                                handleSelect();
                            }

                            if (e.key === ' ') {
                                e.preventDefault();
                            }

                            if (e.key === 'Tab' && e.shiftKey) {
                                setOpen(false);
                            }
                        },
                        onKeyUp(e) {
                            if (
                                e.key === ' ' &&
                                !context.dataRef.current.typing
                            ) {
                                handleSelect();
                            }
                        },
                        onPointerMove() {
                            setPointer(true);
                        },
                    })}
                    tabIndex={0}
                >
                    {(header || searchText) && (
                        <>
                            <div
                                role="group"
                                className={clsx(
                                    styles.header,
                                    styles.contentHorizontalPadding
                                )}
                            >
                                {searchText && (
                                    <div className={styles.searchResultsInfo}>
                                        <Text secondary>
                                            <FormattedMessage
                                                id="{resultsCount} results"
                                                values={{
                                                    resultsCount: optionsCount,
                                                }}
                                            />
                                        </Text>
                                    </div>
                                )}
                                {header}
                            </div>
                        </>
                    )}
                    {children}
                </FadeScroll>
            </>
        );
    }
);

export default memo(SelectDropdown);
