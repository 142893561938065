import { memo, Children } from 'react';
import type { ReactNode } from 'react';
import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({
    children,
    separator,
}: {
    children: ReactNode;
    separator: ReactNode;
}) => {
    return (
        <nav>
            <ol className={styles.orderedList}>
                {Children.map(children, (child, index) =>
                    !child ? null : (
                        <>
                            <li className={styles.listItem}>{child}</li>
                            {index + 1 !== Children.count(children) && (
                                <li className={styles.separator}>
                                    {separator}
                                </li>
                            )}
                        </>
                    )
                )}
            </ol>
        </nav>
    );
};

export default memo(Breadcrumbs);
