import { gql, useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useContext, useState } from 'react';
import type {
    CustomDiscountInput,
    MutationSaveCustomerPreorderPricingArgs,
} from '@apiTypes';
import useCurrentUser from '../../core/hooks/useCurrentUser';
import useImmutableCallback from '../../core/hooks/useImmutableCallback';
import notEmpty from '../../core/util/notEmpty';
import PreorderModeContext from '../../preorder/PreorderModeContext';
import { customerPreorderPricingQuery } from './useCustomerPreorderPricing';
import usePriceCache from './usePriceCache';

const saveCustomerPreorderPricingMutation = gql`
    mutation saveCustomerPreorderPricing(
        $customerNumber: ID!
        $preorderSeasonId: ID!
        $values: CustomerPreorderPricingValuesInput!
    ) {
        saveCustomerPreorderPricing(
            customerNumber: $customerNumber
            preorderSeasonId: $preorderSeasonId
            values: $values
        )
    }
`;

const useSaveCustomerPreorderPricing = () => {
    const { selectedPreorderSeason } = useContext(PreorderModeContext);
    const currentUser = useCurrentUser();
    const customerNumber = currentUser?.customerNumber;
    const [isUpdating, setIsUpdating] = useState(false);

    const [mutation, options] =
        useMutation<MutationSaveCustomerPreorderPricingArgs>(
            saveCustomerPreorderPricingMutation
        );

    const { resetPrices } = usePriceCache();

    const saveCustomerPreorderPricing = useImmutableCallback(
        async (values: CustomDiscountInput[]) => {
            try {
                setIsUpdating(true);
                await mutation({
                    variables: {
                        values: {
                            preorderDiscounts: values,
                        },
                        preorderSeasonId: selectedPreorderSeason?.id,
                        customerNumber,
                    },
                    refetchQueries: [
                        getOperationName(customerPreorderPricingQuery),
                    ].filter(notEmpty),
                    awaitRefetchQueries: true,
                });
                await resetPrices(true);
            } finally {
                setIsUpdating(false);
            }
        }
    );
    return { saveCustomerPreorderPricing, ...options, loading: isUpdating };
};

export default useSaveCustomerPreorderPricing;
