import type { Uppy, UppyOptions } from '@uppy/core';
import { memo, useContext, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../buttons';
import { Text } from '../../typography';
import { LayoutContext } from '../../../contexts';
import { UploadIcon } from '../../icons';
import { HStack } from '../../layout';
import styles from './FileInput.module.scss';

const FileInput = ({
    uppy,
    name = 'files[]',
    id = 'FileInput',
}: {
    /**
        The uppy object
     */
    uppy: React.MutableRefObject<Uppy>;
    /**
     * The name of input
     */
    name?: string;
    /**
     * The id of input
     */
    id?: string;
}) => {
    const hiddenInputStyle = {
        width: '0.1px',
        height: '0.1px',
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1,
    } as React.CSSProperties;

    const { opts } = uppy.current as unknown as {
        /**
         *
         */
        opts: UppyOptions;
    };

    const inputRef = useRef<HTMLInputElement>(null);
    const { isInMobileView } = useContext(LayoutContext);
    return (
        <div>
            <input
                className="uppy-FileInput-input"
                style={hiddenInputStyle}
                type="file"
                name={name}
                onChange={event => {
                    if (event.target.files) {
                        const files = Array.from(event.target.files);

                        // We clear the input after a file is selected, because otherwise
                        // change event is not fired in Chrome and Safari when a file
                        // with the same name is selected.
                        // ___Why not use value="" on <input/> instead?
                        //    Because if we use that method of clearing the input,
                        //    Chrome will not trigger change if we drop the same file twice (Issue #768).
                        (
                            event.target as {
                                // eslint-disable-next-line jsdoc/require-jsdoc
                                value: string | null;
                            }
                        ).value = null;

                        const descriptors = files.map(file => ({
                            source: id,
                            name: file.name,
                            type: file.type,
                            data: file,
                        }));

                        try {
                            uppy.current.addFiles(descriptors);
                        } catch (err) {
                            uppy.current.log(err as string);
                        }
                    }
                }}
                multiple={opts.restrictions?.maxNumberOfFiles !== 1}
                accept={opts.restrictions?.allowedFileTypes?.join(',')}
                ref={inputRef}
            />

            <Button
                text
                onClick={() => {
                    inputRef.current?.click();
                }}
                inline
            >
                <HStack gap="mini" align="center">
                    {isInMobileView && (
                        <div className={styles.icon}>
                            <UploadIcon />
                        </div>
                    )}

                    <Text bold={isInMobileView}>
                        <FormattedMessage id="Library FileField ChooseFiles" />
                    </Text>
                </HStack>
            </Button>
        </div>
    );
};

export default memo(FileInput);
