import type {
    SupportTicketEvent,
    SupportTicketEventAttachmentItem,
    SupportTicketEventDocumentTypes,
} from '@apiTypes';
import type { FileTypes } from '@library';

export const attachmentsEventTypeNames = [
    'SupportTicketCertificateOfGuaranteeDocumentEvent',
    'SupportTicketImageOfFaultDocumentEvent',
    'SupportTicketImageOfProductDocumentEvent',
    'SupportTicketImageOfSerialNumberDocumentEvent',
    'SupportTicketOtherDocumentEvent',
    'SupportMessageEvent',
] as const;

export type AttachmentsEvent = (typeof attachmentsEventTypeNames)[number];

export interface FileSectionConfig {
    label?: string;
    eventType: AttachmentsEvent;
    documentType: SupportTicketEventDocumentTypes;
    additionalEventTypes: AttachmentsEvent[];
    requiredFiles: number;
    accept: FileTypes[];
    maxFileSize?: number;
}

export interface AttachmentDetails extends SupportTicketEventAttachmentItem {
    event?: Extract<
        SupportTicketEvent,
        {
            __typename: AttachmentsEvent;
        }
    >;
}
