import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import { memo } from 'react';
import type { NumberFormatValues } from 'react-number-format';
import MinusIcon from '../../../../../core/components/icons/MinusIcon';
import PlusIcon from '../../../../../core/components/icons/PlusIcon';
import { Button } from '../../../buttons';
import { Tooltip } from '../../../popup';
import { Text } from '../../../typography';
import type { BaseInputProps } from '../BaseInput';
import type { NumberMaskInputProps } from '../NumberMaskInput';
import NumberMaskInput from '../NumberMaskInput';
import styles from './BaseQuantityInput.module.scss';

export type BaseQuantityInputProps = {
    /**
     * The value
     */
    value: string;
    /**
     * The number format props
     */
    numberFormatProps?: NumberMaskInputProps['numberFormatProps'];
    /**
     * The onIncrease event callback
     */
    onIncrease?: () => void;
    /**
     * The onDecrease event callback
     */
    onDecrease?: () => void;
    /**
     * The onChange event callback
     */
    onChange?: (values: NumberFormatValues) => void;
    /**
     * The message when maximus is reached
     */
    maxMessage?: ReactNode;
    /**
     * The message when minimum is reached
     */
    minMessage?: ReactNode;
    /**
     * The loading indicator
     */
    loading?: boolean;
    /**
     * The theme
     */
    theme?: string;
    /**
     * Mini sized input
     */
    mini?: boolean;
    /**
     * Show error state
     */
    error?: boolean;
    /**
     * Error message to show when input is in error state
     */
    errorMessage?: ReactNode;
} & Omit<
    BaseInputProps,
    'defaultValue' | 'type' | 'ref' | 'children' | 'onChange' | 'error'
>;

const BaseQuantityInput = ({
    value,
    numberFormatProps,
    onIncrease,
    onDecrease,
    onChange,
    maxMessage,
    minMessage,
    loading,
    theme,
    small,
    mini,
    error,
    errorMessage,
    ...props
}: BaseQuantityInputProps) => {
    return (
        <div
            className={clsx(styles.container, theme, {
                [styles.small]: small,
                [styles.mini]: mini,
                [styles.error]: error,
            })}
            data-test-class="quantityInput"
        >
            <div className={styles.input}>
                <Tooltip
                    content={minMessage}
                    mode="click"
                    disabled={!minMessage}
                    showArrow
                    autoCloseDelay={1600}
                >
                    <div>
                        <Button
                            danger
                            theme={clsx(
                                styles.commonButtonTheme,
                                styles.decreaseButtonTheme
                            )}
                            onClick={onDecrease}
                            data-test-class="decreaseButton"
                        >
                            <div className={styles.buttonContent}>
                                <MinusIcon />
                            </div>
                        </Button>
                    </div>
                </Tooltip>

                <div className={styles.inputContainer}>
                    <NumberMaskInput
                        value={value}
                        theme={styles.inputTheme}
                        numberFormatProps={{
                            ...numberFormatProps,
                            onValueChange: onChange,
                        }}
                        small={small}
                        mini={mini}
                        onKeyDown={event => {
                            if (event.key === 'ArrowDown') {
                                onDecrease?.();
                            }
                            if (event.key === 'ArrowUp') {
                                onIncrease?.();
                            }
                        }}
                        error={error}
                        data-test-class="quantityTextInput"
                        {...props}
                    />
                    {loading && (
                        <div
                            data-test-class="progressIndicator"
                            className={styles.skBounce}
                        >
                            <div className={styles.skBounceDot}></div>
                        </div>
                    )}
                </div>

                <Tooltip
                    content={maxMessage}
                    mode="click"
                    disabled={!maxMessage}
                    showArrow
                    autoCloseDelay={1600}
                >
                    <div>
                        <Button
                            tertiary
                            theme={styles.commonButtonTheme}
                            onClick={onIncrease}
                            data-test-class="increaseButton"
                        >
                            <div className={styles.buttonContent}>
                                <PlusIcon />
                            </div>
                        </Button>
                    </div>
                </Tooltip>
            </div>
            {error && errorMessage && (
                <div className={styles.quantityError}>
                    <Text secondary>{errorMessage}</Text>
                </div>
            )}
        </div>
    );
};

export default memo(BaseQuantityInput);
