import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { CartContext } from '../../core/cartContext';
import PreorderModeContext from '../PreorderModeContext';

export interface CustomerPreorderSummary {
    totalValue: number;
    discountTier: string;
}

export const customerPreorderBrandBreakdownQuery = gql`
    query customerPreorderBrandBreakdown($cartId: Int!) {
        customerPreorderSummary(cartId: $cartId) {
            brandBreakdown {
                brandName
                generalDealerPrice
            }
        }
    }
`;

const useCustomerPreorderBrandBreakdown = () => {
    const { isInPreorderMode } = useContext(PreorderModeContext);
    const { cart } = useContext(CartContext);
    const { data, loading, error } = useQuery<{
        customerPreorderSummary: {
            brandBreakdown: {
                brandName: string;
                generalDealerPrice: number;
            }[];
        };
    }>(customerPreorderBrandBreakdownQuery, {
        variables: {
            cartId: cart.id,
        },
        skip: !cart.id || !isInPreorderMode,
    });

    if (error) {
        throw error;
    }

    return {
        brandBreakdown: data?.customerPreorderSummary.brandBreakdown ?? [],
        loading,
    };
};

export default useCustomerPreorderBrandBreakdown;
