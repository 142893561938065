import { memo, useLayoutEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { clsx } from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Text, CloseIcon } from '@library';
import ClickableArea from '../../button/ClickableArea';
import styles from './Select.module.css';
import type { SelectDropdownOptions } from './SelectDropdown';
import SelectMenuInput from './SelectMenuInput';
import SelectIndication from './SelectIndication';
import SelectPlaceholder from './SelectPlaceholder';

interface SelectMenuProps {
    small?: boolean;
    fullWidth?: boolean;
    noPadding?: boolean;
    disabled?: boolean;
    bordered?: boolean;
    material?: boolean;
    isOpen?: boolean;
    searchable?: boolean;
    label?: ReactNode;
    placeholder?: ReactNode;
    selectedOption?: SelectDropdownOptions | SelectDropdownOptions[];
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    search?: string;
    updateSearch?: (searchTerm: string) => void;
    keepLabel?: boolean;
    formStyle?: boolean;
    loading?: boolean;
    successMessage?: string;
    showSuccessMessage?: boolean;
    onChange: (value: string) => void;
    value?: string;
    clearButton?: boolean;
    noBackground?: boolean;
}

const getSelectedLabelValue = (
    selectedOption?: SelectDropdownOptions | SelectDropdownOptions[]
) => {
    let selectedLabelValue: ReactNode = null;
    let hasSelectedValue = false;

    if (selectedOption) {
        if (Array.isArray(selectedOption)) {
            if (selectedOption.length === 1) {
                hasSelectedValue = true;
                selectedLabelValue = (
                    <span className={styles.selectedValuesItem}>
                        {selectedOption[0].label}
                    </span>
                );
            } else if (selectedOption.length > 1) {
                hasSelectedValue = true;
                selectedLabelValue = (
                    <span className={styles.selectedValuesItem}>
                        <FormattedMessage
                            id="{numberOfOptions} selected"
                            values={{
                                numberOfOptions: selectedOption.length,
                            }}
                        />
                    </span>
                );
            }
        } else {
            hasSelectedValue = true;
            selectedLabelValue = selectedOption.label;
        }
    }

    const result: [ReactNode, boolean] = [selectedLabelValue, hasSelectedValue];
    return result;
};

const SelectMenuLabel = memo(
    ({
        bordered,
        noPadding,
        label,
        hasSelectedValue,
        small,
    }: {
        bordered: boolean;
        noPadding: boolean;
        label: React.ReactNode;
        hasSelectedValue: boolean;
        small: boolean;
    }) => {
        return (
            <span
                className={clsx(styles.label, {
                    [styles.labelMargin]: bordered,
                    [styles.padding]: !noPadding,
                    [styles.small]: small,
                })}
            >
                <Text secondary>
                    {label}
                    {hasSelectedValue && ':'}
                </Text>
            </span>
        );
    }
);

const useSelectInputWidth = ({
    fullWidth,
    selectedLabelValue,
    searchable,
    noPadding,
}: {
    fullWidth?: boolean;
    searchable?: boolean;
    noPadding?: boolean;
    selectedLabelValue: ReactNode;
}) => {
    const selectedValueRef = useRef<HTMLSpanElement>(null);
    const [selectedValueWidth, setSelectedValueWidth] = useState(0);
    useLayoutEffect(() => {
        if (selectedValueRef.current && !fullWidth) {
            const textWidth =
                selectedValueRef.current.getBoundingClientRect().width;
            setSelectedValueWidth(textWidth);
        }
    }, [selectedValueRef, selectedLabelValue, searchable, fullWidth]);

    const additionalWidth = !noPadding ? 32 : 1;

    return {
        inputWidth: fullWidth
            ? '100%'
            : `${selectedValueWidth + additionalWidth + 23}px`,
        selectedValueRef,
    };
};

const ClearIconButton = memo(
    ({ onChange }: { onChange: (value: string) => void }) => {
        return (
            <ClickableArea
                onClick={event => {
                    event.stopPropagation();
                    onChange('');
                }}
            >
                <div className={styles.clearButton}>
                    <CloseIcon />
                </div>
            </ClickableArea>
        );
    }
);

const SelectMenu = ({
    small = false,
    fullWidth,
    noPadding = false,
    disabled = false,
    bordered = false,
    material = true,
    isOpen = false,
    searchable = false,
    label,
    placeholder,
    selectedOption,
    setIsOpen,
    search,
    updateSearch = () => {},
    keepLabel = false,
    formStyle = false,
    loading = false,
    noBackground = false,
    successMessage,
    showSuccessMessage,
    onChange,
    value,
    clearButton,
}: SelectMenuProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [selectedLabelValue, hasSelectedValue] =
        getSelectedLabelValue(selectedOption);

    const handleClick = () => {
        if (disabled) return;
        inputRef.current?.focus();
        setIsOpen(currentlyOpen => (searchable ? true : !currentlyOpen));
    };

    const { inputWidth, selectedValueRef } = useSelectInputWidth({
        fullWidth,
        searchable,
        noPadding,
        selectedLabelValue,
    });

    return (
        <div
            role="button"
            tabIndex={searchable ? -1 : 0}
            className={clsx(styles.wrapper, {
                [styles.cursorPointer]: !searchable,
                [styles.disabled]: disabled,
                [styles.notDisabled]: !disabled,
                [styles.small]: small,
                [styles.bordered]: bordered,
                [styles.material]: material,
                [styles.formStyle]: formStyle,
                [styles.filled]: hasSelectedValue,
            })}
            onClick={handleClick}
            onKeyDown={event => {
                if (event.key === 'Enter') {
                    handleClick();
                }
                if (event.key === 'Escape') {
                    setIsOpen(false);
                }
            }}
        >
            {label && (
                <SelectMenuLabel
                    bordered={bordered}
                    noPadding={noPadding}
                    small={small}
                    label={label}
                    hasSelectedValue={hasSelectedValue}
                />
            )}

            <div
                className={clsx(styles.selectMenu, {
                    [styles.fullWidth]: fullWidth,
                    [styles.nonFormStyle]: !formStyle,
                })}
            >
                <SelectMenuInput
                    noPadding={noPadding}
                    disabled={disabled}
                    searchable={searchable}
                    search={search}
                    material={material}
                    updateSearch={updateSearch}
                    setIsOpen={setIsOpen}
                    style={{ width: inputWidth }}
                    ref={inputRef}
                    noBackground={noBackground}
                />

                <SelectPlaceholder
                    search={search}
                    hasSelectedValue={hasSelectedValue}
                    title={placeholder?.toString()}
                    noPadding={noPadding}
                    fullWidth={fullWidth}
                    open={isOpen}
                    formStyle={formStyle}
                    placeholder={placeholder}
                />
                {selectedLabelValue && !search && (
                    <span
                        ref={selectedValueRef}
                        title={selectedLabelValue.toString()}
                        className={clsx(styles.selectedValue, {
                            [styles.padding]: !noPadding,
                            [styles.fullWidth]: fullWidth,
                            [styles.noWrap]: !fullWidth,
                            [styles.small]: small,
                            [styles.formStyle]: formStyle,
                        })}
                    >
                        <Text secondary>
                            {keepLabel ? `${placeholder}: ` : ''}
                            {selectedLabelValue}
                        </Text>
                    </span>
                )}
                {value && clearButton && (
                    <ClearIconButton onChange={onChange} />
                )}
                <SelectIndication
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    noPadding={noPadding}
                    showSuccessMessage={showSuccessMessage}
                    formStyle={formStyle}
                    successMessage={successMessage}
                    loading={loading}
                />
            </div>
        </div>
    );
};

export default memo(SelectMenu);
