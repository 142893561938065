// cSpell:disable
export const supportedMimoTypes: Record<string, string> = {
    avi: 'video/x-msvideo',
    csv: 'text/csv',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    flv: 'video/x-flv',
    heic: 'image/heic',
    heif: 'image/heif',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    log: 'text/plain',
    mkv: 'video/x-matroska',
    mov: 'video/quicktime',
    mp4: 'video/mp4',
    pdf: 'application/pdf',
    png: 'image/png',
    rtf: 'text/rtf',
    svg: 'image/svg+xml',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    txt: 'text/plain',
    webm: 'video/webm',
    webp: 'image/webp',
    wmv: 'video/x-ms-wmv',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
// cSpell:enable
