import { useEffect, useState } from 'react';

const useAutoFocusAfterOnMount = (
    inputRef: React.MutableRefObject<HTMLInputElement | null>,
    focus?: boolean
) => {
    const [focusToElement, setFocusToElement] = useState(false);
    useEffect(() => {
        if (focus) setFocusToElement(focus);
    }, [focus]);

    useEffect(() => {
        if (inputRef.current && focusToElement) {
            inputRef.current.focus();
        }
    }, [focusToElement, inputRef]);

    return inputRef;
};

export default useAutoFocusAfterOnMount;
