import type { ReactNode } from 'react';
import { memo, useMemo } from 'react';
import withErrorBoundary from '../../core/components/errors/withErrorBoundary';
import useCustomerPreorderPricing from '../queries/useCustomerPreorderPricing';
import useDiscountTiers from '../queries/useDiscountTiers';
import useSaveCustomerPreorderPricing from '../queries/useSaveCustomerPreorderPricing';
import type { CustomDiscountContextValue } from './CustomDiscountContext';
import CustomDiscountContext from './CustomDiscountContext';

const PreorderPricingProvider = ({ children }: { children: ReactNode }) => {
    const { loading, customerPreorderPricing } = useCustomerPreorderPricing();
    const { loading: isUpdating, saveCustomerPreorderPricing } =
        useSaveCustomerPreorderPricing();

    const contextValue: CustomDiscountContextValue = useMemo(() => {
        return {
            loading,
            isUpdating,
            editMode: !!customerPreorderPricing,
            discounts: customerPreorderPricing?.preorderDiscounts || [],
            saveCustomDiscount: saveCustomerPreorderPricing,
        };
    }, [
        loading,
        isUpdating,
        customerPreorderPricing,
        saveCustomerPreorderPricing,
    ]);

    useDiscountTiers();

    return (
        <CustomDiscountContext.Provider value={contextValue}>
            {children}
        </CustomDiscountContext.Provider>
    );
};

export default memo(withErrorBoundary(PreorderPricingProvider));
