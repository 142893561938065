import { memo, forwardRef } from 'react';
import type { CSSProperties, ReactNode } from 'react';
import { clsx } from 'clsx';
import { TextBlock } from '@library';
import HorizontalLayout from '../../layout/HorizontalLayout';
import CheckmarkIcon from '../../icons/CheckmarkIcon';
import type { SelectDropdownOptions } from './SelectDropdown';
import styles from './Select.module.css';

function isOptionLabelString(label: ReactNode): label is string {
    return typeof label === 'string';
}

interface SelectDropdownItemProps {
    virtualized?: boolean;
    style?: CSSProperties;
    value?: string | string[];
    formStyle?: boolean;
    option: SelectDropdownOptions;
    handleOptionsEvents(
        optionValue: string,
        event: React.MouseEvent | React.KeyboardEvent
    ): void;
}

const SelectDropdownItem = forwardRef<HTMLDivElement, SelectDropdownItemProps>(
    (
        {
            style,
            value,
            option,
            handleOptionsEvents,
            virtualized = false,
            formStyle,
        },
        ref
    ) => {
        const selected = Array.isArray(value)
            ? value.includes(option.value)
            : value === option.value;
        return (
            <div
                style={style}
                tabIndex={0}
                role="option"
                aria-selected={selected}
                aria-disabled={option.disabled}
                onClick={
                    !option.disabled
                        ? e => handleOptionsEvents(option.value, e)
                        : undefined
                }
                onMouseEnter={
                    !option.disabled
                        ? e => handleOptionsEvents(option.value, e)
                        : undefined
                }
                onMouseLeave={
                    !option.disabled
                        ? e => handleOptionsEvents(option.value, e)
                        : undefined
                }
                onKeyDown={
                    !option.disabled
                        ? e => handleOptionsEvents(option.value, e)
                        : undefined
                }
                ref={ref}
                data-test-class="selectDropdownOption"
                className={clsx(styles.dropdownItem, {
                    [styles.dropdownItemFocused]:
                        option.value === document.activeElement?.id,
                    [styles.virtualized]: virtualized,
                    [styles.disabled]: option.disabled,
                    [styles.formStyle]: formStyle,
                    [styles.selected]: selected,
                })}
            >
                <TextBlock tertiary regular>
                    <HorizontalLayout>
                        {!formStyle && (
                            <span
                                className={clsx(styles.dropdownItemIcon, {
                                    [styles.dropdownItemSelectedIcon]: selected,
                                })}
                            >
                                <CheckmarkIcon />
                            </span>
                        )}
                        {isOptionLabelString(option.label) ? (
                            <span
                                className={styles.text}
                                data-text={option.label}
                            >
                                {option.label}
                            </span>
                        ) : (
                            option.label
                        )}
                    </HorizontalLayout>
                </TextBlock>
            </div>
        );
    }
);

export default memo(SelectDropdownItem);
