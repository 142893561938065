import { SupportTicketItemReason } from '@apiTypes';
import type { SupportTicketItem, SupportTicketItemResolution } from '@apiTypes';

export interface GroupedTicketItemBreakdown {
    quantity: number;
    reason?: SupportTicketItemReason;
    resolution?: SupportTicketItemResolution;
    resolutionReason?: string;
}

export interface GroupedTicketItem {
    breakdown: GroupedTicketItemBreakdown[];
    restQuantity: number;
    supportTicketItem: SupportTicketItem;
    confirmedQuantity?: number;
    id: string;
}

export const ReasonsByTicketType: Record<
    SupportTicketType,
    SupportTicketItemReason[]
> = {
    return: [
        SupportTicketItemReason.LateDelivery,
        SupportTicketItemReason.NotFitting,
        SupportTicketItemReason.WrongOrder,
        SupportTicketItemReason.WrongDescription,
    ],
    complaint: [
        SupportTicketItemReason.WrongQuantity,
        SupportTicketItemReason.TransportDamage,
        SupportTicketItemReason.ArticleIncomplete,
    ],
    repair: [],
    inquiry: [],
    warranty: [],
};

export enum SupportTicketType {
    Complaint = 'complaint',
    Inquiry = 'inquiry',
    Repair = 'repair',
    Return = 'return',
    Warranty = 'warranty',
}

export const isTypename = <
    N extends { __typename: string },
    T extends N['__typename'],
>(
    node: N,
    typename: T
): node is Extract<N, { __typename: T }> => {
    return node.__typename === typename;
};

export const isTypeNameFilter =
    <N extends { __typename: string }, T extends string & N['__typename']>(
        typeNames: T[]
    ) =>
    (node: N): node is Extract<N, { __typename: T }> => {
        return typeNames.some(typename => isTypename(node, typename));
    };
