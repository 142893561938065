import type { QueryResult } from '@apollo/client';
import { gql } from '@apollo/client';
import { captureException } from '@sentry/react';
import { useContext, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { notify } from '@library';
import useCurrentCartId from '../../cart/hooks/useCurrentCartId';
import useCurrentStore from '../../core/hooks/useCurrentStore';
import PreorderModeContext from '../../preorder/PreorderModeContext';
import { CheckoutErrors } from '../context/CheckoutProvider';
import type { CartFields } from '../types';
import usePolledQuery from '../../core/hooks/apollo/usePolledQuery';

export const cartFieldsQuery = gql`
    query cartFields(
        $cartId: Int!
        $store: ID!
        $isPreorder: Boolean!
        $shouldIncludePaymentDueDate: Boolean!
    ) {
        cartFields(cartId: $cartId, store: $store) {
            id
            shippingMethod
            stockLocation
            reference
            comment
            deliveryAddressId
            deliveryAddress {
                name
                country
                city
                address
                address2
                postcode
                companyName
            }
            paymentTermId
            paymentDueDate @include(if: $shouldIncludePaymentDueDate)
            internalNote @include(if: $isPreorder)
            deliveryDates {
                brandName
                productGroupId
                variantGroupId
                sku
                quantity
                sortIndex
                deliveryDate
            }
            defaultDeliveryDate
        }
    }
`;

const useCartFields = (
    onCompleted: (
        data: QueryResult<{
            cartFields: CartFields;
        }>['data']
    ) => void
) => {
    const store = useCurrentStore();
    const cartId = useCurrentCartId();
    const { isInPreorderMode } = useContext(PreorderModeContext);

    const { error } = usePolledQuery<{
        cartFields: CartFields;
    }>(
        cartFieldsQuery,
        {
            variables: {
                cartId,
                store: store.id,
                isPreorder: isInPreorderMode,
                shouldIncludePaymentDueDate: store.companyCode === 'hg',
            },
            notifyOnNetworkStatusChange: true,
            pollInterval: 60_000,
            skip: !cartId,
            onCompleted,
        },
        true
    );

    useEffect(() => {
        if (error) {
            notify({
                type: 'error',
                title: (
                    <FormattedMessage id="Failed to synchronize cart settings" />
                ),
                message: (
                    <FormattedMessage id="Your cart settings could not be retrieved from the server. The displayed values might be out of date. Please verify the settings before proceeding." />
                ),

                id: CheckoutErrors.FAILED_TO_FETCH_CART_FIELDS,
            });

            captureException(error);
        } else {
            toast.dismiss(CheckoutErrors.FAILED_TO_FETCH_CART_FIELDS);
        }

        return () => {
            toast.dismiss(CheckoutErrors.FAILED_TO_FETCH_CART_FIELDS);
        };
    }, [error]);
};

export default useCartFields;
