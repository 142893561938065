import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import { memo } from 'react';
import { CloseIcon } from '@library';
import { ClickableArea } from '../buttons';
import { HStack } from '../layout';
import styles from './Pill.module.scss';

const Pill = ({
    children,
    onRemove,
    success = false,
    danger = false,
    filled = false,
    stretchHeight = false,
}: {
    children: ReactNode;
    /**
     * Use success styles
     */
    success?: boolean;
    /**
     * Use danger styles
     */
    danger?: boolean;
    /**
     * Use filled (solid) styles
     */
    filled?: boolean;
    /**
     * Stretch height to match parent container
     */
    stretchHeight?: boolean;
    /**
     * Callback for remove action. Also automatically
     * adds remove button inside the pill
     */
    onRemove?(): void;
}) => {
    return (
        <div
            className={clsx(styles.pill, {
                [styles.success]: success,
                [styles.danger]: danger,
                [styles.filled]: filled,
                [styles.stretchHeight]: stretchHeight,
            })}
        >
            <HStack align="center" gap="mini">
                {children}
                {!!onRemove && (
                    <ClickableArea
                        onPress={onRemove}
                        className={styles.removeTrigger}
                    >
                        <CloseIcon mini />
                    </ClickableArea>
                )}
            </HStack>
        </div>
    );
};

export default memo(Pill);
