import { useApolloClient } from '@apollo/client';
import type { ReactNode } from 'react';
import { useContext, memo, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import SpinnerPage from '../../../core/components/pages/SpinnerPage';
import CartProvider from '../../../core/components/providers/CartProvider';
import { AppContext } from '../../../core/context';
import useCustomNavigate from '../../../core/hooks/router/useCustomNavigate';
import useCategories from '../../../core/queries/useCategories';
import PreorderPricingProvider from '../../../pricing/providers/PreorderPricingProvider';
import type { PreorderModeContextValues } from '../../PreorderModeContext';
import PreorderModeContext from '../../PreorderModeContext';
import useActivePreorderSeasons from '../../queries/useActivePreorderSeasons';
import getPreorderRefetchQueries from '../../util/getPreorderRefetchQueries';
import PreorderModeHeader from './PreorderModeHeader';

const PreorderModeProvider = ({ children }: { children: ReactNode }) => {
    const { isImpersonating } = useContext(AppContext);

    const { activePreorderSeasons, loading } = useActivePreorderSeasons();

    const preorderSeasonId = +(useParams().preorderSeasonId || 0);
    const [onlyPreorderProducts, setOnlyPreorderProducts] = useState(true);
    const navigate = useCustomNavigate();

    const updateSelectedPreorderSeason = useCallback(
        (id: number) => {
            navigate(`../${id}`);
        },
        [navigate]
    );

    const selectedPreorderSeason = activePreorderSeasons.find(
        item => item.id === preorderSeasonId
    );

    const value: PreorderModeContextValues = useMemo(() => {
        return {
            isInPreorderMode: true,
            onlyPreorderProducts,
            preorderSeasonId,
            activePreorderSeasons,
            selectedPreorderSeason,
            productsFilters: {
                preorderSeasonId: onlyPreorderProducts
                    ? preorderSeasonId
                    : undefined,
                includePreorderSeasonId: onlyPreorderProducts
                    ? undefined
                    : preorderSeasonId,
                isSalesRepresentative: isImpersonating,
            },
            updateSelectedPreorderSeason,
        };
    }, [
        activePreorderSeasons,
        onlyPreorderProducts,
        selectedPreorderSeason,
        preorderSeasonId,
        updateSelectedPreorderSeason,
        isImpersonating,
    ]);

    const client = useApolloClient();
    const onCartUpdated = useCallback(() => {
        client
            .refetchQueries({
                include: getPreorderRefetchQueries(),
            })
            .catch(error => {
                throw error;
            });
    }, [client]);

    const { loading: loadingCategories } = useCategories(false, {
        preorderSeasonId: selectedPreorderSeason?.id,
    });

    if (loading || loadingCategories) {
        return <SpinnerPage />;
    }

    if (!selectedPreorderSeason) {
        throw new Error('Invalid preorder season id');
    }

    return (
        <PreorderModeContext.Provider value={value}>
            <CartProvider
                cartType="customerPreorder"
                onCartUpdated={onCartUpdated}
            >
                <PreorderPricingProvider>
                    <PreorderModeHeader
                        onlyPreorderProducts={onlyPreorderProducts}
                        setOnlyPreorderProducts={setOnlyPreorderProducts}
                    />
                    {children}
                </PreorderPricingProvider>
            </CartProvider>
        </PreorderModeContext.Provider>
    );
};

export default memo(PreorderModeProvider);
