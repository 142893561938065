import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormField, Heading, TextField, VStack, SingleSelect } from '@library';
import {
    useTicketInCreation,
    useUpdateTicketInCreation,
} from '../../../../context/TicketCreationContext/TicketCreationContext';
import useCurrentStore from '../../../../../core/hooks/useCurrentStore';

const ContactForm = memo(() => {
    const { ticket } = useTicketInCreation();
    const { updateTicket } = useUpdateTicketInCreation();
    const store = useCurrentStore();
    const intl = useIntl();
    return (
        <VStack gap="smallMedium">
            <Heading h4>
                <FormattedMessage id="Tickets Creation Preview ContactInformation" />
            </Heading>
            <VStack gap="small">
                <FormField
                    label={
                        <FormattedMessage id="Tickets Creation Preview NameLabel" />
                    }
                >
                    <TextField
                        value={ticket.customerContactName || ''}
                        onChange={value => {
                            updateTicket({
                                customerContactName: value,
                            });
                        }}
                    />
                </FormField>
                <FormField
                    label={
                        <FormattedMessage id="Tickets Creation Preview PhoneNumberLabel" />
                    }
                >
                    <TextField
                        value={ticket.customerContactPhone || ''}
                        onChange={value => {
                            updateTicket({
                                customerContactPhone: value,
                            });
                        }}
                    />
                </FormField>
                <FormField
                    label={
                        <FormattedMessage id="Tickets Creation Preview EmailLabel" />
                    }
                >
                    <TextField
                        value={ticket.customerContactEmail || ''}
                        type="email"
                        onChange={value => {
                            updateTicket({
                                customerContactEmail: value,
                            });
                        }}
                    />
                </FormField>
                <FormField
                    label={
                        <FormattedMessage id="Tickets Creation Preview LanguageLabel" />
                    }
                >
                    <SingleSelect
                        options={store.languages.map(language => {
                            return {
                                label: (
                                    <FormattedMessage id={language.language} />
                                ),
                                value: language.language,
                            };
                        })}
                        value={ticket.language || ''}
                        id="languageSelector"
                        onChange={value => {
                            updateTicket({
                                language: value,
                            });
                        }}
                        placeholder={intl.formatMessage({
                            id: 'Tickets Creation Preview LanguagePlaceholder',
                        })}
                    />
                </FormField>
            </VStack>
        </VStack>
    );
});

export default ContactForm;
