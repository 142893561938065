import type { ReactNode } from 'react';
import { memo, useRef } from 'react';
import { Button } from '../buttons';
import { HStack } from '../layout';
import useElementSize from '../../../core/hooks/dom/useElementSize';
import styles from './SplitButton.module.scss';
import type { DropdownProps } from './Dropdown';
import Dropdown from './Dropdown';

const SplitButton = ({
    mainActionOnClick,
    buttonProps,
    children,
    id,
    ...dropdownProps
}: DropdownProps & {
    /**
     * The main button click
     */
    mainActionOnClick?: () => void;
    children: ReactNode;
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const { width } = useElementSize(ref, true);
    return (
        <div ref={ref} id={id}>
            <HStack>
                <Button
                    {...buttonProps}
                    theme={styles.mainActionTheme}
                    onClick={mainActionOnClick}
                    justify="start"
                >
                    <div className={styles.buttonContent}>{children}</div>
                </Button>
                <div className={styles.separator} />

                <Dropdown
                    matchWidth={width}
                    id={id && `${id}_dropdown`}
                    placement="bottom-end"
                    {...dropdownProps}
                    buttonProps={{
                        ...buttonProps,
                        theme: styles.triggerTheme,
                    }}
                />
            </HStack>
        </div>
    );
};

export default memo(SplitButton);
