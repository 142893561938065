import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, HStack, Modal, VStack } from '@library';

const DiscardModal = ({
    open,
    onOpenChange,
    onDiscard,
    onCancel,
    discardTicket,
    isDiscarding,
    customHeader,
    onClose,
}: {
    open: boolean;
    onOpenChange?: (value: boolean) => void;
    onDiscard: () => void;
    onCancel?: () => void;
    onClose?: () => void;
    discardTicket: () => Promise<boolean>;
    isDiscarding: boolean;
    customHeader?: string;
}) => {
    const discard = () => {
        discardTicket()
            .then(() => {
                onDiscard();
            })
            .catch(error => {
                throw error;
            });
    };
    return (
        <Modal
            open={open}
            onOpenChange={value => {
                if (!value) {
                    onClose?.();
                }
                onOpenChange?.(value);
            }}
            header={
                <FormattedMessage
                    id={
                        customHeader
                            ? customHeader
                            : 'Tickets Creation Discard Header'
                    }
                />
            }
        >
            <VStack gap="small">
                <div />
                <HStack gap="small" justify="spaceBetween">
                    <Button
                        outline
                        onClick={() => {
                            onOpenChange?.(false);
                            onCancel?.();
                        }}
                    >
                        <FormattedMessage id="Tickets Creation Discard NoButton" />
                    </Button>
                    <Button danger loading={isDiscarding} onClick={discard}>
                        <FormattedMessage id="Tickets Creation Discard DiscardButton" />
                    </Button>
                </HStack>
            </VStack>
        </Modal>
    );
};

export default memo(DiscardModal);
