import { useEffect, useRef } from 'react';
import useCustomerPreorderSummary from './useCustomerPreorderSummary';
import usePriceCache from './usePriceCache';

const useDiscountTiers = () => {
    const { resetPrices } = usePriceCache();

    const { customerPreorderSummary } = useCustomerPreorderSummary();

    const isLoaded = !!customerPreorderSummary;
    const discountTier = customerPreorderSummary?.discountTier;

    const skipFirstLoadedState = useRef(true);

    useEffect(() => {
        if (isLoaded) {
            if (skipFirstLoadedState.current) {
                skipFirstLoadedState.current = false;
                return;
            }

            resetPrices().catch(error => {
                throw error;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [discountTier, isLoaded]);
};

export default useDiscountTiers;
