import { memo } from 'react';
import type { ReactNode } from 'react';
import Checkbox from './Checkbox';

export interface CheckboxOption {
    /**
     * Value for the radio option.
     */
    value: string;
    /**
     * Displayed label for the radio option.
     */
    label: ReactNode;
}

export interface CheckboxOptionsProps {
    /**
     * The options of the radio group.
     */
    options: CheckboxOption[];
    /**
     * Callback for onChange event handler.
     */
    onChange?: (value: string[]) => void;
    /**
     * The current value of the checkbox group.
     */
    value: string[];
    /**
     * The name of checkbox.
     */
    name?: string;
}

/**
 * The checkbox options, use this if you need special layout for the checkbox group.
 */
const CheckboxOptions = ({
    options,
    value,
    onChange,
    name,
}: CheckboxOptionsProps) => {
    return (
        <>
            {options.map(({ value: optionValue, label }) => {
                return (
                    <Checkbox
                        key={optionValue}
                        checked={value.includes(optionValue)}
                        name={name}
                        onChange={newValue => {
                            if (newValue) {
                                onChange?.([...value, optionValue]);
                            } else {
                                onChange?.(
                                    value.filter(
                                        selectedValue =>
                                            selectedValue !== optionValue
                                    )
                                );
                            }
                        }}
                        data-test-class={name}
                        data-test-state={optionValue}
                    >
                        {label}
                    </Checkbox>
                );
            })}
        </>
    );
};

export default memo(CheckboxOptions);
