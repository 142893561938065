import { memo } from 'react';
import { useLayoutContext } from '../../contexts';
import type { SidePaneProps } from './SidePane';
import SidePane from './SidePane';

const ResponsiveSidePane = ({ side, ...props }: SidePaneProps) => {
    const { isInMobileView } = useLayoutContext();
    return <SidePane side={isInMobileView ? 'bottom' : side} {...props} />;
};

export default memo(ResponsiveSidePane);
