import transformUnionType from './transformUnionType';

const transformUnionTypes = <
    T extends Record<string, unknown> & { __typename: string },
>(
    items: T[]
) => {
    return items.map(transformUnionType);
};

export default transformUnionTypes;
