import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { AppContext } from '../context';

const useRealUserDuringImpersonation = () => {
    const { isImpersonating } = useContext(AppContext);

    const { data, error } = useQuery<{
        loggedInUser: {
            username: string;
            customer: {
                name: string;
            };
        };
    }>(
        gql`
            query loggedInUser {
                loggedInUser {
                    username
                    customer {
                        name
                    }
                }
            }
        `,
        {
            skip: !isImpersonating,
        }
    );

    if (error) {
        throw error;
    }

    return { realUser: data?.loggedInUser };
};

export default useRealUserDuringImpersonation;
