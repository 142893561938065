import type { MutableRefObject } from 'react';
import { useLayoutEffect, useRef } from 'react';

const KEYBOARD_EVENT_KEYS = ['Enter', ' ', 'ArrowUp', 'ArrowDown', 'Escape'];

export type HandleOptionsEventsType = (
    optionValue: string,
    index: number,
    event: React.MouseEvent | React.KeyboardEvent
) => void;

const focusElementAtIndexInRefs = (
    index: number,
    arrayOfOptionsRefs: MutableRefObject<HTMLDivElement[]>
) => {
    const lastElemIndex = arrayOfOptionsRefs.current.length - 1;
    if (index === -1 && arrayOfOptionsRefs.current[lastElemIndex]) {
        arrayOfOptionsRefs.current[lastElemIndex].focus();
        return;
    }
    if (index === lastElemIndex + 1 && arrayOfOptionsRefs.current[0]) {
        arrayOfOptionsRefs.current[0].focus();
        return;
    }
    arrayOfOptionsRefs.current[index]?.focus();
};

const useSelectDropdownInteractions = (
    onChange: (value: string) => void,
    closeDropdown: () => void
): [
    MutableRefObject<HTMLDivElement[]>,
    (
        optionValue: string,
        index: number,
        event: React.MouseEvent | React.KeyboardEvent
    ) => void,
] => {
    const arrayOfOptionsRefs = useRef<HTMLDivElement[]>([]);

    useLayoutEffect(() => {
        return () => {
            arrayOfOptionsRefs.current = [];
        };
    }, []);

    const handleOptionSelect = (optionValue: string) => {
        onChange(optionValue);
    };

    const focusOnElementAtIndex = (index: number) => {
        focusElementAtIndexInRefs(index, arrayOfOptionsRefs);
    };

    const handleOptionEscapeKeyDown = () => {
        closeDropdown();
    };

    const handleOptionsKeyDown = (
        optionsValue: string,
        index: number,
        event: React.KeyboardEvent
    ) => {
        if (KEYBOARD_EVENT_KEYS.includes(event.key)) {
            event.preventDefault();
        }

        if (event.key === 'Enter' || event.key === ' ') {
            handleOptionSelect(optionsValue);
            return;
        }

        switch (event.key) {
            case 'ArrowUp':
                focusOnElementAtIndex(index - 1);
                break;
            case 'ArrowDown':
                focusOnElementAtIndex(index + 1);
                break;
            case 'Escape':
                handleOptionEscapeKeyDown();
                break;
            default:
        }
    };

    const handleOptionsEvents: HandleOptionsEventsType = (
        optionValue,
        index,
        event
    ) => {
        switch (event.type) {
            case 'click':
                handleOptionSelect(optionValue);
                break;
            case 'keydown':
                handleOptionsKeyDown(
                    optionValue,
                    index,
                    event as React.KeyboardEvent
                );
                break;
            default:
        }
    };

    return [arrayOfOptionsRefs, handleOptionsEvents];
};

export default useSelectDropdownInteractions;
