import { memo } from 'react';
import type { NumericFormatProps } from 'react-number-format';
import getNumberLocale from '../../../util/input/getNumberLocale';
import type { NumberMaskInputProps } from './NumberMaskInput';
import NumberMaskInput from './NumberMaskInput';
import styles from './PriceField.module.css';
/**
 *
 */
type PriceFieldFieldProps = Omit<
    NumberMaskInputProps,
    'numberFormatProps' | 'theme' | 'startAdornment'
> & {
    /**
     * The locale to use for formatting the number.
     */
    locale: string;
    /**
     * The currency symbol to use.
     */
    currency: string;
};

/**
 * The price field component for editing price.
 */
const PriceField = ({
    onChange,
    locale,
    currency,
    ...props
}: PriceFieldFieldProps) => {
    const { thousandSeparator, decimalSeparator } = getNumberLocale(locale);
    const options: Partial<NumericFormatProps> = {
        thousandSeparator,
        decimalSeparator,
        inputMode: 'numeric',
        decimalScale: 2,
        fixedDecimalScale: true,
        isAllowed: ({ floatValue }) => {
            return floatValue ? floatValue < 1000000 : true;
        },
    };
    return (
        <NumberMaskInput
            {...props}
            theme={styles.theme}
            numberFormatProps={{
                ...options,
                onValueChange: ({ floatValue }) => {
                    onChange?.(floatValue?.toString() || '');
                },
            }}
            startAdornment={<div className={styles.currency}>{currency}</div>}
        />
    );
};

export default memo(PriceField);
