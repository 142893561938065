import { memo, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Paragraph } from '@library';
import { AppContext } from '../../../core/context';
import useStoreAndLanguageParams from '../../../core/hooks/useStoreAndLanguageParams';
import type { Language } from '../../../core/types/language';
import usePreorderDiscountTier from '../../hooks/usePreorderDiscountTier';
import type { PreorderSeasonDiscountTier } from '../../types';
import styles from './PreorderDiscountTier.module.scss';

const getDiscountTierName = (
    discountTier: PreorderSeasonDiscountTier,
    language: Language
) =>
    discountTier[
        `name${language.toUpperCase()}` as keyof PreorderSeasonDiscountTier
    ] as string;

const PreorderDiscountTier = () => {
    const { language } = useStoreAndLanguageParams();

    const { currentLevel, nextLevel, amountToNextLevel } =
        usePreorderDiscountTier();
    const intl = useIntl();
    const { currency } = useContext(AppContext);

    const amountToNextLevelFormatted = intl.formatNumber(amountToNextLevel, {
        style: 'currency',
        currency,
        currencyDisplay: 'code',
        maximumFractionDigits: amountToNextLevel < 1 ? 2 : 0,
    });

    const anyTier = currentLevel || nextLevel;

    if (!anyTier) {
        return null;
    }

    return (
        <div className={styles.discountTier}>
            {currentLevel && (
                <Paragraph
                    tertiary
                    uppercase
                    medium
                    letterSpacing="medium"
                    data-test-class="currentDiscountTier"
                >
                    {getDiscountTierName(currentLevel, language)}
                </Paragraph>
            )}
            {currentLevel && nextLevel && <div className={styles.divider} />}
            {nextLevel && (
                <Paragraph tertiary>
                    <FormattedMessage
                        id={'{amount} to the next tier'}
                        values={{ amount: amountToNextLevelFormatted }}
                    />
                </Paragraph>
            )}
        </div>
    );
};

export default memo(PreorderDiscountTier);
