import type { ApolloError } from '@apollo/client';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import UnhandledError from './UnhandledError';

const ImpersonationMessage = ({
    impersonator,
    impersonatedUser,
    isPreorder,
    error,
}: {
    impersonator?: string;
    impersonatedUser?: string;
    isPreorder?: boolean;
    error?: ApolloError;
}) => {
    if (error) {
        return <UnhandledError>{error}</UnhandledError>;
    }

    const messageId = isPreorder
        ? 'you are signed in as {impersonator} and access the website as the client {impersonatedUser} in pre-order mode'
        : 'you are signed in as {impersonator} and access the website as the client {impersonatedUser}';

    return (
        <FormattedMessage
            id={messageId}
            values={{ impersonator, impersonatedUser }}
        />
    );
};

export default memo(ImpersonationMessage);
