import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import type {
    SupportTicketEvent,
    SupportTicketEventDocumentTypes,
} from '@apiTypes';
import type { FileDetails } from '@library';
import {
    FileField,
    FormField,
    VStack,
    Text,
    HStack,
    FileRestrictionMessage,
} from '@library';
import notEmpty from '../../../../core/util/notEmpty';
import { isTypeNameFilter, isTypename } from '../../../types';
import QrCodeUpload from '../../Creation/Common/QrCodeUpload/QrCodeUpload';
import FilesStack from './FilesStack';
import styles from './FileSection.module.scss';
import type { AttachmentDetails, FileSectionConfig } from './types';
import TicketFilePreview from './TicketFilePreview';

const FileSection = ({
    config: {
        label,
        eventType,
        requiredFiles,
        additionalEventTypes,
        documentType: event,
        accept,
        maxFileSize,
    },
    submitted,
    id,
    uploadPermissionToken,
    events,
    readonly,
    qrCode,
    detailView,
    uploadDocuments,
    deleteTicketEventAttachment,
    files,
    onFilesUpdate,
    disabled,
}: {
    readonly?: boolean;
    config: FileSectionConfig;
    submitted?: boolean;
    id: number;
    uploadPermissionToken?: string;
    qrCode?: boolean;
    detailView?: boolean;
    events: SupportTicketEvent[];
    uploadDocuments?: (params: {
        id: number;
        documentType: SupportTicketEventDocumentTypes;
        attachments: string[];
    }) => Promise<unknown>;
    deleteTicketEventAttachment?: (
        ticketId: number,
        eventId: string,
        attachmentId: number
    ) => Promise<unknown>;
    files?: FileDetails[];
    onFilesUpdate?: (files: FileDetails[]) => void;
    disabled?: boolean;
}) => {
    const attachments = events
        .filter(isTypeNameFilter([eventType, ...additionalEventTypes]))
        .flatMap(item => {
            return item.attachments?.map((attachment, index) => {
                return {
                    eventId: item.id,
                    attachmentId: index,
                    attachment: {
                        event: item,
                        ...attachment,
                    },
                };
            });
        })
        .filter(item => {
            return (
                item?.attachment &&
                isTypename(item.attachment, 'SupportTicketEventAttachmentItem')
            );
        }) as {
        eventId: string;
        attachmentId: number;
        attachment: AttachmentDetails;
    }[];

    const error = requiredFiles > 0 && attachments.length < requiredFiles;
    if (readonly && attachments.length === 0) {
        return null;
    }

    const anyAttachments =
        attachments.map(item => item.attachment).filter(notEmpty).length > 0;

    return (
        <VStack>
            <FormField
                label={
                    label && (
                        <Text medium>
                            <FormattedMessage id={label} />
                        </Text>
                    )
                }
                topHint={
                    !readonly && (
                        <FileRestrictionMessage
                            accept={accept}
                            maxFileSize={maxFileSize}
                        />
                    )
                }
                error={
                    error &&
                    submitted && (
                        <FormattedMessage
                            id="Tickets Files FilesRequiredMessage"
                            values={{
                                requiredFiles,
                            }}
                        />
                    )
                }
                required={requiredFiles > 0 && !readonly}
            >
                <VStack gap="small">
                    {!readonly ? (
                        <HStack gap="small" wrap>
                            <div className={styles.uploadArea}>
                                <FileField
                                    files={files}
                                    onFilesUpdate={onFilesUpdate}
                                    internalState={!!files}
                                    accept={accept}
                                    maxFileSize={maxFileSize}
                                    onUploadComplete={
                                        uploadDocuments &&
                                        (async newAttachments => {
                                            await uploadDocuments({
                                                id,
                                                documentType: event,
                                                attachments: newAttachments,
                                            });
                                        })
                                    }
                                    disabled={disabled}
                                >
                                    {anyAttachments &&
                                        attachments
                                            .map(item => item.attachment)
                                            .filter(notEmpty)
                                            .map((item, idx) => {
                                                return (
                                                    <TicketFilePreview
                                                        key={idx.toString()}
                                                        attachment={item}
                                                        attachmentId={idx}
                                                        deleteFile={
                                                            deleteTicketEventAttachment &&
                                                            (async index => {
                                                                const attachment =
                                                                    attachments[
                                                                        index
                                                                    ];
                                                                return deleteTicketEventAttachment(
                                                                    id,
                                                                    attachment.eventId,
                                                                    attachment.attachmentId
                                                                ).catch(
                                                                    deletingError => {
                                                                        throw deletingError;
                                                                    }
                                                                );
                                                            })
                                                        }
                                                    />
                                                );
                                            })
                                            .filter(notEmpty)}
                                </FileField>
                            </div>
                            {qrCode && uploadPermissionToken && (
                                <QrCodeUpload
                                    id={id}
                                    uploadPermissionToken={
                                        uploadPermissionToken
                                    }
                                    compact
                                    dark
                                />
                            )}
                        </HStack>
                    ) : (
                        <FilesStack
                            attachments={attachments
                                .map(item => item.attachment)
                                .filter(notEmpty)}
                            detailView={detailView}
                        />
                    )}
                </VStack>
            </FormField>
        </VStack>
    );
};

export default memo(FileSection);
