import { memo, useRef } from 'react';
import { clsx } from 'clsx';
import { FixedSizeList as List } from 'react-window';
import useSelectDropdownInteractions from '../../../hooks/useSelectDropdownInteractions';
import useOverflowingElement from '../../../hooks/dom/useOverflowingElement';
import styles from './Select.module.css';
import SelectDropdownItem from './SelectDropdownItem';
import type { SelectDropdownProps } from './SelectDropdown';

const VirtualizedSelectDropdown = ({
    value,
    onChange,
    options,
    closeDropdown,
    rightAligned = false,
    menuSize = 'min',
    boundsRef,
}: SelectDropdownProps) => {
    const [arrayOfOptionsRefs, handleOptionsEvents] =
        useSelectDropdownInteractions(onChange, closeDropdown);

    const menuRef = useRef<HTMLDivElement>(null);
    const isOverflowing = useOverflowingElement(menuRef, boundsRef);

    return (
        <div
            ref={menuRef}
            role="menu"
            tabIndex={0}
            className={clsx(
                styles.dropdown,
                styles.virtualized,
                styles[menuSize],
                {
                    [styles.rightAligned]: isOverflowing || rightAligned,
                }
            )}
        >
            <List
                height={300}
                itemCount={options.length}
                itemSize={47}
                width="100%"
            >
                {({ index, style }) => {
                    const option = options[index];

                    return (
                        <SelectDropdownItem
                            virtualized
                            key={option.value}
                            style={style}
                            value={value}
                            option={option}
                            handleOptionsEvents={(optionValue, e) =>
                                handleOptionsEvents(optionValue, index, e)
                            }
                            ref={(element: HTMLDivElement | null) => {
                                if (element !== null) {
                                    arrayOfOptionsRefs.current.push(element);
                                }
                            }}
                        />
                    );
                }}
            </List>
        </div>
    );
};

export default memo(VirtualizedSelectDropdown);
