import type { ReactNode } from 'react';
import { memo, useState } from 'react';
import { clsx } from 'clsx';
import { Button, type BaseButtonProps } from '../buttons';
import { Text } from '../typography';
import { ChevronDownIcon } from '../icons';
import type { TooltipStateLessProps } from './Tooltip';
import { TooltipStateLess } from './Tooltip';
import styles from './Dropdown.module.scss';

export interface DropdownProps
    extends Pick<TooltipStateLessProps, 'matchWidth' | 'placement'> {
    /**
     The button props for trigger
     */
    buttonProps: Omit<BaseButtonProps, 'component' | 'children'>;
    /**
     * Action items for dropdown
     */
    items: {
        /**
         * The label of action
         */
        label: string;
        /**
         * The icon of action
         */
        icon?: ReactNode;
        /**
         * The value of action
         */
        value: string;
        /**
         * The action is disabled
         */
        disabled?: boolean;
    }[];
    /**
     * The callback that triggere when user select action
     */
    onSelect?: (value: string) => void;
    children?: ReactNode;
    /**
     * The id of dropdown
     */
    id?: string;
}

const Dropdown = ({
    buttonProps,
    items,
    children,
    onSelect,
    matchWidth,
    placement,
    id,
}: DropdownProps) => {
    const [open, setOpen] = useState(false);

    return (
        <TooltipStateLess
            open={open}
            onOpenChange={value => {
                setOpen(value);
            }}
            content={
                <div className={styles.container}>
                    <div className={styles.wrapper}>
                        {items.map(item => (
                            <div
                                key={item.label}
                                className={styles.item}
                                id={id && `${id}_item_${item.value}`}
                            >
                                <Button
                                    text
                                    secondary
                                    disabled={item.disabled}
                                    theme={styles.buttonTheme}
                                    onClick={() => {
                                        onSelect?.(item.value);
                                        setOpen(false);
                                    }}
                                    justify="start"
                                >
                                    {item.icon && <div>{item.icon}</div>}
                                    <div className={styles.label}>
                                        <Text>{item.label}</Text>
                                    </div>
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>
            }
            mode="click"
            matchReferenceWidth={!matchWidth}
            matchWidth={matchWidth}
            theme={styles.tooltipTheme}
            placement={placement}
        >
            <Button {...buttonProps} justify="center">
                {children && <div>{children}</div>}
                <div
                    className={clsx(styles.triggerIcon, {
                        [styles.opened]: open,
                    })}
                >
                    <ChevronDownIcon />
                </div>
            </Button>
        </TooltipStateLess>
    );
};

export default memo(Dropdown);
