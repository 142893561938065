import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Paragraph, VStack, Text } from '@library';
import Confirmation from '../Common/Confirmation/Confirmation';
import styles from './InquiryConfirmation.module.scss';

const InquiryConfirmation = () => {
    return (
        <Confirmation
            message={
                <div className={styles.message}>
                    <VStack gap="small">
                        <Paragraph center>
                            <FormattedMessage
                                id="Tickets Creation Confirmation InquiryMessage"
                                values={{
                                    b: value => {
                                        return <Text medium>{value}</Text>;
                                    },
                                }}
                            />
                        </Paragraph>
                    </VStack>
                </div>
            }
        />
    );
};

export default memo(InquiryConfirmation);
