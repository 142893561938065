import { gql, useQuery } from '@apollo/client';
import type { QueryTicketSchemaArgs, TicketSchemaResult } from '@apiTypes';

const ticketsQuery = gql`
    query TicketSchema($store: String!) {
        ticketSchema(store: $store) {
            ticketTypes {
                productClasses {
                    productClassId
                    approvalIsRequiredForPrint
                    eligibilityWindow
                    imageOfProductRequired
                    imageOfSerialNumberRequired
                    name
                }
                ticketType
                availableDocuments {
                    documentType
                    requiredFiles
                    productClassId
                }
            }
        }
    }
`;

const useTicketSchema = () => {
    const { data, loading, error } = useQuery<
        {
            ticketSchema: TicketSchemaResult;
        },
        QueryTicketSchemaArgs
    >(ticketsQuery, {
        variables: {
            store: 'ic',
        },
        fetchPolicy: 'cache-first',
    });

    return {
        ticketSchema: data?.ticketSchema,
        error,
        loading,
    };
};

export default useTicketSchema;
