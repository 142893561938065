import { gql } from '@apollo/client';

export const attachmentFragment = gql`
    fragment Attachment on SupportTicketEventAttachmentItem {
        attachmentType
        sourceFile
        sourceFileSize
        sourceFileName
        processing
        thumbnail
        webSafeFile
    }
`;

export const eventsFragment = gql`
    ${attachmentFragment}

    fragment EventsFragment on SupportTicket {
        events {
            ... on SupportMessageEvent {
                id
                SupportMessageEvent_attachments: attachments {
                    ...Attachment
                }
                comment
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
            ... on SupportInternalNoteEvent {
                id
                SupportInternalNoteEvent_attachments: attachments {
                    ...Attachment
                }
                comment
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
            ... on SupportTicketItemRejectionEvent {
                id
                SupportTicketItemRejectionEvent_attachments: attachments {
                    ...Attachment
                }
                salesLines {
                    ... on SupportTicketReturnItem {
                        sku
                        upstreamRecId
                        reason
                    }
                    ... on SupportTicketWarrantyOrRepairItem {
                        sku
                        upstreamRecId
                    }
                    ... on SupportTicketManualItem {
                        SupportTicketManualItem_sku: sku
                    }
                }
                reason
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
            ... on SupportTicketItemResolutionEvent {
                id
                SupportTicketItemRejectionEvent_attachments: attachments {
                    ...Attachment
                }
                salesLines {
                    ... on SupportTicketReturnItem {
                        sku
                        upstreamRecId
                        reason
                    }
                    ... on SupportTicketWarrantyOrRepairItem {
                        sku
                        upstreamRecId
                    }
                    ... on SupportTicketManualItem {
                        SupportTicketManualItem_sku: sku
                    }
                }
                SupportTicketItemResolutionEvent_reason: reason
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
            ... on SupportTicketImageOfFaultDocumentEvent {
                id
                attachments {
                    ...Attachment
                }
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
            ... on SupportTicketImageOfSerialNumberDocumentEvent {
                id
                attachments {
                    ...Attachment
                }
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
            ... on SupportTicketImageOfProductDocumentEvent {
                id
                attachments {
                    ...Attachment
                }
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
            ... on SupportTicketCertificateOfGuaranteeDocumentEvent {
                id
                attachments {
                    ...Attachment
                }
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
            ... on SupportTicketOtherDocumentEvent {
                id
                attachments {
                    ...Attachment
                }
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
        }
    }
`;
