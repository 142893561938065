import { useMemo } from 'react';
import type {
    SupportTicketType,
    ProductClass as ProductClassAPI,
} from '@apiTypes';
import { useCanImpersonateCustomers } from '../../user/queries/useCapabilities';
import useTicketSchema from './useTicketSchema';

export interface ProductClass extends ProductClassAPI {
    serialNumberLabel: string;
    serialNumberPlaceholder: string;
    serialNumberOptional: boolean;
}

const useProductClasses = (ticketType?: SupportTicketType) => {
    const { ticketSchema, error } = useTicketSchema();
    if (error) {
        throw error;
    }

    return ticketSchema?.ticketTypes
        .find(schema => schema.ticketType === ticketType)
        ?.productClasses.map(productClass => {
            return {
                ...productClass,
                name: `Tickets Common TicketProductClass_${productClass.productClassId} Name`,
                serialNumberLabel: `Tickets Common TicketProductClass_${productClass.productClassId} SerialNumberLabel`,
                serialNumberPlaceholder: `Tickets Common TicketProductClass_${productClass.productClassId} SerialNumberPlaceholder`,
                serialNumberOptional: productClass.productClassId === 2,
            } as ProductClass;
        });
};

export const useProductClass = (
    ticketType?: SupportTicketType,
    productClassId?: number
) => {
    const canImpersonate = useCanImpersonateCustomers();
    const productClasses = useProductClasses(ticketType);
    return useMemo(() => {
        const productClass = productClasses?.find(
            productClassSchema =>
                productClassSchema.productClassId === productClassId
        );

        if (productClass?.eligibilityWindow && canImpersonate) {
            return {
                ...productClass,
                eligibilityWindow: undefined,
            };
        }

        return productClass;
    }, [canImpersonate, productClasses, productClassId]);
};

export default useProductClasses;
