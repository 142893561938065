import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import type {
    MutationCreateSupportTicketDocumentEventArgs,
    SupportTicketEventDocumentTypes,
} from '@apiTypes';
import { getTicketRefetchQuery } from './useTicket';

const createSupportTicketDocumentEventMutation = gql`
    mutation createSupportTicketDocumentEvent(
        $id: Int!
        $documentType: SupportTicketEventDocumentTypes!
        $attachments: [String!]!
    ) {
        createSupportTicketDocumentEvent(
            id: $id
            documentType: $documentType
            attachments: $attachments
        )
    }
`;

const useUploadTicketDocument = (
    refetchQueries?: InternalRefetchQueriesInclude
) => {
    const [createTicketEvent, { loading, error }] = useMutation<
        {
            createSupportTicketDocumentEvent: boolean;
        },
        MutationCreateSupportTicketDocumentEventArgs
    >(createSupportTicketDocumentEventMutation, {
        awaitRefetchQueries: true,
    });

    const uploadDocuments = useCallback(
        async ({
            id,
            documentType,
            attachments,
        }: {
            id: number;
            documentType: SupportTicketEventDocumentTypes;
            attachments: string[];
        }) => {
            if (attachments.length === 0) {
                return;
            }

            const variables = {
                id,
                documentType,
                attachments,
            };

            return createTicketEvent({
                variables,
                refetchQueries: refetchQueries
                    ? refetchQueries
                    : [getTicketRefetchQuery(id)],
                context: {
                    retryOnError: true,
                },
            });
        },
        [createTicketEvent, refetchQueries]
    );

    return {
        uploadDocuments,
        loading: loading,
        error: error,
    };
};

export default useUploadTicketDocument;
