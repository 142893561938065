import { DateTime } from 'luxon';

const computeDefaultDeliveryDate = (
    applicableDates: string[],
    defaultDeliveryDate?: DateTime
) => {
    if (defaultDeliveryDate) {
        return defaultDeliveryDate;
    }

    const startDate = applicableDates.slice().shift();
    if (!startDate) {
        return DateTime.local();
    }

    return DateTime.fromISO(startDate);
};

export default computeDefaultDeliveryDate;
