import type { RefObject } from 'react';
import { useCallback, useLayoutEffect, useState } from 'react';
import useResizeObserver from './useResizeObserver';

const useElementSize = (ref: RefObject<HTMLElement>, condition: boolean) => {
    const [elementSize, setElementSize] = useState({
        width: 0,
        height: 0,
    });
    const handleContainerSize = useCallback((element: Element) => {
        const { width, height } = element.getBoundingClientRect();
        setElementSize({
            width,
            height,
        });
    }, []);

    useResizeObserver(
        ref,
        (entries: ResizeObserverEntry[]) => {
            handleContainerSize(entries[0].target);
        },
        condition
    );

    useLayoutEffect(() => {
        const container = ref.current;
        if (container && condition) {
            handleContainerSize(container);
        }
    }, [condition, ref, handleContainerSize]);

    return { ...elementSize };
};

export default useElementSize;
