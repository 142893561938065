import { gql } from '@apollo/client';
import type { Query, QuerySupportTicketArgs } from '@apiTypes';
import usePolledQuery from '../../core/hooks/apollo/usePolledQuery';
import transformUnionTypes from '../util/transformUnionTypes';
import { attachmentFragment } from './commonFragments';

export const ticketAttachmentsQuery = gql`
    ${attachmentFragment}

    query supportTicketAttachmentEvents($id: Int!) {
        supportTicketAttachmentEvents(id: $id) {
            ... on SupportMessageEvent {
                id
                SupportMessageEvent_attachments: attachments {
                    ...Attachment
                }
                comment
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
            ... on SupportInternalNoteEvent {
                id
                SupportInternalNoteEvent_attachments: attachments {
                    ...Attachment
                }
                comment
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
            ... on SupportTicketItemRejectionEvent {
                id
                SupportTicketItemRejectionEvent_attachments: attachments {
                    ...Attachment
                }
                salesLines {
                    ... on SupportTicketReturnItem {
                        sku
                        upstreamRecId
                        reason
                    }
                    ... on SupportTicketWarrantyOrRepairItem {
                        sku
                        upstreamRecId
                    }
                    ... on SupportTicketManualItem {
                        SupportTicketManualItem_sku: sku
                    }
                }
                reason
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
            ... on SupportTicketImageOfFaultDocumentEvent {
                id
                attachments {
                    ...Attachment
                }
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
            ... on SupportTicketImageOfSerialNumberDocumentEvent {
                id
                attachments {
                    ...Attachment
                }
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
            ... on SupportTicketImageOfProductDocumentEvent {
                id
                attachments {
                    ...Attachment
                }
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
            ... on SupportTicketCertificateOfGuaranteeDocumentEvent {
                id
                attachments {
                    ...Attachment
                }
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
            ... on SupportTicketOtherDocumentEvent {
                id
                attachments {
                    ...Attachment
                }
                created
                user {
                    username
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const getTicketAttachmentsRefetchQuery = (id: number) => {
    return {
        query: ticketAttachmentsQuery,
        variables: {
            id,
        },
    };
};

const useTicketAttachments = (
    id: number,
    pollInterval = 20_000,
    skip?: boolean
) => {
    const { data, loading, error } = usePolledQuery<
        {
            supportTicketAttachmentEvents: Query['supportTicketAttachmentEvents'];
        },
        QuerySupportTicketArgs
    >(ticketAttachmentsQuery, {
        variables: {
            id,
        },
        pollInterval,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip,
    });

    return {
        attachments: transformUnionTypes(
            data?.supportTicketAttachmentEvents ?? []
        ),
        error,
        loading,
    };
};

export default useTicketAttachments;
