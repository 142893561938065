import type { ReactNode } from 'react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    Heading,
    VStack,
    RequestSubmittedIcon,
    HStack,
} from '@library';
import CustomLink from '../../../../../core/components/CustomLink';
import CreationWizardStep from '../../Wizard/CreationWizardStep';
import { useTicketInCreation } from '../../../../context/TicketCreationContext/TicketCreationContext';
import { formatTicketNumber } from '../../../../domain';
import styles from './Confirmation.module.scss';

const Confirmation = ({
    message,
    additionalAction,
}: {
    message: ReactNode;
    additionalAction?: ReactNode;
}) => {
    const { ticket } = useTicketInCreation();

    return (
        <CreationWizardStep titleId="Tickets Creation Confirmation Title">
            <div className={styles.container}>
                <VStack gap="medium" align="center">
                    <VStack gap="mini" align="center">
                        <RequestSubmittedIcon />
                        <Heading h3 medium>
                            <FormattedMessage id="Tickets Creation Confirmation Title" />
                        </Heading>
                        <CustomLink
                            to={`../../ticket/${formatTicketNumber(ticket.id)}`}
                        >
                            <Button text inline>
                                <Heading h3 regular>
                                    <FormattedMessage
                                        id="Tickets Common TicketNumber"
                                        values={{
                                            id: formatTicketNumber(ticket.id),
                                        }}
                                    />
                                </Heading>
                            </Button>
                        </CustomLink>
                    </VStack>
                    {message}
                    <div className={styles.buttons}>
                        <VStack gap="small">
                            {additionalAction}
                            <CustomLink
                                to={'../../create'}
                                className={styles.link}
                            >
                                <Button outline>
                                    <div className={styles.createButtonContent}>
                                        <FormattedMessage id="Tickets Creation Confirmation CreateNewTicketButton" />
                                    </div>
                                </Button>
                            </CustomLink>

                            <HStack justify="center">
                                <CustomLink to={'../../'}>
                                    <Button text inline>
                                        <FormattedMessage id="Tickets Creation Confirmation BackButton" />
                                    </Button>
                                </CustomLink>
                            </HStack>
                        </VStack>
                    </div>
                </VStack>
            </div>
        </CreationWizardStep>
    );
};
export default memo(Confirmation);
