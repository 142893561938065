import { memo } from 'react';
import AccessDeniedRedirect from '../core/components/router/AccessDeniedRedirect';
import LazyComponent from '../core/components/router/LazyComponent';
import RestrictedContent from '../core/components/router/RestrictedContent';
import useLoginState from '../core/hooks/useLoginState';
import { CartFieldsProvider } from './contexts/CartFields/CartFieldsContext';
import DeliveryDatesProvider from './contexts/DeliveryDates/DeliveryDatesProvider';

const ShareCart = LazyComponent(
    async () => import('./components/ShareCart/ShareCart')
);

const Cart = LazyComponent(async () => import('./components/CartPage/Cart'));
const CartsOverview = LazyComponent(
    async () => import('./components/CartOverview/CartsOverview')
);

const RestrictedCart = memo(() => {
    const isLoggedIn = useLoginState();
    return (
        <RestrictedContent
            storeFlags={isLoggedIn ? undefined : 'cartSharingEnabled'}
            fallback={AccessDeniedRedirect}
        >
            <CartFieldsProvider>
                <DeliveryDatesProvider>
                    <Cart />
                </DeliveryDatesProvider>
            </CartFieldsProvider>
        </RestrictedContent>
    );
});

const RestrictedCartsOverview = memo(() => {
    return (
        <RestrictedContent loggedInOnly fallback={AccessDeniedRedirect}>
            <CartsOverview />
        </RestrictedContent>
    );
});

const CartRoutes = [
    {
        path: 'cart',
        element: <RestrictedCart />,
    },
    {
        path: 'carts',
        element: <RestrictedCartsOverview />,
    },
    { path: 'share-cart/:code', element: <ShareCart /> },
];

export default CartRoutes;
