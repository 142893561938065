import { memo, forwardRef } from 'react';
import TextInput from '../TextInput';

const SelectMenuInput = forwardRef(
    (
        {
            noPadding,
            disabled,
            searchable,
            search,
            material,
            updateSearch,
            setIsOpen,
            style,
            noBackground,
        }: {
            noPadding?: boolean;
            disabled?: boolean;
            searchable?: boolean;
            search?: string;
            material?: boolean;
            updateSearch: (searchTerm: string) => void;
            setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
            style: React.CSSProperties;
            noBackground?: boolean;
        },
        ref: React.Ref<HTMLInputElement>
    ) => {
        return (
            <TextInput
                ref={ref}
                aria-autocomplete="list"
                autoComplete="off"
                autoCorrect="off"
                noGreyBorder
                noHoverBorder
                material={material}
                noActiveBorder
                noFilledBorder
                noBackground={noBackground}
                withPadding={!noPadding}
                value={search}
                onChange={e => {
                    setIsOpen(true);
                    updateSearch(e.target.value);
                }}
                readOnly={!searchable}
                tabIndex={searchable && !disabled ? 0 : -1}
                cursorPointer={!searchable}
                style={style}
            />
        );
    }
);

export default memo(SelectMenuInput);
