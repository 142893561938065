import type {
    SupportTicketItem,
    SupportTicketItemUpdateInput,
} from '@apiTypes';
import { isTypename } from '../types';

export const transform = (
    item: SupportTicketItem
): SupportTicketItemUpdateInput => {
    const { quantity, serialNumber } = item;

    if (isTypename(item, 'SupportTicketReturnItem')) {
        const { upstreamRecId, reason } = item;
        return {
            return: {
                upstreamRecId,
                quantity,
                reason,
                sku: item.sku,
                serialNumber,
            },
        };
    }

    if (isTypename(item, 'SupportTicketWarrantyOrRepairItem')) {
        const { upstreamRecId } = item;
        return {
            warrantyOrRepair: {
                upstreamRecId,
                sku: item.sku,
                quantity,
                serialNumber,
            },
        };
    }

    const {
        productName,
        serialNumber: serialNumberManual,
        orderNumber,
        sku,
    } = item;
    return {
        manual: {
            sku,
            quantity,
            serialNumber: serialNumberManual,
            productName,
            orderNumber,
        },
    };
};

const transformTicketItemsToUpdateTicketItems = (
    items: SupportTicketItem[]
) => {
    return items.map(item => {
        return transform(item);
    });
};

export default transformTicketItemsToUpdateTicketItems;
