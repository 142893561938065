import { memo, forwardRef } from 'react';
import type { Ref } from 'react';
import { clsx } from 'clsx';
import { CloseIcon } from '@library';
import SearchIcon from '../../../../core/components/icons/SearchIcon';
import ClickableArea from '../../../../core/components/button/ClickableArea';
import { HStack } from '../../layout';
import styles from './SearchField.module.css';
import type { BaseInputProps, BaseInputReference } from './BaseInput';
import BaseInput from './BaseInput';

/**
 *
 */
export interface SearchFieldProps
    extends Omit<
        BaseInputProps,
        'theme' | 'startAdornment' | 'error' | 'customInput' | 'children'
    > {
    /**
     * The light version of search input
     */
    // eslint-disable-next-line react/no-unused-prop-types
    light?: boolean;
}

/**
 *
 */
type SearchFieldReference = BaseInputReference;
/**
 * The search field component.
 */
const SearchField = memo(
    forwardRef(
        (
            { onChange, light, endAdornment, ...props }: SearchFieldProps,
            ref: Ref<SearchFieldReference>
        ) => {
            return (
                <BaseInput
                    theme={clsx(styles.theme, {
                        [styles.darkTheme]: !light,
                    })}
                    startAdornment={<SearchIcon />}
                    endAdornment={
                        props.value ? (
                            <HStack align="center" gap="mini">
                                <ClickableArea
                                    className={styles.clearButton}
                                    onClick={() => {
                                        onChange?.('');
                                    }}
                                >
                                    <CloseIcon small bold />
                                </ClickableArea>
                                {endAdornment && (
                                    <>
                                        <div
                                            className={styles.adornmentDivider}
                                        />
                                        {endAdornment}
                                    </>
                                )}
                            </HStack>
                        ) : (
                            endAdornment || <span />
                        )
                    }
                    onChange={onChange}
                    {...props}
                    ref={ref}
                />
            );
        }
    )
);

export default SearchField;
