import { memo } from 'react';
import type { IconProps } from './types';

const PlusIcon = ({ iconLabel, color }: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10.622"
            viewBox="0 0 10 10.622"
            data-icon={iconLabel}
            color={color}
        >
            <g transform="translate(-214.535 -414.035)">
                <rect
                    fill="none"
                    width="10"
                    height="10.622"
                    transform="translate(214.535 414.035)"
                />
                <g
                    style={{ isolation: 'isolate' }}
                    transform="translate(214.535 414.035)"
                >
                    <path
                        fill="currentColor"
                        d="M218.343,414.035h2.365v4.058h3.827v2.224h-3.827v4.339h-2.365v-4.339h-3.808v-2.224h3.808Z"
                        transform="translate(-214.535 -414.035)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default memo(PlusIcon);
