import type { ReactNode } from 'react';
import { useRef, startTransition, memo, useState } from 'react';
import { clsx } from 'clsx';
import { useInView } from 'react-intersection-observer';
import { ClickableArea, Heading, VStack } from '@library';

import FadeScroll from '../../../../core/components/layout/FadeScroll/FadeScroll';
import useEffectWithoutMount from '../../../../core/util/useEffectWithoutMount';
import Container from '../../../../core/components/Container';
import { FailedToRenderErrorBoundary } from '../../../../core/components/errors/withErrorBoundary';
import styles from './SegmentsPage.module.scss';

interface Tab {
    /**
     * Tab name
     */
    name: string;
    /**
     * The optional title component, name is used if not provided
     */
    title?: ReactNode;
    /**
     * Element to render
     */
    element: ReactNode;
    /**
     * Test class
     */
    testClass?: string;
}

export interface Segment extends Tab {
    /**
     * Hide segment
     */
    hide?: boolean;
    /**
     * Always Show segment
     */
    expandedStart?: boolean;
}

const Tabs = memo(
    ({
        tabs,
        activeTab,
        setActiveTab,
    }: {
        /**
         * Tabs to render
         */
        tabs: Tab[];
        /**
         * Active tab
         */
        activeTab: string;
        /**
         * Set active tab
         */
        setActiveTab: (value: string) => void;
    }) => {
        return (
            <FadeScroll mode="horizontal" hideScrollbar>
                <div className={styles.tabs}>
                    <div className={styles.tabsContent}>
                        {tabs.map(tab => {
                            return (
                                <ClickableArea
                                    key={tab.name}
                                    onPress={() => {
                                        setActiveTab(tab.name);
                                    }}
                                    className={clsx(styles.tab, {
                                        [styles.active]: activeTab === tab.name,
                                    })}
                                    data-test-class={
                                        tab.testClass && `${tab.testClass}-tab`
                                    }
                                >
                                    <Heading h4 regular>
                                        {tab.title || tab.name}
                                    </Heading>
                                </ClickableArea>
                            );
                        })}
                    </div>
                </div>
            </FadeScroll>
        );
    }
);

interface TabsViewProps {
    /**
     * Tabs to render
     */
    tabs: Tab[];
    /**
     * Secondary style of tabs
     */
    secondary?: boolean;
    /**
     * Show tabs as sticky element
     */
    sticky?: boolean;
}

const TabsView = memo(({ tabs, secondary, sticky }: TabsViewProps) => {
    const [activeTabState, setActiveTabState] = useState('');
    const activeTab = activeTabState || tabs[0]?.name;

    useEffectWithoutMount(() => {
        if (!tabs.find(tab => tab.name === activeTab)) {
            setActiveTabState('');
        }
    }, [tabs, activeTab]);

    const activeTabToShow =
        tabs.find(tab => tab.name === activeTabState) || tabs[0];

    const { ref, inView } = useInView({ skip: !sticky, initialInView: true });
    const anchor = useRef<HTMLDivElement | null>();
    return (
        <div
            className={clsx(styles.tabsView, {
                [styles.secondary]: secondary,
            })}
        >
            <div
                ref={element => {
                    anchor.current = element;
                    ref(element);
                }}
            />
            <div
                className={clsx(styles.tabsContainer, {
                    [styles.sticky]: !inView && sticky,
                })}
            >
                <Container>
                    <Tabs
                        tabs={tabs}
                        activeTab={activeTab}
                        setActiveTab={value => {
                            const anchorElement = anchor.current;
                            if (!inView && anchorElement) {
                                anchorElement.scrollIntoView();
                            }
                            startTransition(() => {
                                setActiveTabState(value);
                            });
                        }}
                    />
                </Container>
            </div>
            <Container>
                <div
                    className={styles.tabContent}
                    data-test-class={
                        activeTabToShow.testClass &&
                        `${activeTabToShow.testClass}-tab-content`
                    }
                >
                    <FailedToRenderErrorBoundary key={activeTabToShow.name}>
                        <>{activeTabToShow.element}</>
                    </FailedToRenderErrorBoundary>
                </div>
            </Container>
        </div>
    );
});

interface SegmentsPageProps {
    /**
     * The Segments
     */
    segments: Segment[];
}

const SegmentsPage = ({
    segments: segmentsProp,
    secondary,
    sticky,
}: SegmentsPageProps & Pick<TabsViewProps, 'sticky' | 'secondary'>) => {
    const segments = segmentsProp.filter(segment => !segment.hide);

    const segmentsForCurrentScreen = segments.filter(
        segment => !segment.expandedStart
    );

    const startSegments = segments.filter(segment => segment.expandedStart);
    return (
        <div className={styles.tabView}>
            <VStack gap="small">
                {startSegments.length > 0 && (
                    <div>
                        <Container>
                            {startSegments.map(segment => (
                                <div key={segment.name}>{segment.element}</div>
                            ))}
                        </Container>
                    </div>
                )}

                <TabsView
                    tabs={segmentsForCurrentScreen}
                    secondary={secondary}
                    sticky={sticky}
                />
            </VStack>
        </div>
    );
};

export default memo(SegmentsPage);
