import { type ReactNode, memo, useState } from 'react';
import { clsx } from 'clsx';
import { Button, ChevronTopIcon, ClickableArea, VStack } from '@library';
import Container from '../../../../core/components/Container';
import LinkBreadcrumbs from '../../../../core/components/LinkBreadcrumbs';
import { useNoScrollingWindow } from '../../../../core/components/Backdrop';
import FadeAnimation from '../../../../core/components/animations/FadeAnimation';
import useEffectWithoutMount from '../../../../core/util/useEffectWithoutMount';
import ErrorBoundary from '../../../../core/components/ErrorBoundary';
import { FailedToRenderMessage } from '../../../../core/components/errors/withErrorBoundary';
import { useTicketInCreation } from '../../../context/TicketCreationContext/TicketCreationContext';
import styles from './CreationWizardStep.module.scss';
import DetailsHeader from './DetailsHeader';

const CreationWizardStep = memo(
    ({
        children,
        footer,
        titleId,
        expandElement,
        expandPlaceholder,
        showHeader,
    }: {
        children: ReactNode;
        footer?: ReactNode;
        titleId: string;
        expandElement?: ReactNode;
        expandPlaceholder?: {
            open: string;
            close: string;
        };
        showHeader?: boolean;
    }) => {
        const breadcrumbs = [
            {
                labelId: 'Tickets Tickets Title',
                to: '/service',
            },
            {
                labelId: 'Tickets Creation SelectTicketType Title',
                to: '/service/create',
            },
            { labelId: titleId, to: '.', isRelative: true },
        ];

        const [open, setOpen] = useState(false);

        useNoScrollingWindow(open);

        useEffectWithoutMount(() => {
            if (!expandElement) {
                setOpen(false);
            }
        }, [expandElement]);

        const { ticket } = useTicketInCreation();

        return (
            <div className={styles.container}>
                <LinkBreadcrumbs titleId={titleId} breadcrumbs={breadcrumbs} />
                <div className={styles.content}>
                    <Container>
                        <ErrorBoundary
                            customMessage={<FailedToRenderMessage />}
                        >
                            <VStack gap="smallMedium">
                                {showHeader && (
                                    <DetailsHeader ticket={ticket} />
                                )}

                                <>{children}</>
                            </VStack>
                        </ErrorBoundary>
                    </Container>
                </div>

                <FadeAnimation show={open}>
                    <ClickableArea
                        className={clsx(styles.backdrop)}
                        onPress={() => {
                            setOpen(false);
                        }}
                    />
                </FadeAnimation>
                {footer && (
                    <div
                        className={clsx(styles.footer, {
                            [styles.expanded]: open,
                        })}
                    >
                        <VStack gap="small">
                            {expandElement && (
                                <div className={styles.expandedSection}>
                                    <div>
                                        <Container>
                                            <Button
                                                text
                                                inline
                                                onClick={() => {
                                                    setOpen(!open);
                                                }}
                                            >
                                                {open
                                                    ? expandPlaceholder?.close
                                                    : expandPlaceholder?.open}
                                                <div
                                                    className={clsx(
                                                        styles.arrow,
                                                        {
                                                            [styles.expanded]:
                                                                open,
                                                        }
                                                    )}
                                                >
                                                    <ChevronTopIcon />
                                                </div>
                                            </Button>
                                        </Container>
                                    </div>
                                    <div
                                        className={clsx(styles.section, {
                                            [styles.expanded]: open,
                                        })}
                                    >
                                        {open && expandElement}
                                    </div>
                                </div>
                            )}
                            <div>
                                <Container>{footer}</Container>
                            </div>
                        </VStack>
                    </div>
                )}
            </div>
        );
    }
);

export default memo(CreationWizardStep);
