/* eslint max-statements: ['error', 30] */
/* eslint  max-lines-per-function: ['error', 300] */
/* eslint max-lines: ['error', 380] */
/* eslint-disable jsdoc/require-jsdoc */

import { gql, useMutation } from '@apollo/client';
import AwsS3 from '@uppy/aws-s3';
import type { UppyFile } from '@uppy/core';
import { Uppy } from '@uppy/core';
import {
    useEffect,
    useId,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { useIntl } from 'react-intl';
import assert from '../../../../../core/util/assert';
import notEmpty from '../../../../../core/util/notEmpty';
import useImmutableCallback from '../../../../../core/hooks/useImmutableCallback';
import useDeepMemo from '../../../../../core/hooks/useDeepMemo';
import { notify } from '../../../notify';
import useDragAndDrop from './useDragAndDrop';

export interface FileDetails {
    id: string;
    s3Key?: string;
}

export const createS3UploadUrlMutation = gql`
    mutation createS3UploadUrl(
        $filename: String!
        $type: String!
        $metadata: [FileMetadataInput!]!
    ) {
        createS3UploadUrl(
            filename: $filename
            type: $type
            metadata: $metadata
        ) {
            url
            fields {
                key
                value
            }
            expires
            method
        }
    }
`;

export type UploadHandler = (fileIds: string[]) => Promise<void>;

const fileTypesMap = {
    image: [
        '.png',
        '.tif',
        '.tiff',
        '.jpg',
        '.jpeg',
        '.heif',
        '.heic',
        '.hif',
        '.svg',
        '.webp',
    ],
    video: ['.mp4', '.mov', '.avi', '.flv', '.mkv', '.wmv', '.webm'],
    pdf: ['.pdf'],
    word: ['.doc', '.docx'],
    excel: ['.xls', '.xlsx'],
    text: ['.txt', '.rtf', '.csv', '.log'],
    other: ['.txt', '.rtf', '.csv', '.log'],
};
export type FileTypes = keyof typeof fileTypesMap;

const useS3Mutation = () => {
    const [createS3UploadUrl] = useMutation<{
        createS3UploadUrl: {
            url: string;
            fields: {
                key: string;
                value: string;
            }[];
            expires: number;
        };
    }>(createS3UploadUrlMutation, {
        context: {
            legacyApi: true,
        },
    });
    return createS3UploadUrl;
};

export interface UseUppyInputParams {
    /**
     * The multiple upload mode
     */
    multiple?: boolean;
    /**
     * Allowed files
     */
    accept?: FileTypes[];
    /**
     *  Callback for onUploadComplete event
     */
    onUploadComplete?: UploadHandler;
    /**
     * Ref for drop handler
     */
    dropUploadRef: React.RefObject<HTMLDivElement>;
    /**
     * AutoUpload after adding
     */
    autoProceed?: boolean;
    /**
     * Keep files in file field. Useful for standalone forms
     */
    internalState?: boolean;
    /**
     * Triggered when files is updated
     */
    onFilesUpdate?: (files: FileDetails[]) => void;
    /**
     * External files state
     */
    files?: FileDetails[];
    /** */
    maxFileSize?: number;
}

const getFilesDetail = (currentFiles: UppyFile[]): FileDetails[] => {
    return currentFiles.map(file => {
        return {
            id: file.id,
            s3Key: (file.response?.body.key ?? file.meta.key) as
                | string
                | undefined,
        };
    });
};

const useUppyInput = ({
    files,
    multiple = false,
    accept,
    onUploadComplete: onUploadCompleteOuter,
    dropUploadRef,
    autoProceed,
    internalState,
    maxFileSize,
    onFilesUpdate: onFilesUpdateOuter,
}: UseUppyInputParams) => {
    const [uppyFiles, setUppyFiles] = useState<UppyFile[]>([]);
    const id = useId();
    const intl = useIntl();

    const uppy = useRef(
        new Uppy({
            id,
            autoProceed,
            locale: {
                strings: {
                    exceedsSize: intl.formatMessage({
                        id: 'Library FileField ExceedSizeMessage',
                    }),
                    youCanOnlyUploadFileTypes: intl.formatMessage({
                        id: 'Library FileField WrongTypeMessage',
                    }),
                },
            },
        })
            .use(AwsS3, {
                id: 'awsS3Plugin',
            })
            .use(ThumbnailGenerator)
    );

    const onUploadComplete = useImmutableCallback(onUploadCompleteOuter);
    const onFilesUpdate = useImmutableCallback(onFilesUpdateOuter);
    const updateFiles = useImmutableCallback(() => {
        setUppyFiles(uppy.current.getFiles());
    });

    const allowedFileTypes = useDeepMemo(() => {
        return (accept ?? (Object.keys(fileTypesMap) as FileTypes[])).flatMap(
            item => fileTypesMap[item]
        );
    }, accept);

    const { dropActive, dropError } = useDragAndDrop({
        dropUploadRef,
        uppy,
        allowedFileTypes,
    });

    const createS3UploadUrl = useS3Mutation();

    useLayoutEffect(() => {
        const plugin = uppy.current.getPlugin('awsS3Plugin');
        if (plugin) {
            plugin.setOptions({
                getUploadParameters: async (file: UppyFile) => {
                    const { data } = await createS3UploadUrl({
                        variables: {
                            filename: file.name,
                            type: file.type,
                            metadata: [
                                {
                                    key: 'source',
                                    value: `${window.location.href}`,
                                },
                                {
                                    key: 'filename',
                                    value: file.name,
                                },
                                ...Object.entries(file.meta)
                                    .map(([key, value]) =>
                                        value
                                            ? {
                                                  key,
                                                  value,
                                              }
                                            : undefined
                                    )
                                    .filter(notEmpty),
                            ],
                        },
                    });

                    assert(data, 'Data is required');

                    const {
                        createS3UploadUrl: { url, fields, expires },
                    } = data;

                    return {
                        method: 'POST',
                        url,
                        fields: Object.fromEntries(
                            fields.map(item => [item.key, item.value])
                        ),
                        expires,
                    };
                },
            });
        }
    }, [createS3UploadUrl, uppy]);

    useEffect(() => {
        const handler = async (fileIds: string[]) => {
            const uploaded = getFilesDetail(
                uppy.current
                    .getFiles()
                    .filter(item => fileIds.includes(item.id))
            );
            try {
                await onUploadComplete(
                    uploaded.map(file => file.s3Key).filter(notEmpty)
                );
            } finally {
                if (!internalState) {
                    for (const file of fileIds) {
                        uppy.current.removeFile(file);
                    }
                }
                updateFiles();
            }
        };
        const currentUppy = uppy.current;
        currentUppy.addPostProcessor(handler);
        return () => {
            currentUppy.removePostProcessor(handler);
        };
    }, [onUploadComplete, uppy, updateFiles, internalState]);

    useEffect(() => {
        uppy.current.setOptions({
            restrictions: {
                maxFileSize: maxFileSize,
                minNumberOfFiles: 1,
                maxNumberOfFiles: multiple ? undefined : 1,
                allowedFileTypes,
            },
        });
    }, [multiple, accept, uppy, allowedFileTypes, maxFileSize]);

    useEffect(() => {
        uppy.current.on('thumbnail:generated', () => {
            updateFiles();
        });
        uppy.current.on('files-added', () => {
            updateFiles();
        });
    }, [updateFiles]);

    useEffect(() => {
        uppy.current.on('restriction-failed', (type, error) => {
            notify({
                id: error.message,
                type: 'error',
                title: error.message,
            });
        });
    }, []);

    useEffect(() => {
        onFilesUpdate(getFilesDetail(uppyFiles));
    }, [uppyFiles, onFilesUpdate]);

    useEffect(() => {
        if (files) {
            const filesToRemove = uppyFiles.filter(
                file => !files.find(externalFile => externalFile.id === file.id)
            );
            for (const file of filesToRemove) {
                uppy.current.removeFile(file.id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    const removeFile = (fileId: string) => {
        uppy.current.removeFile(fileId);
        updateFiles();
    };

    const currentFiles = useMemo(() => {
        return files
            ? uppyFiles.filter(file =>
                  files.find(externalFile => externalFile.id === file.id)
              )
            : uppyFiles;
    }, [files, uppyFiles]);

    return {
        uppy,
        dropActive,
        dropError,
        files: currentFiles,
        removeFile,
    };
};

export default useUppyInput;
