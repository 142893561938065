import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import CartProvider from '../core/components/providers/CartProvider';
import LazyComponent from '../core/components/router/LazyComponent';
import PreorderModeProvider from '../preorder/components/PreorderMode/PreorderModeProvider';

const ProductDataSheet = LazyComponent(
    async () => import('../product/components/ProductDataSheet')
);
const OrderSheet = LazyComponent(
    async () => import('../order/components/OrderSheet')
);
const ShareCartSheet = LazyComponent(
    async () => import('../cart/components/ShareCart/ShareCartSheet')
);

const exportRoutesWithoutCart: RouteObject[] = [
    {
        path: 'product-sheet/:pid/:variantId',
        element: <ProductDataSheet />,
    },
    { path: 'product-sheet/:pid', element: <ProductDataSheet /> },
    { path: 'order-sheet/:orderId', element: <OrderSheet /> },
    { path: 'share-cart-sheet/:code', element: <ShareCartSheet /> },
];

const ExportRoutes: RouteObject[] = [
    {
        path: '*',
        element: (
            <CartProvider>
                <Outlet />
            </CartProvider>
        ),

        children: exportRoutesWithoutCart,
    },
    {
        path: 'preorder/:preorderSeasonId/*',
        element: (
            <PreorderModeProvider>
                <Outlet />
            </PreorderModeProvider>
        ),
        children: exportRoutesWithoutCart,
    },
];

export default ExportRoutes;
